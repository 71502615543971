import React, { CSSProperties, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import aboutusHeroImage from "@/assets/img/aboutusHero.png";
import newsImage1 from "@/assets/img/aboutus1.png";
import newsImage2 from "@/assets/img/aboutus2.png";
import newsImage3 from "@/assets/img/aboutus3.png";

import Header from "@/components/front/Header";
import Footer from "@/components/front/Footer";

const Aboutus = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    margins: {
      marginTop: "10rem",
      marginBottom: "10rem",
    },
    subtitle: {
      marginBottom: "0.5rem",
      fontSize: isMobile ? "1.5rem" : "1.8rem",
    },
    title: {
      marginBottom: "1rem",
      fontSize: isMobile ? "2rem" : "3rem",
    },
    description: {
      maxWidth: "80%",
      marginBottom: "1rem",
      marginTop: "3rem",
    },
    aboutusHeroImage: {
      width: isMobile ? "80%" : "100%",
      height: "auto",
      marginBottom: "2rem",
      display: "block",
      marginLeft: isMobile ? "auto" : "0",
      marginRight: isMobile ? "auto" : "0",
    },
    newsImage: {
      width: "90%",
      height: "auto",
      marginBottom: "0.5rem",
    },
    newsTitle: {
      fontWeight: "bold",
      marginBottom: "0.25rem",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    newsDescription: {
      marginBottom: "2rem",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
    joinUsButton: {
      marginTop: "1rem",
      backgroundColor: "#ef4444",
      color: "white",
      padding: "0.5rem 2rem",
      borderRadius: "0.375rem",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: isMobile ? "3rem" : "0",
    },
    featureContainer: {
      display: isMobile ? "flex" : "grid",
      gridTemplateColumns: isMobile ? "none" : "repeat(3, 1fr)",
      overflowX: isMobile ? "scroll" : "hidden",
      overflowY: "hidden",
      whiteSpace: isMobile ? "nowrap" : "normal",
      width: isMobile ? "90vw" : "auto",
      boxSizing: "border-box",
      marginBottom: "2rem",
      gridGap: "20px",
    },
    newsCard: {
      flex: "0 0 auto",
      width: isMobile ? "50%" : "100%",
      marginRight: isMobile ? "10px" : "0",
      overflow: isMobile ? "hidden" : "visible",
    },
  };

  return (
    <div className="flex flex-col min-h-screen">
      <HelmetProvider>
        <Helmet>
          <title>
            {t("front.company.aboutus.helmet")} | Redbird Corporation
          </title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <div
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-grow"
        style={styles.margins}
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <p className="text-xl" style={styles.subtitle}>
              {t("front.company.aboutus.subtitle")}
            </p>
            <h2 className="text-5xl font-bold" style={styles.title}>
              {t("front.company.aboutus.title")}
            </h2>
            <p style={styles.description}>
              {t("front.company.aboutus.description")}
            </p>
            <Link to="/company/careers">
              <button
                className="transition-colors hover:bg-red-600 flex items-center justify-center"
                style={styles.joinUsButton}
              >
                {t("front.company.aboutus.button")}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "0.5rem" }}
                />
              </button>
            </Link>
          </div>
          <div className="md:w-1/2">
            <div className="relative">
              <img
                style={styles.aboutusHeroImage}
                className="w-full"
                src={aboutusHeroImage}
                alt={t("aboutUsHeroAlt")}
              />
            </div>
          </div>
        </div>

        <div className="mt-8">
          <h3 className="text-2xl font-bold">
            {t("front.company.aboutus.news.title")}
          </h3>
          <div style={styles.featureContainer}>
            <div style={styles.newsCard}>
              <img src={newsImage1} alt={t("About US News1")} />
              <h4 style={styles.newsTitle}>
                {t("front.company.aboutus.news.title1")}
              </h4>
              <p style={styles.newsDescription}>
                {t("front.company.aboutus.news.subtitle1")}
              </p>
            </div>
            <div style={styles.newsCard}>
              <img src={newsImage2} alt={t("About US News2")} />
              <h4 style={styles.newsTitle}>
                {t("front.company.aboutus.news.title2")}
              </h4>
              <p style={styles.newsDescription}>
                {t("front.company.aboutus.news.subtitle2")}
              </p>
            </div>
            <div style={styles.newsCard}>
              <img src={newsImage3} alt={t("aBOUT US NEWS3")} />
              <h4 style={styles.newsTitle}>
                {t("front.company.aboutus.news.title3")}
              </h4>
              <p style={styles.newsDescription}>
                {t("front.company.aboutus.news.subtitle3")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
