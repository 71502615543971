import React from "react";

interface GrayOutScreenProps {
  message: string;
}

const GrayOutScreen: React.FC<GrayOutScreenProps> = ({ message }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="text-center p-4 m-4 border-2 border-yellow-500 rounded-lg bg-yellow-100 text-yellow-700 text-lg">
        {message}
      </div>
    </div>
  );
};

export default GrayOutScreen;
