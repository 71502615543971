import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faMinus } from "@fortawesome/free-solid-svg-icons";

export default function Faq() {
  const { t } = useTranslation();
  const [openQuestions, setOpenQuestions] = useState<number[]>([]);

  const toggleQuestion = (questionNumber: number) => {
    if (openQuestions.includes(questionNumber)) {
      setOpenQuestions(openQuestions.filter((q) => q !== questionNumber));
    } else {
      setOpenQuestions([...openQuestions, questionNumber]);
    }
  };

  const isQuestionOpen = (questionNumber: number) => {
    return openQuestions.includes(questionNumber);
  };

  return (
    <div className="max-w-7xl mx-auto py-8 px-4 sm:py-8 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-800 dark:text-slate-200 text-center">
        {t("front.faq.title")}
      </h2>
      <p className="text-center mt-4 text-lg leading-6 text-gray-500">
        {t("front.faq.headline")}
      </p>
      <div className="mt-12">
        <dl className="space-y-6">
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <div
              key={num}
              className="border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow duration-200 mx-auto w-full md:w-3/4 cursor-pointer"
              onClick={() => toggleQuestion(num)}
            >
              <div className="flex justify-between items-center">
                <dt className="text-lg leading-6 font-medium text-gray-900 dark:text-slate-300">
                  {t(`front.faq.questions.q${num}`)}
                </dt>
                <FontAwesomeIcon
                  icon={isQuestionOpen(num) ? faMinus : faChevronDown}
                  className="text-red-500"
                />
              </div>
              {isQuestionOpen(num) && (
                <dd className="mt-2 text-base text-gray-500">
                  {t(`front.faq.questions.a${num}`)}
                </dd>
              )}
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
