import { useLoadScript } from "@react-google-maps/api";
import { createContext, ReactNode, useContext } from "react";

interface AddressContextType {
  isLoaded: boolean;
  loadError: Error | undefined;
}

const AddressContext = createContext<AddressContextType | undefined>(undefined);

export const useAddressContext = () => {
  const context = useContext(AddressContext);
  if (context === undefined) {
    throw new Error("useAddressContext must be used within an AddressProvider");
  }
  return context;
};

interface AddressProviderProps {
  children: ReactNode;
  googleMapsApiKey: string;
}

export const AddressProvider: React.FC<AddressProviderProps> = ({
  children,
  googleMapsApiKey,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries: ["places"],
  });

  return (
    <AddressContext.Provider value={{ isLoaded, loadError }}>
      {children}
    </AddressContext.Provider>
  );
};
