import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CareersHero from "@/components/front/CompanyCareers/CareersHero";
import CaeeresContact from "@/components/front/CompanyCareers/CareersContact";
import Header from "@/components/front/Header";

import { useTranslation } from "react-i18next";

const CompanyCareers = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("front.company.careers.helmet")} | Redbird Corporation
          </title>
        </Helmet>
      </HelmetProvider>
      <div>
        <Header />
        <CareersHero />
        <CaeeresContact />
      </div>
    </div>
  );
};

export default CompanyCareers;
