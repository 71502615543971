import React from "react";
import Spinner from "../front/Loader/Spinner";

const PageLoader = ({
  children,
  isFetching,
}: {
  children: React.ReactNode;
  isFetching: boolean;
}) => {
  return <>{isFetching ? <Spinner /> : children}</>;
};

export default PageLoader;
