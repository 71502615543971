import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface FilterOption {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  type: "input" | "select";
  options?: { value: string; label: string }[];
}
interface FilterOptionsButtonProps {
  filterOptions: FilterOption[];
  onRemoveFilters: () => void;
}
const FilterOptionsButton: React.FC<FilterOptionsButtonProps> = ({
  filterOptions,
  onRemoveFilters,
}) => {
  const { t } = useTranslation();
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const filterPopupRef = useRef<HTMLDivElement>(null);

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterPopupRef.current &&
      !filterPopupRef.current.contains(event.target as Node)
    ) {
      setShowFilterPopup(false);
    }
  };

  const handleRemoveFilters = () => {
    setShowFilterPopup(false);
    onRemoveFilters();
  };

  React.useEffect(() => {
    if (showFilterPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterPopup]);

  return (
    <>
      <button
        className="px-7 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
        onClick={toggleFilterPopup}
      >
        <FontAwesomeIcon icon={faFilter} className="mr-2" />
        {t("back.management.component.filterButton")}
      </button>
      {showFilterPopup && (
        <div
          ref={filterPopupRef}
          className="absolute right-0 mt-2 z-10 w-80 bg-white rounded-md shadow-lg"
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">
              {t("back.management.component.filterOptions")}
            </h3>
            {filterOptions.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  {option.label}
                </label>
                {option.type === "input" ? (
                  <input
                    type="text"
                    value={option.value}
                    onChange={(e) => option.onChange(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder={option.placeholder}
                  />
                ) : (
                  <select
                    value={option.value}
                    onChange={(e) => option.onChange(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">{option.placeholder}</option>
                    {option.options?.map((selectOption) => (
                      <option
                        key={selectOption.value}
                        value={selectOption.value}
                      >
                        {selectOption.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            ))}
            <div className="mt-4 flex justify-end">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                onClick={handleRemoveFilters}
              >
                {t("back.management.component.removeFilters")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterOptionsButton;
