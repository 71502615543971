import React from "react";

const SmallSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="relative w-12 h-12 animate-spin rounded-full bg-gradient-to-r from-black via-red-500 to-black">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 bg-gray-200 rounded-full border-2 border-white"></div>
      </div>
    </div>
  );
};

export default SmallSpinner;
