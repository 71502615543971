import PageLoader from "@/components/back/Spinner";
import SmallSpinner from "@/components/front/Loader/SmallSpinner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faClipboardList,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  endOfToday,
  endOfYesterday,
  parseISO,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Order {
  id: number;
  customerName: string;
  created_at: string;
  total_price: string;
  cost: string | "0";
  status: string;
}

interface CardProps {
  isSidebarOpen: boolean;
}

const Card: React.FC<CardProps> = ({ isSidebarOpen }) => {
  const { t } = useTranslation();
  const [todaysIncome, setTodaysIncome] = useState<number>(0);
  const [incomeGrowthPercentage, setIncomeGrowthPercentage] =
    useState<number>(0);
  const [todaysOrders, setTodaysOrders] = useState<number>(0);
  const [orderGrowthPercentage, setOrderGrowthPercentage] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [axiosInstance, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const selectedBranch = user?.selectedBranch?.id;

  useEffect(() => {
    const fetchOrders = async () => {
      if (!selectedBranch) return;

      const timeZone = "America/Chicago";

      const todayStart = zonedTimeToUtc(startOfToday(), timeZone).toISOString();
      const todayEnd = zonedTimeToUtc(endOfToday(), timeZone).toISOString();
      const yesterdayStart = zonedTimeToUtc(
        startOfYesterday(),
        timeZone
      ).toISOString();
      const yesterdayEnd = zonedTimeToUtc(
        endOfYesterday(),
        timeZone
      ).toISOString();

      try {
        // Fetch today's orders
        const todayResponse = await axiosInstance.get(
          `${process.env.REACT_APP_API_BASE_URL}api/orders/?branch=${selectedBranch}&created_at_start=${todayStart}&created_at_end=${todayEnd}`
        );
        const todaysOrders: Order[] = await fetchAllOrders(todayResponse);

        // Fetch yesterday's orders
        const yesterdayResponse = await axiosInstance.get(
          `${process.env.REACT_APP_API_BASE_URL}api/orders/?branch=${selectedBranch}&created_at_start=${yesterdayStart}&created_at_end=${yesterdayEnd}`
        );
        const yesterdaysOrders: Order[] = await fetchAllOrders(
          yesterdayResponse
        );

        processOrders(todaysOrders, yesterdaysOrders);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [axiosInstance, selectedBranch]);

  const fetchAllOrders = async (response: any): Promise<Order[]> => {
    let allOrders: Order[] = response.data.orders;
    const totalPages = Math.ceil(response.data.total_orders / 10);

    for (let page = 2; page <= totalPages; page++) {
      const pageResponse = await axiosInstance.get(
        `${response.config.url}&page=${page}`
      );
      allOrders = [...allOrders, ...pageResponse.data.orders];
    }

    return allOrders;
  };

  const processOrders = (todaysOrders: Order[], yesterdaysOrders: Order[]) => {
    let todayIncome = 0;
    let yesterdayIncome = 0;
    let todayOrderCount = 0;
    let yesterdayOrderCount = 0;

    const timeZone = "America/Chicago";

    const todayStart = zonedTimeToUtc(startOfToday(), timeZone);
    const todayEnd = zonedTimeToUtc(endOfToday(), timeZone);

    const yesterdayStart = zonedTimeToUtc(startOfYesterday(), timeZone);
    const yesterdayEnd = zonedTimeToUtc(endOfYesterday(), timeZone);

    todaysOrders.forEach((order) => {
      const orderDateUTC = parseISO(order.created_at);
      const orderDateLocal = utcToZonedTime(orderDateUTC, timeZone);

      const orderAmount = parseFloat(order.total_price);
      if (orderDateLocal >= todayStart && orderDateLocal <= todayEnd) {
        todayIncome += orderAmount;
        todayOrderCount++;
      }
    });

    yesterdaysOrders.forEach((order) => {
      const orderDateUTC = parseISO(order.created_at);
      const orderDateLocal = utcToZonedTime(orderDateUTC, timeZone);

      const orderAmount = parseFloat(order.total_price);
      if (orderDateLocal >= yesterdayStart && orderDateLocal <= yesterdayEnd) {
        yesterdayIncome += orderAmount;
        yesterdayOrderCount++;
      }
    });

    setTodaysIncome(todayIncome);
    setTodaysOrders(todayOrderCount);

    const calcGrowthPercentage = (today: number, yesterday: number): number => {
      if (yesterday === 0) {
        return today > 0 ? 100 : 0;
      }
      return ((today - yesterday) / yesterday) * 100;
    };

    setIncomeGrowthPercentage(
      calcGrowthPercentage(todayIncome, yesterdayIncome)
    );
    setOrderGrowthPercentage(
      calcGrowthPercentage(todayOrderCount, yesterdayOrderCount)
    );
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div
        className="grid grid-cols-2 gap-2 sm:gap-6"
        style={{
          transition: "transform 0.3s",
          transform: isSidebarOpen ? "translateX(64px)" : "translateX(0)",
        }}
      >
        {/* Card 1: Today's Income */}
        <div className="p-2 sm:p-4 rounded-lg shadow-md bg-white min-h-[100px] sm:min-h-[150px]">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <SmallSpinner />
            </div>
          ) : (
            <div className="flex items-center justify-between p-2 sm:p-3">
              <div>
                <p className="text-sm sm:text-lg font-medium text-gray-600">
                  {t("back.management.dashboard.card.card1")}
                </p>
                <p className="text-xl sm:text-3xl font-bold">
                  {todaysIncome !== null
                    ? `$${todaysIncome.toFixed(2)}`
                    : "0.00"}
                </p>
                <p className="text-xs sm:text-sm font-medium text-gray-600">
                  {incomeGrowthPercentage !== null
                    ? `${incomeGrowthPercentage.toFixed(2)}%`
                    : "0.00"}{" "}
                  {t("back.management.dashboard.fromyesterday")}
                </p>
              </div>
              <FontAwesomeIcon
                icon={faDollarSign}
                className="text-red-500 text-xl sm:text-3xl"
              />
            </div>
          )}
        </div>

        {/* Card 2: Today's Orders */}
        <div className="p-2 sm:p-4 rounded-lg shadow-md bg-white min-h-[100px] sm:min-h-[150px]">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <SmallSpinner />
            </div>
          ) : (
            <div className="flex items-center justify-between p-2 sm:p-3">
              <div>
                <p className="text-sm sm:text-lg font-medium text-gray-600">
                  {t("back.management.dashboard.card.card2")}
                </p>
                <p className="text-xl sm:text-3xl font-bold">
                  {todaysOrders !== null ? todaysOrders : "0"}
                </p>
                <p className="text-xs sm:text-sm font-medium text-gray-600">
                  {orderGrowthPercentage !== null
                    ? `${orderGrowthPercentage.toFixed(2)}%`
                    : "0.00"}{" "}
                  {t("back.management.dashboard.fromyesterday")}
                </p>
              </div>
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-red-500 text-xl sm:text-3xl"
              />
            </div>
          )}
        </div>
      </div>
    </PageLoader>
  );
};

export default Card;
