import { Link } from "react-router-dom";
import SuccessIllustration from "../../ChineseCooking.gif"; // Import the GIF file

const OrderConfirmation = () => {
  return (
    <div className="p-6 bg-gray-100 min-h-screen flex flex-col items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <Link
            to="/store"
            className="text-red-600 hover:underline flex items-center"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
            Back to Home
          </Link>
          <h1 className="text-2xl font-bold text-gray-700">
            Order Confirmation
          </h1>
        </div>
        <div className="flex flex-col items-center justify-center space-y-4">
          <img
            src={SuccessIllustration}
            alt="Success"
            className="w-64 h-64 mb-4 rounded-lg shadow-sm"
          />
          <h2 className="text-xl font-bold text-green-600 text-center">
            Congratulations! Your Order is Confirmed!
          </h2>
          <h3 className="text-lg text-gray-600 text-center">
            We&apos;re excited to prepare your order. It will be ready in just
            15 minutes! You should receive a text message with order details on
            the phone number you provided.
          </h3>
          <p className="text-gray-600 text-center">
            Thank you for choosing us! Your order has been placed successfully,
            and we appreciate your purchase. If you have any questions or need
            further assistance, please feel free to reach out.
          </p>
          <p className="text-gray-600 text-center">Have a great day!</p>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
