import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import CafeBakeryHero from "@/components/front/BusinessCafeBakery/CafeBakeryHero";
import CafeBakeryFeature from "@/components/front/BusinessCafeBakery/CafeBakeryFeature";
import CafeBakeryFeature2 from "@/components/front/BusinessCafeBakery/CafeBakeryFeature2";
import FeaturesHero2 from "@/components/front/FeaturesHero";
import Contact from "@/views/front/Contact";

import { useTranslation } from "react-i18next";

const StyledLine = () => {
  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: "#aaa",
    width: "80%",
    margin: "20px auto",
  };

  return <div style={lineStyle}></div>;
};

const BusinessCafeBakery = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("front.business.cafebakery.helmet")} | Redbird Corporation
          </title>
        </Helmet>
      </HelmetProvider>
      <div>
        <Header />
        <CafeBakeryHero />
        <CafeBakeryFeature />
        <CafeBakeryFeature2 />
        <StyledLine />
        <FeaturesHero2 />
        <Contact />
      </div>
    </div>
  );
};

export default BusinessCafeBakery;
