import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import LogoLight from "@/assets/img/logo-light.png";

interface Props {
  isActive: boolean;
  requestOpen: () => void;
  requestClose: () => void;
  className?: string;
  btnClassName?: string;
}

export default function FeaturesDropdown({ 
  isActive,
  requestOpen,
  requestClose,
  className, btnClassName }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const pricing = t("front.navbar.pricing", {
    returnObjects: true,
  }) as Record<string, string>;

  const handleItemClick = (pricingName: string) => {
    const path = `/pricing`;
    navigate(path);

    if (window.innerWidth < 1025) {
      setIsMobileOpen(false);
    }
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      timeoutRef.current = setTimeout(() => {
        requestClose();
      }, 500); 
    };

    const handleMouseEnter = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      requestOpen();
    };

    const container = containerRef.current;
    container?.addEventListener("mouseleave", handleMouseLeave);
    container?.addEventListener("mouseenter", handleMouseEnter);

    return () => {
      container?.removeEventListener("mouseleave", handleMouseLeave);
      container?.removeEventListener("mouseenter", handleMouseEnter);
    };
  }, [requestClose, requestOpen]);

  return (
    <div
      className={`relative group ${className}`}
      ref={containerRef}
    >
      <button
        className={`cursor-pointer select-none font-medium focus:outline-none transition ease-in-out duration-150 px-3 py-1 rounded-sm text-gray-500 dark:text-white hover:text-red-500 hover:underline ${btnClassName}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {t("front.navbar.pricing.title")}
      </button>
      <div
        className={`absolute z-10 mt-2 left-0 w-64 bg-white dark:bg-gray-900 shadow-lg border border-gray-300 dark:border-gray-700 rounded-lg transition-opacity duration-300 ease-out ${isActive ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
        style={{ transitionProperty: 'opacity, visibility' }}
      >
          <div className="py-2">
            {Object.entries(pricing).map(([key, value]) => {
              if (key.startsWith("dropdown")) {
                return (
                  <button
                    key={key}
                    type="button"
                    onClick={() => {
                      handleItemClick(value);
                      setIsOpen(false);
                    }}
                    className="block w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-red-500 hover:underline"
                  >
                    {value}
                  </button>
                );
              }
              return null;
            })}
          </div>
        </div>
      
      {isMobileOpen && (
        <div className="fixed inset-0 z-50 bg-white dark:bg-gray-900">
          <div className="px-5 pt-4 pb-10 flex items-center justify-between">
            <div>
              <Link to="/">
                <img
                  src={LogoLight}
                  alt="Logo"
                  className="md:hidden h-14 w-auto"
                />
              </Link>
            </div>
            <button
              className="text-red-500"
              onClick={() => setIsMobileOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-xl absolute left-1/2 transform -translate-x-1/2 font-medium">
              {t("front.navbar.pricing.title")}
            </span>
          </div>
          <div className="mt-4 space-y-2">
            {Object.entries(pricing).map(([key, value]) => {
              if (key.startsWith("dropdown")) {
                return (
                  <button
                    key={key}
                    type="button"
                    onClick={() => {
                      handleItemClick(value);
                      setIsMobileOpen(false);
                    }}
                    className="block w-full px-5 py-3 text-left dark:text-gray-300 mobile-menu-link"
                  >
                    {value}
                  </button>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
