import React from "react";
import ExpenseTitle from "@/views/back/management/components/expense/ExpenseTitle";
import ExpenseDetail from "./components/expense/ExpenseDetail";

interface ExpenseProps {
  onMenuItemClick: (componentName: string) => void;
}
const Expense: React.FC<ExpenseProps> = ({ onMenuItemClick }) => {
  return (
    <div>
      <ExpenseTitle onMenuItemClick={onMenuItemClick} />
      <ExpenseDetail />
    </div>
  );
};

export default Expense;
