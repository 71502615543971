import PageLoader from "@/components/back/Spinner";
import Spinner from "@/components/front/Loader/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faCircle,
  faImage,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditOptionProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  optionId: number | null;
}

interface OptionList {
  id: number;
  name: string;
  required: boolean;
}

interface Option {
  id: number;
  name: string;
  description: string;
  color: string;
  surcharge: string;
  image: string;
  optionLists: number[];
}

const EditOption = ({
  onMenuItemClick,
  setSuccessMessage,
  optionId,
}: EditOptionProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [optionLists, setOptionLists] = useState<OptionList[]>([]);
  const [option, setOption] = useState<Option>({
    id: 0,
    name: "",
    description: "",
    color: "#ffffff",
    surcharge: "",
    image: "",
    optionLists: [],
  });

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");

  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const [filteredOptionLists, setFilteredOptionLists] = useState<OptionList[]>(
    []
  );
  const [optionListSearchQuery, setOptionListSearchQuery] = useState("");
  const [optionListsLoading, setOptionListsLoading] = useState(false);

  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  const handleColorChange = (value: string) => {
    if (value === "") {
      setOption({ ...option, color: "#ffffff" });
    } else {
      setOption({ ...option, color: value });
    }
  };

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setImageBase64(base64Data);
        }
      });
    }
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
    setImageBase64("");
  };

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
    const fetchAllOptionLists = async () => {
      try {
        setOptionListsLoading(true);
        if (!loading && axiosInstance && user?.selectedBranch) {
          let allFetchedOptionLists: OptionList[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedOptionLists = [...allFetchedOptionLists, ...results];
            nextUrl = next;
          }

          setOptionLists(allFetchedOptionLists);
          setFilteredOptionLists(allFetchedOptionLists);
        }
      } catch (error) {
        console.error("Error fetching option lists:", error);
      } finally {
        setOptionListsLoading(false);
      }
    };

    fetchAllOptionLists();
  }, [axiosInstance, loading, user?.selectedBranch]);

  useEffect(() => {
    const fetchOption = async () => {
      try {
        if (!loading && axiosInstance && optionId) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${optionId}/`);
          setOption({
            ...response.data,
            optionLists: response.data.optionList
              ? [response.data.optionList]
              : [],
          });
          setImagePreviewUrl(response.data.image);
          setIsImageUploaded(response.data.image !== "");
        }
      } catch (error) {
        console.error("Error fetching option:", error);
      }
    };

    if (optionId) {
      fetchOption();
    }
  }, [axiosInstance, loading, optionId]);
  

  const handleUpdateOption = async () => {
    const optionData: {
      branch: string | undefined;
      name: string;
      description: string;
      color: string;
      surcharge: string;
      image?: string;
      optionLists: number[];
    } = {
      branch: user?.selectedBranch?.id.toString(),
      name: option.name,
      description: option.description,
      color: option.color,
      surcharge: option.surcharge || "0",
      optionLists: option.optionLists,
    };

    if (imageBase64) {
      optionData.image = imageBase64;
    }

    try {
      if (!loading && axiosInstance && optionId) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION ?? "";
        await axiosInstance.put(`${apiUrl}${optionId}/`, optionData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const successMessage = t("back.management.menu.option.successupdate");
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("option", successMessage);
      }
    } catch (error) {
      console.error("Error updating option:", error);
      setIsSuccess(false);
      const errorMessage = t("back.management.menu.option.failupdate");
      setBannerMessage(errorMessage);
      setShowBanner(true);
    }
  };

  const handleOptionListSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedOptionListSearch(e.target.value);
  };

  const debouncedOptionListSearch = useCallback(
    _.debounce((query: string) => {
      setOptionListSearchQuery(query);
      if (query === "") {
        setFilteredOptionLists(optionLists);
      } else {
        setFilteredOptionLists(
          optionLists.filter((optionList) =>
            optionList.name.toLowerCase().includes(query.toLowerCase())
          )
        );
      }
    }, 300),
    [optionLists]
  );

  useEffect(() => {
    return () => {
      debouncedOptionListSearch.cancel();
    };
  }, [debouncedOptionListSearch]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleOptionListChange = (selectedOptionListId: number) => {
    setOption((prevOption) => {
      const optionLists = prevOption.optionLists.includes(selectedOptionListId)
        ? prevOption.optionLists.filter((id) => id !== selectedOptionListId)
        : [...prevOption.optionLists, selectedOptionListId];

      return {
        ...prevOption,
        optionLists,
      };
    });
  };

  const handleRemoveOptionList = (optionListId: number) => {
    setOption((prevOption) => ({
      ...prevOption,
      optionLists: prevOption.optionLists.filter((id) => id !== optionListId),
    }));
  };

  const selectedOptionLists = useMemo(
    () =>
      optionLists.filter((optionList) =>
        option.optionLists.includes(optionList.id)
      ),
    [optionLists, option.optionLists]
  );

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.option.editOption")}
        </h1>

        {optionId ? (
          <>
            {/* General Information Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.option.generalinfo")}
              </h2>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="optionName"
                  >
                    {t("back.management.menu.option.optionName")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder={t(
                      "back.management.menu.option.enterOptionName"
                    )}
                    value={option.name}
                    onChange={(e) =>
                      setOption({ ...option, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="optionColor"
                  >
                    {t("back.management.menu.option.optionColor")}
                  </label>
                  <select
                    id="optionColor"
                    value={option.color}
                    onChange={(e) => handleColorChange(e.target.value)}
                    className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  >
                    <option value="">
                      {t("back.management.menu.option.selectColor")}
                    </option>
                    {colorOptions.map((colorOption) => (
                      <option key={colorOption.value} value={colorOption.value}>
                        {colorOption.label}
                      </option>
                    ))}
                  </select>
                  {option.color && (
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={option.color}
                      className="mt-2"
                    />
                  )}
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  {t("back.management.menu.option.description")}
                </label>
                <textarea
                  id="description"
                  placeholder={t(
                    "back.management.menu.option.enterDescription"
                  )}
                  value={option.description}
                  onChange={(e) =>
                    setOption({ ...option, description: e.target.value })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
                ></textarea>
              </div>
            </div>

            {/* Surcharge Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.option.surcharge")}
              </h2>
              <input
                type="text"
                placeholder={t("back.management.menu.option.enterSurcharge")}
                value={option.surcharge}
                onChange={(e) =>
                  setOption({ ...option, surcharge: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
            

            {/* Option List Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.option.optionList")}
              </h2>
              <input
                type="text"
                placeholder={t("back.management.menu.option.searchOptionLists")}
                onChange={handleOptionListSearchChange}
                className="mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              />
              {optionListsLoading ? (
                <Spinner />
              ) : (
                <div className="p-4 border rounded-md max-h-40 overflow-y-auto">
                  <p className="text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.option.selectedOptionLists")}
                  </p>
                  <ul>
                    {selectedOptionLists.map((optionList) => (
                      <li
                        key={optionList.id}
                        className="flex items-center mb-2"
                      >
                        <span className="text-gray-700 mr-2">
                          {optionList.name}
                        </span>
                        <button
                          onClick={() => handleRemoveOptionList(optionList.id)}
                          className="text-red-500 hover:text-red-700 focus:outline-none"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <select
                id="optionLists"
                multiple
                value={option.optionLists.map(String)}
                onChange={(e) =>
                  handleOptionListChange(parseInt(e.target.value, 10))
                }
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm mt-2"
              >
                {filteredOptionLists
                  .filter(
                    (optionList) => !option.optionLists.includes(optionList.id)
                  )
                  .map((optionList) => (
                    <option key={optionList.id} value={optionList.id}>
                      {optionList.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* Media Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.option.media")}
              </h2>
              <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
                {t("back.management.menu.option.uploadImage")}
              </p>
              <div
                className={`block w-full border-2 ${
                  isImageUploaded ? "border-solid" : "border-dotted"
                } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
              >
                <input
                  id="fileUpload"
                  type="file"
                  className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                  onChange={handleFileChange}
                />
                {imagePreviewUrl ? (
                  <>
                    <img
                      src={imagePreviewUrl}
                      alt="Preview"
                      className="max-h-full max-w-full p-2"
                    />
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                      style={{ width: "30px", height: "30px" }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="2x"
                      className="text-gray-400 mb-2"
                    />
                    <p className="text-gray-600 mb-2">
                      {t("back.management.menu.option.UploadImageText")}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("option")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.option.cancelbutton")}
              </button>
              <button
                onClick={handleUpdateOption}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                {t("back.management.menu.option.updatebutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.menu.option.noselected")}</p>
        )}
      </div>
    </PageLoader>
  );
};

export default EditOption;
