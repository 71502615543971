// featurepos.tsx

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PosHero from "@/components/front/FeaturePos/PosHero";
import PosFeature from "@/components/front/FeaturePos/PosFeature";
import PosFeature2 from "@/components/front/FeaturePos/PosFeature2";
import Header from "@/components/front/Header";
import FeaturesHero2 from "@/components/front/FeaturesHero";
import Contact from "@/views/front/Contact";

import { useTranslation } from "react-i18next";

const StyledLine = () => {
  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: "#aaa",
    width: "80%",
    margin: "20px auto",
  };

  return <div style={lineStyle}></div>;
};

const FeaturePos = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{t("front.features.pos.helmet")} | Redbird Corporation</title>
        </Helmet>
      </HelmetProvider>
      <div>
        <Header />
        <PosHero />
        <PosFeature />
        <PosFeature2 />
        <StyledLine />
        <FeaturesHero2 />
        <Contact />
      </div>
    </div>
  );
};

export default FeaturePos;
