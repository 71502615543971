import Spinner from "@/components/front/Loader/Spinner";
import { useCustomer } from "@/contexts/CustomerContext";
import {
  clearCart,
  removeItem,
  updateItemQuantity,
} from "@/features/cart/cart";
import { RootState } from "@/store";
import classNames from "@/utils/shared/ClassesUtils";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Tab, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import WarningBanner from "../ui/banners/WarningBanner";
import AddressAutocomplete from "./AddressAutoComplete";
import CookieCheck from "./CookieCheck";
import { applyBranch10Promotion } from "./Promotions/branch10promotion";
import { useAddressAutocomplete } from "./useAddressAutoComplete";

interface Option {
  id: number;
  name: string;
  description: string;
  surcharge: string;
  created_at: string;
}
interface OptionList {
  id: number;
  name: string;
  options: Option[];
  required: boolean;
  option_type: string;
  max_selections: number | null;
}
interface Product {
  id: number;
  name: string;
  unit_price: string;
  description: string;
  image: string;
  product_option_lists: OptionList[];
}

interface SelectedOption {
  quantity: number;
  selected: boolean;
  surcharge?: number;
}

export interface CartItem extends Product {
  quantity: number;
  selected_options: { [key: string]: SelectedOption };
  uniqueId: string; // Add a uniqueId to each cart item
}

interface Branch {
  id: number;
  name: string;
  delivery_feature: boolean;
  service_fee: string;
  tax_rate: string;
  address: {
    street: string;
    city: string;
    province: string;
    country: string;
    postal_code: string;
  };
}

declare let LODOP: any;

const CartPage: React.FC = () => {
  const cart = useSelector((state: RootState) => state.foodCart.items);
  const branchId = useSelector((state: RootState) => state.foodCart.branchId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCheckout, setShowCheckout] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [token, setToken] = useState<string | null>(null);
  const [selectedTip, setSelectedTip] = useState<number | null>(null);
  const [customTip, setCustomTip] = useState<number | null>(null);
  const [comments, setComments] = useState<string>("None");
  const [consent, setConsent] = useState<boolean>(false);
  const [branch, setBranch] = useState<Branch>();
  const [axiosInstance] = useAxiosInstance();
  const [orderMode, setOrderMode] = useState("Pick up");
  const [isOpen, setIsOpen] = useState({
    delivery: false,
    guest: false,
    duplicate: false,
  });
  const [showPayment, setShowPayment] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState("");
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);
  const [deliveryFeeCalculated, setDeliveryFeeCalculated] = useState(false);
  const [deliveryFeeError, setDeliveryFeeError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(searchParams.get("state") || "");
  const { customer } = useCustomer();

  const serviceFee =
    useSelector((state: RootState) => state.foodCart.serviceFee) || 0;
  const taxRate =
    useSelector((state: RootState) => state.foodCart.taxRate) || 0;

  // State for card data
  const [cardData, setCardData] = useState({
    cardholderName: customer?.name || "",
    phone_number: customer?.phone.substring(2) || "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
  });

  // validations for card
  const [errors, setErrors] = useState({
    cardholderName: "",
    phone_number: "",
    cvv: "",
    expiryMonth: "",
    expiryYear: "",
    consent: "", // Add consent error state
  });

  const {
    address: deliveryAddress,
    setAddress: setDeliveryAddress,
    handlePlaceSelected,
    handleInputChange: handleDeliveryAddressChange,
  } = useAddressAutocomplete({
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const {
    address,
    setAddress,
    handlePlaceSelected: handleAddressPlaceSelected,
    handleInputChange: handleAddressChange,
  } = useAddressAutocomplete({
    street: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  useEffect(() => {
    if (customer) {
      setState("customer");
      setSearchParams({ state: "customer" });
      setAddress({
        street: customer.street ?? "",
        city: customer.city ?? "",
        state: customer.state ?? "",
        country: customer.country ?? "",
        pincode: customer.pincode ?? "",
      });
      setDeliveryAddress({
        street: customer.street ?? "",
        city: customer.city ?? "",
        state: customer.state ?? "",
        country: customer.country ?? "",
        pincode: customer.pincode ?? "",
      });
    }
  }, [customer, setAddress, setDeliveryAddress, setSearchParams]);

  useEffect(() => {
    // Load the CLODOP script
    const script = document.createElement("script");
    script.src = "https://35.167.6.231:8443/CLodopfuncs.js";
    script.async = true;
    script.onload = () => {
      console.log("CLODOP script loaded successfully");
      setIsScriptLoaded(true);
    };
    script.onerror = () => {
      console.error("Failed to load CLODOP script");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Scroll function
  const scrollToPaymentForm = () => {
    const paymentForm = document.getElementById("payment-form");
    if (paymentForm) {
      paymentForm.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (showPayment && state !== "") {
      setTimeout(scrollToPaymentForm, 100);
    }
  }, [showPayment, state]);

  // Update card data on input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCardData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Validation logic
    let error = "";
    switch (name) {
      case "cardholderName":
        error = value.trim() === "" ? "Cardholder name is required" : "";
        break;
      case "phone_number":
        error = value.trim() === "" ? "Phone number is required" : "";
        break;
      case "cvv":
        error = !/^[0-9]{3,4}$/.test(value) ? "CVV must be 3 or 4 digits" : "";
        break;
      case "expiryMonth":
        error = !/^(0[1-9]|1[0-2])$/.test(value) ? "Invalid month" : "";
        break;
      case "expiryYear":
        error = !/^\d{4}$/.test(value) ? "Invalid year" : "";
        break;
      case "postal":
        error = value.trim() === "" ? "Postal code is required" : "";
        break;
      default:
        error = value.trim() === "" ? "This field is required" : "";
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    const fetchBranch = async () => {
      if (!axiosInstance || !branchId) {
        setLoading(false);
        return;
      }
      try {
        setLoading(true);
        const businessName = localStorage.getItem("subdomain") || "";
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${branchId}?business_name=${businessName}`;
        const response = await axiosInstance.get(apiUrl);
        setBranch(response.data);
      } catch (error) {
        toast.error("Error fetching branch data");
      } finally {
        setLoading(false);
      }
    };
    fetchBranch();
  }, [axiosInstance, branchId]);

  const handleConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(e.target.checked);
    setErrors((prevErrors) => ({
      ...prevErrors,
      consent: e.target.checked ? "" : "Consent is required",
    }));
  };

  const handleTipSelection = (percentage: number) => {
    setSelectedTip(percentage);
    setCustomTip(null);
  };

  const handleDeliveryConfirmation = () => {
    setIsOpen((p) => ({ ...p, delivery: false }));
    setShowPayment(true);
    setTimeout(scrollToPaymentForm, 100);
  };

  const handleCustomTipInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      setCustomTip(null);
    } else if (value < 0) {
      setCustomTip(null);
      toast.error("Invalid custom tip. Tip cannot be negative.");
    } else {
      setCustomTip(value);
    }
    setSelectedTip(null);
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setComments(event.target.value);
  };

  const validateForm = () => {
    const newErrors = {
      cardholderName:
        cardData.cardholderName.trim() === ""
          ? "Cardholder name is required"
          : "",
      phone_number:
        cardData.phone_number.trim() === "" ? "Phone number is required" : "",
      cvv: !/^[0-9]{3,4}$/.test(cardData.cvv)
        ? "CVV must be 3 or 4 digits"
        : "",
      expiryMonth: !/^(0[1-9]|1[0-2])$/.test(cardData.expiryMonth)
        ? "Invalid month"
        : "",
      expiryYear: !/^\d{4}$/.test(cardData.expiryYear) ? "Invalid year" : "",
      consent: consent ? "" : "Consent is required", // Validate consent,
    };
    setErrors(newErrors);
    console.log("Errors:", newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  /* Printing stuff */

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  /* printing info */

  // print
  const printReceipt = (result: any) => {
    console.log("Printing receipt...");
    if (!isScriptLoaded) {
      console.error("script not loaded");
      return;
    }

    const bridgeIndex = result.bridge_id;
    const bridgeName = result.bridge_name;
    const printerIds = result.printer_name;
    const formattedOrderId = `#${result.order_number
      .slice(1)
      .padStart(3, "0")}`;
    const printerMapping = result.printer_mapping;
    const primaryPrinters = result.primary_printers;
    const orderType = result.order_type;

    const getItemsForPrinter = (printerId: string) => {
      const itemIds = printerMapping[printerId] || [];
      return adjustedCart.filter((item) => itemIds.includes(item.id));
    };

    const formatDeliveryAddress = (addressObj: any): string => {
      if (!addressObj) return "N/A";
      const { address, city, region, postal } = addressObj;
      return `${address}, ${city}, ${region}, ${postal}`.trim();
    };

    const printHeader = (
      LODOP: any,
      result: any,
      cardData: any,
      formattedOrderId: string,
      orderType: string
    ) => {
      // Branch name printing
      LODOP.ADD_PRINT_TEXTA(
        "restaurant_name",
        13,
        35,
        200,
        40,
        result.branch_name
      );
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 15);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "Horient", 2);

      // Order method
      LODOP.ADD_PRINT_TEXT(69, 15, 100, 30, orderType.toUpperCase());
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 13);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      // Order number same line as order method
      LODOP.ADD_PRINT_TEXT(69, 174, 80, 30, formattedOrderId);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 13);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      // Customer name
      LODOP.ADD_PRINT_TEXT(109, 15, 60, 25, "Name: ");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(109, 74, 180, 25, cardData.cardholderName);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      // Phone number
      LODOP.ADD_PRINT_TEXT(138, 15, 60, 25, "Phone:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(138, 74, 180, 25, result.phone_number);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      // Order ID
      LODOP.ADD_PRINT_TEXT(167, 15, 65, 25, "OrderID: ");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(167, 74, 180, 25, result.order_name);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      // Payment method created_at
      LODOP.ADD_PRINT_TEXT(198, 15, 100, 20, "Card Payment");
      LODOP.ADD_PRINT_TEXT(198, 99, 155, 20, new Date().toLocaleString());
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

      let top = 229;

      if (orderType.toLowerCase() === "delivery") {
        LODOP.ADD_PRINT_TEXT(top, 15, 40, 25, "ADD:");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        const formattedAddress = formatDeliveryAddress(result.delivery_address);
        LODOP.ADD_PRINT_TEXT(top, 55, 200, 40, formattedAddress);
        top += 41; // Increment top for the additional field
      }

      LODOP.ADD_PRINT_LINE(top, 0, top, 270, 1, 1);
      LODOP.SET_PRINT_STYLEA(0, "Horient", 2);

      return top + 13; // Return the new top value
    };

    const printFooter = (
      LODOP: any,
      top: number,
      subtotal: number,
      tax: number,
      tipAmount: number,
      serviceFee: number,
      total: number,
      orderType: string,
      deliveryFee: number
    ) => {
      // Numbers
      top += 20; // Add some space before the numbers
      LODOP.ADD_PRINT_TEXT(top, 16, 70, 25, "Subtotal:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${subtotal.toFixed(2)}`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

      top += 25; // Increment the position for the next number
      LODOP.ADD_PRINT_TEXT(top, 16, 70, 25, "Tax:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${tax.toFixed(2)}`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

      top += 25; // Increment the position for the next number
      LODOP.ADD_PRINT_TEXT(top, 16, 70, 25, "Tip:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${tipAmount.toFixed(2)}`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

      if (orderType.toLowerCase() === "delivery") {
        top += 25; // Increment the position for delivery fee
        LODOP.ADD_PRINT_TEXT(top, 16, 120, 25, "Delivery Fee:");
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${deliveryFee.toFixed(2)}`);
        LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
        LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);
      }

      top += 25; // Increment the position for the next number
      LODOP.ADD_PRINT_TEXT(top, 16, 120, 25, "Service Fee:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${serviceFee.toFixed(2)}`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

      top += 25; // Increment the position for the next number
      LODOP.ADD_PRINT_TEXT(top, 16, 70, 25, "Total:");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.ADD_PRINT_TEXT(top, 175, 80, 25, `$ ${total.toFixed(2)}`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);
      LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

      top += 25; // Increment the position for the line
      LODOP.ADD_PRINT_LINE(top, -2, top, 270, 1, 1);

      top += 20; // Add some space before the "PAID" text
      LODOP.ADD_PRINT_TEXT(top, 80, 100, 35, "PAID");
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 14);
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "Horient", 2);

      top += 60; // Add some space before the "Powered by RedBird" text
      LODOP.ADD_PRINT_TEXT(top, 43, 171, 20, "Powered by RedBird");
      LODOP.SET_PRINT_STYLEA(0, "Alignment", 2);
      LODOP.SET_PRINT_STYLEA(0, "ItemType", 1);
      LODOP.SET_PRINT_STYLEA(0, "Horient", 2);

      return top;
    };

    const executePrint = (
      printerId: number,
      items: CartItem[],
      isPrimaryPrinter: boolean
    ) => {
      LODOP.PRINT_INIT("Receipt Print");
      console.log(
        `Setting bridge index: ${bridgeIndex};${bridgeName},${printerId}`
      );
      LODOP.SET_BRIDGE_INDEX(`${bridgeIndex};${bridgeName},${printerId}`);

      let top = printHeader(
        LODOP,
        result,
        cardData,
        formattedOrderId,
        orderType
      );

      if (isPrimaryPrinter) {
        // Print all items and order details on the primary printer
        top += 20; // Add some space after the line
        items.forEach((item, index) => {
          const { name, unit_price, quantity, selected_options } = item;

          // Calculate the item total price
          const itemTotalPrice = parseFloat(unit_price) * quantity;

          // Item quantity
          LODOP.ADD_PRINT_TEXT(top, 16, 15, 25, quantity.toString());
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);

          // Item name
          LODOP.ADD_PRINT_TEXT(top, 49, 150, 25, name);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          // Item price
          LODOP.ADD_PRINT_TEXT(
            top,
            200,
            55,
            25,
            `$ ${itemTotalPrice.toFixed(2)}`
          );
          LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);

          top += 42; // Increment the position for the next item

          // Options
          if (selected_options && Object.keys(selected_options).length > 0) {
            const itemNameLines = Math.ceil(name.length / 20); // Calculate the number of lines for the item name
            const optionsGap = itemNameLines * 5;
            top += optionsGap;

            Object.entries(selected_options).forEach(([optionName, option]) => {
              if (option.selected && option.quantity > 0) {
                let optionPrice = 0;
                if (option.surcharge !== undefined) {
                  optionPrice = option.surcharge * option.quantity;
                }

                LODOP.ADD_PRINT_TEXT(
                  top,
                  64,
                  135,
                  25,
                  `- ${optionName} x${option.quantity}`
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 10);
                LODOP.ADD_PRINT_TEXT(
                  top,
                  200,
                  55,
                  25,
                  `$ ${optionPrice.toFixed(2)}`
                );
                LODOP.SET_PRINT_STYLEA(0, "Alignment", 3);
                top += 41; // Increment the position for the next option
              }
            });
          }
        });

        // Add the line at the end
        top += 20; // Add some space before the line
        LODOP.ADD_PRINT_LINE(top, -2, top, 270, 1, 1);

        // Comment
        top += 20; // Add some space before the comment
        LODOP.ADD_PRINT_TEXT(top, 16, 240, 60, "Comments: \r \n" + comments);
        top += 80; // Add space for the comment content
        LODOP.ADD_PRINT_LINE(top, 0, top, 270, 1, 1);

        //
        top = printFooter(
          LODOP,
          top,
          subtotal,
          tax,
          tipAmount,
          serviceFee,
          total,
          orderType,
          parseFloat(deliveryFee) // Make sure to pass the deliveryFee
        );
      } else {
        // Print only product names on specific item printers

        items.forEach((item, index) => {
          const { name, quantity, selected_options } = item;

          // Item quantity
          LODOP.ADD_PRINT_TEXT(top, 16, 15, 25, quantity.toString());
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          // Item name
          LODOP.ADD_PRINT_TEXT(top, 49, 190, 30, name);
          LODOP.SET_PRINT_STYLEA(0, "FontSize", 12);
          LODOP.SET_PRINT_STYLEA(0, "Bold", 1);

          const itemNameLines = Math.ceil(name.length / 20);
          const optionsGap = itemNameLines * 5;
          top += 30 + optionsGap;

          // Options
          if (selected_options && Object.keys(selected_options).length > 0) {
            Object.entries(selected_options).forEach(([optionName, option]) => {
              if (option.selected && option.quantity > 0) {
                LODOP.ADD_PRINT_TEXT(
                  top,
                  64,
                  175,
                  25,
                  `- ${optionName} x${option.quantity}`
                );
                LODOP.SET_PRINT_STYLEA(0, "FontSize", 11);
                top += 25; // Adjust the position for the next option
              }
            });
            top += 10; // Add some space after options
          }
        });

        // Add the line at the end
        top += 20; // Add some space before the line
        LODOP.ADD_PRINT_LINE(top, -2, top, 270, 1, 1);

        // Comment
        top += 20; // Add some space before the comment
        LODOP.ADD_PRINT_TEXT(top, 16, 240, 60, "Comments: \r \n" + comments);
        top += 80; // Add space for the comment content
        LODOP.ADD_PRINT_LINE(top, 0, top, 270, 1, 1);
      }

      console.log("Initiating print...");
      LODOP.PRINT();
    };

    // Loop through each printer ID and execute the print function
    printerIds.forEach((id: number) => {
      const printerId = id.toString();
      const itemsForPrinter = getItemsForPrinter(printerId);
      const isPrimaryPrinter = primaryPrinters.includes(printerId);

      if (itemsForPrinter.length > 0 || isPrimaryPrinter) {
        executePrint(id, itemsForPrinter, isPrimaryPrinter);
      }
    });
  };

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const tokenInputRef = useRef<HTMLInputElement>(null);

  const handleRemove = (uniqueId: string) => {
    dispatch(removeItem(uniqueId));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleQuantityChange = (itemId: string, increment: boolean) => {
    const item = cart.find((item) => item.uniqueId === itemId);
    if (item) {
      const newQuantity = increment
        ? item.quantity + 1
        : Math.max(item.quantity - 1, 1);
      dispatch(updateItemQuantity({ itemId, quantity: newQuantity }));
    }
  };

  const calculateItemTotal = (item: CartItem) => {
    const baseTotal = parseFloat(item.unit_price) * item.quantity;
    const optionsTotal = Object.values(item.selected_options).reduce(
      (total, option) => {
        if (option.selected && typeof option.surcharge === "number") {
          return total + option.surcharge * option.quantity;
        }
        return total;
      },
      0
    );
    return (baseTotal + optionsTotal).toFixed(2);
  };

  let adjustedCart = cart;

  if (branchId === "10") {
    adjustedCart = applyBranch10Promotion(cart); // Apply the promotion logic
  }

  const subtotal = adjustedCart.reduce(
    (total, item) => total + parseFloat(calculateItemTotal(item) || "0"),
    0
  );
  const tipAmount =
    selectedTip !== null ? subtotal * (selectedTip / 100) : customTip || 0;
  const tax = subtotal * (taxRate / 100);

  const total =
    subtotal +
    tax +
    serviceFee +
    tipAmount +
    (deliveryFee.length > 0 ? parseFloat(deliveryFee) : 0);

  useEffect(() => {
    const handleTokenMessage = (event: MessageEvent) => {
      try {
        const tokenData = JSON.parse(event.data);
        if (tokenData.message) {
          tokenInputRef.current!.value = tokenData.message;
          setToken(tokenData.message);
        }
      } catch (error) {
        toast.error("Error parsing token data");
        console.error("Error :", error);
      }
    };

    window.addEventListener("message", handleTokenMessage, false);
    return () => {
      window.removeEventListener("message", handleTokenMessage, false);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIsTooltipVisible(false);
      }
    };

    document.addEventListener(
      "mousedown",
      handleClickOutside as unknown as EventListener
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside as unknown as EventListener
      );
    };
  }, [tooltipRef]);

  const calculateDeliveryFee = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!branch) return;
    try {
      const requestBody = {
        branch_id: branch.id,
        origin_address: `${branch.address.street}, ${branch.address.city}, ${branch.address.province}, ${branch.address.country}, ${branch.address.postal_code}`,
        address: deliveryAddress?.street,
        city: deliveryAddress?.city,
        region: deliveryAddress?.state,
        country: deliveryAddress?.country,
        postal: deliveryAddress?.pincode,
      };
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/calculate-delivery-fees/`;
      const response = await axiosInstance.post(apiUrl, requestBody);
      setDeliveryFee(response.data.fees);
      setIsOpen((p) => ({ ...p, delivery: true }));
      setDeliveryFeeCalculated(true);
      setDeliveryFeeError("");
    } catch (error: any) {
      toast.error("Error calculating delivery fees");
      setDeliveryFeeError(error.response.data.error);
      setDeliveryFeeCalculated(false);
    }
  };

  const submitOrder = async () => {
    setLoading(true);
    setError("");

    if (!token) {
      setError("Invalid Card Number");
      setLoading(false);
      return;
    }
    if (!validateForm()) {
      setError("Please fix the errors in the form before submitting.");
      setLoading(false);
      return;
    }

    const orderData = {
      order_name: localStorage.getItem("order_name") || "",
      total_price: total,
      tax,
      total_discount_set: 0,
      subtotal_price: subtotal,
      branch_id: branchId,
      tip: tipAmount,
      order_type: orderMode,
      comments,
    };

    // Use adjustedCart instead of cart
    const orderItems = adjustedCart.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
      price_set: parseFloat(item.unit_price) * item.quantity,
      discount_set: 0,
      branch_id: branchId,
      options: Object.entries(item.selected_options)
        .filter(([_, option]) => option.selected)
        .map(([optionName, option]) => ({
          name: optionName,
          quantity: option.quantity,
        })),
    }));

    const requestBody = {
      token,
      order: orderData,
      state: state,
      order_items: orderItems, // Include the adjustedCart items
      card_data: {
        ...cardData,
        address: address?.street,
        city: address?.city,
        region: address?.state,
        country: address?.country,
        postal: address?.pincode,
        phone_number: `+1${cardData.phone_number}`, // Add +1 prefix to the phone number
      },
      mode: orderMode,
      delivery_fee: parseFloat(deliveryFee.length > 0 ? deliveryFee : "0"),
      address: deliveryAddress?.street,
      city: deliveryAddress?.city,
      region: deliveryAddress?.state,
      country: deliveryAddress?.country,
      postal: deliveryAddress?.pincode,
      subscription_id: localStorage.getItem("pushSubscriptionId") || "",
      customer_phone: customer?.phone || "",
    };

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_CREATE_ORDER_ENDPOINT}`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const result = await response.json();
        toast.success("Order created successfully");
        console.log("Order created successfully:", result);
        // Configure result data here

        // Then go to another page /order-confirmation
        printReceipt(result);
        setOrderSuccess(true);
        dispatch(clearCart());
        localStorage.removeItem("order_name");
        navigate("/order-confirmation");
      } else {
        if (response.status === 409) {
          setIsOpen((p) => ({ ...p, duplicate: true }));
        } else {
          const error = await response.json();
          toast.error(`Error creating order: ${error.error}`);
          localStorage.setItem("order_name", error.order_name);
          setError("Payment denied");
        }
        setOrderSuccess(false);
      }
    } catch (err) {
      toast.error("An unexpected error occurred. Please try again.");
      console.error("Unexpected error:", err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unexpected error occurred.");
      }
      setOrderSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen.guest} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen((p) => ({ ...p, guest: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col gap-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 p-4 font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen((p) => ({ ...p, guest: false }));
                        navigate("/customer-login");
                      }}
                    >
                      Sign in to Checkout
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 p-4 font-medium text-white hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setState("guest");
                        setSearchParams({ state: "guest" });
                        setIsOpen((p) => ({ ...p, guest: false }));
                        setShowPayment(true);
                        setTimeout(() => {
                          setShowCheckout(true);
                          scrollToPaymentForm();
                        }, 100);
                      }}
                    >
                      Continue as Guest
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen.delivery} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen((p) => ({ ...p, delivery: false }))}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delivery Fees
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      The delivery fee is ${deliveryFee}. Would you like to
                      proceed?
                    </p>
                  </div>
                  <div className="flex justify-end gap-4 mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      onClick={handleDeliveryConfirmation}
                    >
                      Okay
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setDeliveryFee("");
                        setIsOpen((p) => ({ ...p, delivery: false }));
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen.duplicate} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-between items-start">
                      <p className="text-lg font-semibold text-primary">
                        The phone number: +1{cardData?.phone_number} is already
                        associated with an account.
                      </p>
                    </div>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-600 p-2 font-medium text-white hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen((p) => ({ ...p, duplicate: false }));
                        navigate(
                          `/customer-login?phone=${cardData.phone_number}`
                        );
                      }}
                    >
                      Login to Checkout
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 p-2 font-medium text-white hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => {
                        setIsOpen((p) => ({ ...p, duplicate: false }));
                        setCardData((p) => ({ ...p, phone_number: "" }));
                      }}
                    >
                      Place order with new number
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <div className="p-4">
        {loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <Spinner />
          </div>
        )}
        {cart.length === 0 ? (
          <div className="flex flex-col gap-4 h-screen justify-center items-center">
            <p>Your cart is empty.</p>
            <Link
              to="/store"
              className="text-white bg-red-500 text-sm p-2 rounded-md font-semibold"
            >
              Start Shopping
            </Link>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <Link to="/store" className="text-blue-500 hover:underline">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </Link>
              <h1 className="text-2xl font-bold">Shopping Cart</h1>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2">
                {adjustedCart.map((item) => (
                  <div
                    key={item.uniqueId}
                    className="flex items-center justify-between p-4 border rounded mb-4"
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      className="w-24 h-24 object-cover mr-4 rounded"
                    />
                    <div className="flex-1">
                      <h2 className="font-bold text-lg">{item.name}</h2>
                      <p>Price: ${item.unit_price}</p>
                      <div className="mt-2">
                        {Object.entries(item.selected_options).map(
                          ([optionName, option]) => (
                            <div
                              key={optionName}
                              className="text-sm text-gray-500"
                            >
                              {option.selected &&
                                option.surcharge !== undefined && (
                                  <>
                                    {option.quantity}x {optionName} (+$
                                    {(
                                      option.surcharge * option.quantity
                                    ).toFixed(2)}
                                    )
                                  </>
                                )}
                            </div>
                          )
                        )}
                      </div>
                      <div className="flex items-center mt-2">
                        <button
                          onClick={() => handleRemove(item.uniqueId)}
                          className="text-red-500 hover:underline mr-2"
                        >
                          Remove
                        </button>
                        <div className="flex items-center">
                          <button
                            onClick={() =>
                              handleQuantityChange(item.uniqueId, false)
                            }
                            disabled={item.quantity <= 1}
                            className="px-2 py-1 border rounded-l"
                          >
                            -
                          </button>
                          <span className="px-2 py-1 border-t border-b">
                            {item.quantity}
                          </span>
                          <button
                            onClick={() =>
                              handleQuantityChange(item.uniqueId, true)
                            }
                            className="px-2 py-1 border rounded-r"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-xl font-bold">
                        ${calculateItemTotal(item)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-4 border rounded">
                <h2 className="text-xl font-bold mb-4">Cart Summary</h2>
                <div className="flex justify-between mb-2">
                  <span>Subtotal ({adjustedCart.length} items)</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Tax (8.25%)</span>
                  <span>${tax.toFixed(2)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Tip</span>
                  <span>${tipAmount.toFixed(2)}</span>
                </div>
                <div className="flex justify-between mb-2">
                  <span>Service Fee</span>
                  <span>${serviceFee.toFixed(2)}</span>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Tip
                  </label>
                  <div className="mt-2 grid grid-cols-2 sm:grid-cols-4 gap-2">
                    {[0, 15, 18, 20].map((percentage) => (
                      <button
                        key={percentage}
                        className={`px-4 py-2 rounded-md ${
                          selectedTip === percentage
                            ? "bg-red-500 text-white"
                            : "bg-gray-200 text-gray-700"
                        }`}
                        onClick={() => handleTipSelection(percentage)}
                      >
                        {percentage}%
                      </button>
                    ))}
                    <div className="col-span-2 sm:col-span-1">
                      <input
                        type="number"
                        placeholder="Custom"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md"
                        value={customTip || ""}
                        onChange={handleCustomTipInput}
                      />
                    </div>
                  </div>
                </div>
                {orderMode === "Delivery" && (
                  <div className="flex justify-between mb-2">
                    <span>Delivery Fees</span>
                    <span>
                      {deliveryFee === "" ? "To be calculated" : deliveryFee}
                    </span>
                  </div>
                )}
                <div className="flex justify-between mb-4">
                  <span>Total</span>
                  <span className="text-2xl font-bold">
                    ${total.toFixed(2)}
                  </span>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Comments
                  </label>
                  <textarea
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    rows={3}
                    value={comments}
                    onChange={handleCommentsChange}
                    placeholder="Enter any additional comments or instructions"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="promoCode"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Promo Code
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <input
                      type="text"
                      name="promoCode"
                      id="promoCode"
                      className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                      placeholder="Enter promo code"
                    />
                    <button className="bg-red-500 text-white py-2 px-4 rounded-r hover:bg-red-600">
                      Apply
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between mt-4 mb-4">
                  <Tab.Group>
                    <Tab.List className="flex space-x-2 rounded-full border border-red-200 p-1">
                      {branch?.delivery_feature ? (
                        <>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "px-4 py-2 text-sm font-medium rounded-full transition-colors duration-150 ease-in-out",
                                selected
                                  ? "bg-red-500 text-white shadow-md"
                                  : "bg-white text-red-500 hover:bg-red-50"
                              )
                            }
                            onClick={() => setOrderMode("Pick up")}
                          >
                            Pick up
                          </Tab>
                          <Tab
                            className={({ selected }) =>
                              classNames(
                                "px-4 py-2 text-sm font-medium rounded-full transition-colors duration-150 ease-in-out",
                                selected
                                  ? "bg-red-500 text-white shadow-md"
                                  : "bg-white text-red-500 hover:bg-red-50"
                              )
                            }
                            onClick={() => setOrderMode("Delivery")}
                          >
                            Delivery
                          </Tab>
                        </>
                      ) : (
                        <Tab
                          className={() =>
                            classNames(
                              "w-full px-4 py-2 text-sm font-medium rounded-full transition-colors duration-150 ease-in-out",
                              "bg-red-500 text-white shadow-md"
                            )
                          }
                          onClick={() => setOrderMode("Pick up")}
                        >
                          Pick up Only
                        </Tab>
                      )}
                    </Tab.List>
                  </Tab.Group>
                </div>

                <button
                  onClick={handleClearCart}
                  className="bg-gray-500 text-white py-2 px-4 rounded w-full hover:bg-gray-600 mt-2"
                >
                  Clear Cart
                </button>
                <button
                  onClick={() => {
                    if (state === "") {
                      setIsOpen((p) => ({ ...p, guest: true }));
                    } else {
                      setShowCheckout(true);
                      if (orderMode === "Pick up") {
                        setShowPayment(true);
                        setTimeout(scrollToPaymentForm, 100);
                      } else {
                        setShowDeliveryForm(true);
                      }
                    }
                  }}
                  className="bg-red-500 text-white py-2 px-4 rounded w-full hover:bg-red-600 mt-2"
                >
                  Checkout
                </button>
              </div>
            </div>
            {showCheckout && orderMode === "Delivery" && showDeliveryForm && (
              <div className="mt-6 p-4 border rounded">
                {deliveryFeeError && (
                  <WarningBanner
                    title="Delivery Fees Calculation Error"
                    text={deliveryFeeError}
                    className="mt-4"
                  />
                )}
                <h2 className="text-xl font-bold mb-4">Address Details</h2>
                <form
                  className="grid grid-cols-1 lg:grid-cols-2 gap-4"
                  onSubmit={calculateDeliveryFee}
                >
                  <AddressAutocomplete
                    value={deliveryAddress.street}
                    onChange={(value: string) =>
                      handleDeliveryAddressChange("street", value)
                    }
                    onPlaceSelected={handlePlaceSelected}
                  />
                  <div>
                    <label
                      htmlFor="city"
                      className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <div className="mt-2">
                      <input
                        id="city"
                        type="text"
                        name="city"
                        required
                        autoComplete="city"
                        value={deliveryAddress.city}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          handleDeliveryAddressChange("city", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="state"
                      className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                    >
                      State
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="state"
                        name="state"
                        required
                        autoComplete="state"
                        value={deliveryAddress.state}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          handleDeliveryAddressChange("state", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                    >
                      Country
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="country"
                        name="country"
                        required
                        autoComplete="country"
                        value={deliveryAddress.country}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          handleDeliveryAddressChange("country", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="pincode"
                      className="mt-2 block text-sm font-medium leading-6 text-gray-900"
                    >
                      Zip Code
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="pincode"
                        name="pincode"
                        required
                        autoComplete="pincode"
                        value={deliveryAddress.pincode}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) =>
                          handleDeliveryAddressChange("pincode", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full inline-flex justify-center lg:justify-end items-center">
                    <button
                      type="submit"
                      className="p-2 bg-red-500 hover:bg-red-600 text-white rounded-md"
                    >
                      Check Delivery Fees
                    </button>
                  </div>
                </form>
              </div>
            )}
            {showPayment &&
              (orderMode === "Pick up" ||
                (orderMode === "Delivery" && deliveryFeeCalculated)) && (
                <div id="payment-form" className="mt-6 p-4 border rounded">
                  {error && (
                    <WarningBanner
                      title="Payment Error"
                      text={error}
                      className="mt-4"
                    />
                  )}
                  <h2 className="text-xl font-bold mb-4">Payment Details</h2>
                  <h3 className="text-lg mb-1">Card Number</h3>
                  <CookieCheck />
                  <form name="tokenform" id="tokenform">
                    <iframe
                      title="Token Frame"
                      ref={iframeRef}
                      id="tokenFrame"
                      name="tokenFrame"
                      src="https://fts.cardconnect.com/itoke/ajax-tokenizer.html?css=%2Eerror%7Bcolor%3A%20red%3B%7Dinput%7Bwidth%3A300px%3B%7Dinput%7Bheight%3A30px%3B%7D"
                      className="w-full border rounded mb-4"
                      style={{
                        width: "330px",
                        height: "55px",
                        borderRadius: "8px",
                        outline: "none",
                        transitionDuration: "0.3s",
                      }}
                    />
                    <input
                      type="hidden"
                      ref={tokenInputRef}
                      name="token"
                      id="tokenInput"
                    />
                    <div className="space-y-4">
                      <div>
                        <label
                          htmlFor="cardholderName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cardholder Name
                        </label>
                        <input
                          type="text"
                          name="cardholderName"
                          id="cardholderName"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          value={cardData.cardholderName}
                          onChange={handleInputChange}
                        />
                        {errors.cardholderName && (
                          <div className="text-red-500 text-sm">
                            {errors.cardholderName}
                          </div>
                        )}
                      </div>
                      <div className="grid lg:grid-cols-3 gap-4">
                        <div>
                          <label
                            htmlFor="cvv"
                            className="block text-sm font-medium text-gray-700"
                          >
                            CVV
                          </label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={4}
                            name="cvv"
                            id="cvv"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            value={cardData.cvv}
                            onChange={handleInputChange}
                          />
                          {errors.cvv && (
                            <div className="text-red-500 text-sm">
                              {errors.cvv}
                            </div>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="expiryMonth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Expiration Month
                          </label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            min={1}
                            max={12}
                            placeholder="MM"
                            name="expiryMonth"
                            id="expiryMonth"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            value={cardData.expiryMonth}
                            onChange={handleInputChange}
                          />
                          {errors.expiryMonth && (
                            <div className="text-red-500 text-sm">
                              {errors.expiryMonth}
                            </div>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="expiryYear"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Expiration Year
                          </label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={4}
                            name="expiryYear"
                            id="expiryYear"
                            placeholder="YYYY"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                            value={cardData.expiryYear}
                            onChange={handleInputChange}
                          />
                          {errors.expiryYear && (
                            <div className="text-red-500 text-sm">
                              {errors.expiryYear}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="phone_number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Phone Number
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center">
                            <span className="text-gray-500 sm:text-sm border-r border-gray-300 bg-gray-100 px-3 py-2 h-full">
                              +1
                            </span>
                          </div>
                          <input
                            type="tel"
                            name="phone_number"
                            id="phone_number"
                            className="block w-full pl-16 border border-gray-300 rounded-md shadow-sm p-2"
                            value={cardData.phone_number}
                            onChange={handleInputChange}
                            pattern="[0-9]{10}"
                            required
                          />
                        </div>
                        {errors.phone_number && (
                          <div className="text-red-500 text-sm">
                            {errors.phone_number}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          name="consent"
                          id="consent"
                          checked={consent}
                          onChange={handleConsentChange}
                        />
                        <label
                          htmlFor="consent"
                          className="text-sm font-medium text-gray-700 ml-2"
                        >
                          Please enter 10 digit US phone number to receive order
                          confirmation. By entering your number, you consent to
                          receive SMS from Redbird.
                        </label>
                        {errors.consent && (
                          <div className="text-red-500 text-sm">
                            {errors.consent}
                          </div>
                        )}
                      </div>

                      {/* Billing Information title with tooltip */}
                      <div className="flex items-center mt-6 mb-4">
                        <h3 className="text-lg font-bold">
                          Billing Information
                        </h3>
                        <div className="relative ml-2" ref={tooltipRef}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="text-gray-500 cursor-pointer"
                            onClick={() =>
                              setIsTooltipVisible(!isTooltipVisible)
                            }
                          />
                          <div
                            className={`absolute z-10 p-2 bg-white border rounded shadow-lg transition-opacity duration-300
                        right-0 w-48 text-sm
                        lg:right-auto lg:left-0 lg:w-64 lg:text-base
                        ${
                          isTooltipVisible
                            ? "opacity-100"
                            : "opacity-0 pointer-events-none"
                        }`}
                          >
                            This is billing address for verifying cardholder
                            information only.
                          </div>
                        </div>
                      </div>
                      <AddressAutocomplete
                        value={address.street}
                        onChange={(value: string) =>
                          handleAddressChange("street", value)
                        }
                        onPlaceSelected={handleAddressPlaceSelected}
                      />
                      <div>
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          value={address.city}
                          onChange={(e) =>
                            handleAddressChange("city", e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="region"
                          className="block text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <input
                          type="text"
                          name="region"
                          id="region"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          value={address.state}
                          onChange={(e) =>
                            handleAddressChange("state", e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          value={address.country}
                          onChange={(e) =>
                            handleAddressChange("country", e.target.value)
                          }
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="postal"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Postal Code
                        </label>
                        <input
                          type="text"
                          name="postal"
                          id="postal"
                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                          value={address.pincode}
                          onChange={(e) =>
                            handleAddressChange("pincode", e.target.value)
                          }
                        />
                        <br />
                      </div>
                    </div>
                  </form>
                  <button
                    onClick={submitOrder}
                    className="bg-red-500 text-white py-2 px-4 rounded w-full hover:bg-red-600"
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Submit Payment"}
                  </button>
                  {error && <div className="text-red-500 mt-4">{error}</div>}
                  {orderSuccess && (
                    <div className="bg-green-100 text-green-800 p-4 rounded mt-4">
                      Your order has been successfully placed!
                    </div>
                  )}
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default CartPage;
