import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddDeviceProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}
interface Category {
  id: number;
  name: string;
}
interface Product {
  id: number;
  name: string;
}

const AddDevice = ({ onMenuItemClick, setSuccessMessage }: AddDeviceProps) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const selectedBranch = user?.selectedBranch;
  const [axiosInstance, loading, isFetching] = useAxiosInstance();

  const [name, setName] = useState("");
  const [deviceNumber, setDeviceNumber] = useState("");
  const [status, setStatus] = useState("Active");
  const [mode, setMode] = useState("Online");
  const [isPrimary, setIsPrimary] = useState(false);

  // category and product selections for device
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_DEVICES ?? "";

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          let allFetchedCategories: Category[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          if (categorySearchQuery) {
            nextUrl += `&name=${encodeURIComponent(categorySearchQuery)}`;
          }

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedCategories = [...allFetchedCategories, ...results];
            nextUrl = next;
          }

          setAllCategories(allFetchedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchAllProducts = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          if (productSearchQuery) {
            nextUrl += `&menu_display_name=${encodeURIComponent(
              productSearchQuery
            )}`;
          }

          const response = await axiosInstance.get(nextUrl);
          const { results } = response.data;
          setAllProducts(results);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchAllCategories();
    fetchAllProducts();
  }, [
    axiosInstance,
    loading,
    user?.selectedBranch,
    categorySearchQuery,
    productSearchQuery,
  ]);

  const handleCategoryChange = (selectedCategoryId: number) => {
    setSelectedCategories((prevSelected) => {
      const categories = prevSelected.includes(selectedCategoryId)
        ? prevSelected.filter((id) => id !== selectedCategoryId)
        : [...prevSelected, selectedCategoryId];

      return categories;
    });
  };

  const handleProductChange = (selectedProductId: number) => {
    setSelectedProducts((prevSelected) => {
      const products = prevSelected.includes(selectedProductId)
        ? prevSelected.filter((id) => id !== selectedProductId)
        : [...prevSelected, selectedProductId];

      return products;
    });
  };

  const handleRemoveCategory = (categoryId: number) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.filter((id) => id !== categoryId)
    );
  };

  const handleRemoveProduct = (productId: number) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.filter((id) => id !== productId)
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const payload = {
      name,
      device_number: deviceNumber,
      branch: selectedBranch?.id,
      status,
      mode,
      is_primary: isPrimary,
      categories: selectedCategories,
      products: selectedProducts,
    };

    try {
      if (!loading && axiosInstance) {
        const response = await axiosInstance.post(apiUrl, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 201) {
          const successMessage = t(
            "back.management.business.device.successAdd"
          );
          setSuccessMessage(successMessage);
          setIsSuccess(true);
          setBannerMessage(successMessage);
          setShowBanner(true);
          setTimeout(() => onMenuItemClick("devices", successMessage), 3000); // Delay to show success message
        } else {
          const errorMessage = t("back.management.business.device.failAdd");
          setIsSuccess(false);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
      }
    } catch (error) {
      console.error("Error adding device", error);
      setIsSuccess(false);
      setBannerMessage(t("back.management.business.device.failAdd"));
      setShowBanner(true);
    }
  };

  const debouncedCategorySearch = useCallback(
    _.debounce((query: string) => setCategorySearchQuery(query), 300),
    []
  );

  const debouncedProductSearch = useCallback(
    _.debounce((query: string) => setProductSearchQuery(query), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedCategorySearch.cancel();
      debouncedProductSearch.cancel();
    };
  }, [debouncedCategorySearch, debouncedProductSearch]);

  const handleCategorySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedCategorySearch(e.target.value);
  };

  const handleProductSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    debouncedProductSearch(e.target.value);
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.business.device.addNewDevice")}
        </h1>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="name"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.name")}
            </label>
            <input
              id="name"
              name="name"
              type="text"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              placeholder={t("back.management.business.device.enterName")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="device_number"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.deviceNumber")}
            </label>
            <input
              id="device_number"
              name="device_number"
              type="text"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              placeholder={t(
                "back.management.business.device.enterDeviceNumber"
              )}
              value={deviceNumber}
              onChange={(e) => setDeviceNumber(e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="status"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.status")}
            </label>
            <select
              id="status"
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            >
              <option value="Active">
                {t("back.management.business.device.active")}
              </option>
              <option value="Inactive">
                {t("back.management.business.device.inactive")}
              </option>
            </select>
          </div>
          <div>
            <label
              htmlFor="mode"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.mode")}
            </label>
            <select
              id="mode"
              name="mode"
              value={mode}
              onChange={(e) => setMode(e.target.value)}
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            >
              <option value="Offline">
                {t("back.management.business.device.offline")}
              </option>
              <option value="Online">
                {t("back.management.business.device.online")}
              </option>
            </select>
          </div>
          <div>
            <label
              htmlFor="categories"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.categories")}
            </label>
            <input
              type="text"
              placeholder={t(
                "back.management.business.device.searchCategories"
              )}
              onChange={handleCategorySearchChange}
              className="mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            />
            <div className="mt-4">
              <p className="text-gray-700 text-sm font-bold mb-2">
                {t("back.management.business.device.selectedCategories")}
              </p>
              <ul>
                {allCategories
                  .filter((category) =>
                    selectedCategories.includes(category.id)
                  )
                  .map((category) => (
                    <li key={category.id} className="flex items-center mb-2">
                      <span className="text-gray-700 mr-2">
                        {category.name}
                      </span>
                      <button
                        onClick={() => handleRemoveCategory(category.id)}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <select
              id="categories"
              multiple
              value={selectedCategories.map(String)}
              onChange={(e) =>
                handleCategoryChange(parseInt(e.target.value, 10))
              }
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            >
              {allCategories
                .filter((category) => !selectedCategories.includes(category.id))
                .map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="products"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.products")}
            </label>
            <input
              type="text"
              placeholder={t("back.management.business.device.searchProducts")}
              onChange={handleProductSearchChange}
              className="mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            />
            <div className="mt-4">
              <p className="text-gray-700 text-sm font-bold mb-2">
                {t("back.management.business.device.selectedProducts")}
              </p>
              <ul>
                {allProducts
                  .filter((product) => selectedProducts.includes(product.id))
                  .map((product) => (
                    <li key={product.id} className="flex items-center mb-2">
                      <span className="text-gray-700 mr-2">{product.name}</span>
                      <button
                        onClick={() => handleRemoveProduct(product.id)}
                        className="text-red-500 hover:text-red-700 focus:outline-none"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
            <select
              id="products"
              multiple
              value={selectedProducts.map(String)}
              onChange={(e) =>
                handleProductChange(parseInt(e.target.value, 10))
              }
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            >
              {allProducts
                .filter((product) => !selectedProducts.includes(product.id))
                .map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="is_primary"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.business.device.isPrimary")}
            </label>
            <input
              id="is_primary"
              name="is_primary"
              type="checkbox"
              className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
              checked={isPrimary}
              onChange={(e) => setIsPrimary(e.target.checked)}
            />
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              {t("back.management.business.device.addDevice")}
            </button>
          </div>
        </form>
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("devices")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.product.cancelbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddDevice;
