import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PageLoader from "@/components/back/Spinner";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import DateFilterButton from "@/components/back/DateFilterButton";
import MonthToDateSummary from "./MonthToDateSummary";
import SelectedDateSummary from "./SelectedDateSummary";
import PrintableDailyReport from "./PrintableDailyReport";
import {
  startOfMonth,
  startOfDay,
  endOfDay,
  addHours,
  addMinutes,
} from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";

const initialSummaryData = {
  total_subtotal_sales: 0,
  total_tips: 0,
  total_tax: 0,
  total_paid_orders: 0,
  total_unpaid_orders: 0,
  total_service_fee_charge: 0,
};

const Report: React.FC = () => {
  const [summaryData, setSummaryData] = useState(initialSummaryData);
  const [monthToDateTotals, setMonthToDateTotals] = useState<{
    total_subtotal_sales: number;
    total_tips: number;
    total_tax: number;
    total_paid_orders: number;
    total_unpaid_orders: number;
    total_service_fee_charge: number;
  } | null>(null);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [axiosInstance, isFetching] = useAxiosInstance();
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const [lastFetchedStartDate, setLastFetchedStartDate] = useState<
    string | null
  >(null);

  const printableRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  const getUTCDateWithDSTAdjustment = (date: Date, isStartOfDay: boolean) => {
    const timeZone = "America/Chicago";
    const cstDate = utcToZonedTime(date, timeZone);
    const januaryOffset = new Date(
      cstDate.getFullYear(),
      0,
      1
    ).getTimezoneOffset();
    const isDST = cstDate.getTimezoneOffset() < januaryOffset;
    const utcOffset = isDST ? 5 : 6;

    if (isStartOfDay) {
      return addHours(startOfDay(cstDate), utcOffset);
    } else {
      return addHours(endOfDay(cstDate), utcOffset);
    }
  };

  const formatDateToUTC = (date: Date) => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
  };

  const getMonthToDateRange = () => {
    const now = new Date();
    const timeZone = "America/Chicago";
    const cstNow = utcToZonedTime(now, timeZone);
    const firstDayOfMonth = startOfMonth(cstNow);

    const startDate = getUTCDateWithDSTAdjustment(firstDayOfMonth, true);
    const endDate = adjustCurrentTimeToUTC(cstNow);

    return {
      startDate: formatDateToUTC(startDate),
      endDate: formatDateToUTC(endDate),
    };
  };

  const adjustCurrentTimeToUTC = (date: Date) => {
    const timeZone = "America/Chicago";
    const zonedDate = utcToZonedTime(date, timeZone);
    const offset = zonedDate.getTimezoneOffset();
    return addMinutes(zonedDate, offset);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("user_data");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData.selectedBranch && parsedData.selectedBranch.id) {
          setSelectedBranch(parsedData.selectedBranch.id);
        } else {
          console.warn("No valid selected branch found in localStorage");
        }
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    } else {
      console.warn("No user data found in localStorage");
    }
  }, []);

  const fetchSummaryData = async () => {
    try {
      setTotalsLoading(true);
      const start = startDate
        ? getUTCDateWithDSTAdjustment(startDate, true)
        : new Date();
      const end = endDate
        ? getUTCDateWithDSTAdjustment(endDate, false)
        : new Date();

      const url = `/api/summary/${selectedBranch}/?start_date=${formatDateToUTC(
        start
      )}&end_date=${formatDateToUTC(end)}`;

      const response = await axiosInstance.get(url);
      if (!response.status.toString().startsWith("2")) {
        throw new Error("Failed to fetch summary data");
      }

      const data = response.data;
      setSummaryData(data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setTotalsLoading(false);
    }
  };

  const fetchMonthToDateTotals = async () => {
    setError(null);

    try {
      const { startDate, endDate } = getMonthToDateRange();
      const url = `/api/summary/${selectedBranch}/?start_date=${startDate}&end_date=${endDate}`;

      const response = await axiosInstance.get(url);
      if (!response.status.toString().startsWith("2")) {
        throw new Error("Failed to fetch month-to-date totals");
      }

      const data = response.data;
      setMonthToDateTotals({
        total_subtotal_sales: data.total_subtotal_sales,
        total_tips: data.total_tips,
        total_tax: data.total_tax,
        total_paid_orders: data.total_paid_orders,
        total_unpaid_orders: data.total_unpaid_orders,
        total_service_fee_charge: data.total_service_fee_charge,
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBranch) {
        const { startDate } = getMonthToDateRange();
        if (startDate !== lastFetchedStartDate) {
          await fetchMonthToDateTotals();
          setLastFetchedStartDate(startDate);
        }
        await fetchSummaryData();
      }
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [selectedBranch, startDate, endDate]);

  return (
    <BranchRequiredWrapper>
      <PageLoader isFetching={isFetching}>
        <div className="min-h-screen p-4 bg-gray-100">
          <MonthToDateSummary
            monthToDateTotals={monthToDateTotals}
            loading={loading}
          />

          <div className="my-6 flex justify-center">
            <DateFilterButton
              onStartDateChange={(date) => setStartDate(date)}
              onEndDateChange={(date) => setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              labelText="Filter by date"
            />
            <button
              onClick={handlePrint}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            >
              Print Daily Report
            </button>
          </div>

          <SelectedDateSummary
            summaryData={summaryData}
            loading={totalsLoading}
          />

          <div style={{ display: "none" }}>
            <PrintableDailyReport
              ref={printableRef}
              summaryData={summaryData}
            />
          </div>
        </div>
      </PageLoader>
    </BranchRequiredWrapper>
  );
};

export default Report;
