import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

interface OperatingHoursInputProps {
  operatingHours: OperatingHour[];
  setOperatingHours: (hours: OperatingHour[]) => void;
}

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const OperatingHoursInput: React.FC<OperatingHoursInputProps> = ({
  operatingHours,
  setOperatingHours,
}) => {
  const handleAddOperatingHour = () => {
    setOperatingHours([
      ...operatingHours,
      {
        id: Date.now(),
        day: "Monday",
        open_time: "09:00",
        close_time: "17:00",
      },
    ]);
  };

  const handleRemoveOperatingHour = (id: number) => {
    setOperatingHours(operatingHours.filter((hour) => hour.id !== id));
  };

  const handleDayChange = (id: number, day: string) => {
    setOperatingHours(
      operatingHours.map((hour) => (hour.id === id ? { ...hour, day } : hour))
    );
  };

  const handleTimeChange = (
    id: number,
    type: "open_time" | "close_time",
    time: string
  ) => {
    setOperatingHours(
      operatingHours.map((hour) =>
        hour.id === id ? { ...hour, [type]: time } : hour
      )
    );
  };

  return (
    <div>
      <h3 className="font-bold mb-2">Operating Hours</h3>
      {operatingHours.map((hour) => (
        <div key={hour.id} className="flex items-center space-x-2 mb-2">
          <select
            value={hour.day}
            onChange={(e) => handleDayChange(hour.id, e.target.value)}
            className="p-2 border rounded"
          >
            {daysOfWeek.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <input
            type="time"
            value={hour.open_time}
            onChange={(e) =>
              handleTimeChange(hour.id, "open_time", e.target.value)
            }
            className="p-2 border rounded"
          />
          <input
            type="time"
            value={hour.close_time}
            onChange={(e) =>
              handleTimeChange(hour.id, "close_time", e.target.value)
            }
            className="p-2 border rounded"
          />
          <button
            onClick={() => handleRemoveOperatingHour(hour.id)}
            className="p-2 bg-red-500 text-white rounded"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}
      <button
        onClick={handleAddOperatingHour}
        className="p-2 bg-blue-500 text-white rounded mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        Add Operating Hour
      </button>
    </div>
  );
};

export default OperatingHoursInput;
