import React, { useState, useEffect, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";

import CareersHeroImage from "@/assets/img/CareersHero.png";

const CareersHero = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const wrapperStyle: CSSProperties = {
    maxWidth: "80%",
    margin: "0 auto",
  };

  const imageStyle = {
    flexBasis: isMobile ? "100%" : "50%",
    display: "flex",
    justifyContent: "center",
  };

  const imageTagStyle = {
    maxWidth: isMobile ? "80%" : "100%",
    height: "auto",
  };

  const styles: Record<string, CSSProperties> = {
    container: {
      textAlign: "center",
      padding: "50px",
    },
    title: {
      fontSize: isMobile ? "2em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
    },
    subtitle: {
      fontSize: "1.2rem",
      marginBottom: "40px",
      color: "#666",
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      marginBottom: "40px",
      display: "block",
      margin: "auto",
      borderRadius: "20px",
    },
    feature: {
      display: isMobile ? "block" : "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
    featureTitle: {
      fontSize: "2rem",
      color: "#C70039",
      fontWeight: "bold",
    },
    featureDescription: {
      fontSize: isMobile ? "1em" : "1.2em",
      color: "#666",
      maxWidth: "80%",
      margin: "auto",
    },
    icon: {
      color: "#000000",
      fontSize: "2em",
      marginBottom: "10px",
    },
  };

  return (
    <div style={wrapperStyle}>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("front.company.careers.helmet")} | Redbird Corporation
          </title>
        </Helmet>
      </HelmetProvider>
      <div style={styles.container}>
        <div className="relative z-10 px-6 pt-16 md:pt-24 pb-16 md:pb-24 space-y-3">
          <h1 className="relative z-10 pb-6 text-3xl sm:text-5xl md:text-6xl lg:text-7.5xl font-black tracking-snug text-center leading-11 sm:leading-15 md:leading-18 lg:leading-22 mx-auto max-w-5xl">
            <span className="flex justify-center">
              <span className="mr-4">{t("front.company.careers.title")}</span>
            </span>
          </h1>
          <div className="relative z-10 pb-10 text-gray-500 text-lg md:text-2xl text-center leading-normal md:leading-9">
            <p className="sm:text-lg max-w-4xl mx-auto">
              {t("front.company.careers.description")}
            </p>
          </div >
          <div style={imageStyle}>
          <img
            src={CareersHeroImage}
            alt="Career Page Hero"
            style={imageTagStyle}
          />
          </div>
        </div>
        <div className="px-6 py-10">
          <div style={styles.feature}>
            <div>
              {/* <FontAwesomeIcon icon={faHeadset} style={styles.icon} /> */}
              <h1 style={styles.featureTitle}>
                {t("front.company.careers.title2")}
              </h1>
              <p style={styles.featureDescription}>
                {t("front.company.careers.description2")}
              </p>
            </div>
            <div>
              {/* <FontAwesomeIcon icon={farBell} style={styles.icon} /> */}
              <h1 style={styles.featureTitle}>
                {t("front.company.careers.title3")}
              </h1>
              <p style={styles.featureDescription}>
                {t("front.company.careers.description3")}
              </p>
            </div>
            <div>
              {/* <FontAwesomeIcon icon={faChalkboardUser} style={styles.icon} /> */}
              <h1 style={styles.featureTitle}>
                {t("front.company.careers.title4")}
              </h1>
              <p style={styles.featureDescription}>
                {t("front.company.careers.description4")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersHero;
