import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import { setPricingPlan } from "@/store/modules/pricingPlanReducer";
import { PricingPlan } from "@/application/enums/shared/PricingPlan";
import { useTranslation } from "react-i18next";

const PricingToggle = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pricingPlan = useSelector<RootState>(
    (state) => state.pricingPlan.value
  );

  const toggleToYearly = () => {
    dispatch(setPricingPlan(PricingPlan.YEARLY));
  };

  const toggleToMonthly = () => {
    dispatch(setPricingPlan(PricingPlan.MONTHLY));
  };

  return (
    <div className="flex justify-end items-center">
      <div className="relative inline-flex items-center bg-white border-2 border-gray-300 rounded-full">
        <span
          className={`px-4 py-1 rounded-full cursor-pointer ${
            pricingPlan === PricingPlan.MONTHLY
              ? "bg-red-500 text-white"
              : "text-gray-700"
          }`}
          onClick={toggleToMonthly}
        >
          {t("front.pricing.monthly")}
        </span>
        <span
          className={`px-4 py-1 rounded-full cursor-pointer ${
            pricingPlan === PricingPlan.YEARLY
              ? "bg-red-500 text-white"
              : "text-gray-700"
          }`}
          onClick={toggleToYearly}
        >
          {t("front.pricing.yearly")}
        </span>
      </div>
    </div>
  );
};

export default PricingToggle;
