import { useTranslation } from "react-i18next";
import Header from "@/components/front/Header";
import { Link } from "react-router-dom";
import heroImage from "@/assets/img/hero.png";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <div className="relative" style={{ paddingTop: "60px" }}>
      <Header />
      <div className="relative z-10 px-6 pt-16 md:pt-24 pb-16 md:pb-24 space-y-3">
        <h1 className="relative z-10 pb-6 text-3xl md:text-6xl lg:text-7.5xl font-black tracking-snug text-center leading-11 sm:leading-15 md:leading-18 lg:leading-22 text-gray-800 dark:text-white">
          <div className="flex flex-col items-center justify-center md:flex-row">
            <div className="mb-2 md:mb-0">
              <span className="mr-3 md:mr-5">{t("front.hero.headline1")}</span>
            </div>
            <div>
              <span className="text-red-500">{t("front.hero.headline2")}</span>
              <span>{t("front.hero.headline3")}</span>
            </div>
          </div>
        </h1>
        <div className="relative z-10 pb-10 text-gray-500 text-lg md:text-2xl text-center leading-normal md:leading-9">
          <p className="text-lg max-w-2xl mx-auto">
            {t("front.hero.headline4")}
          </p>
        </div>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <div className="rounded-md shadow">
            <Link
              to="/contact"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md bg-red-500 text-white hover:bg-red-600 md:py-4 md:text-lg md:px-10"
            >
              {t("front.hero.start")}
            </Link>
          </div>
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <Link
              to="/contact"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-black hover:text-black hover:bg-gray-50 md:py-4 md:text-lg md:px-10 dark:bg-white dark:text-black dark:hover:bg-gray-100"
            >
              {t("front.hero.contact")}
            </Link>
          </div>
        </div>
        <img
          src={heroImage}
          alt="Description"
          className="mt-4 mx-auto shadow-lg transform transition-transform duration-200"
        />
      </div>
    </div>
  );
}
