// src/store/modules/pricingPlanReducer.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PricingPlan } from "@/application/enums/shared/PricingPlan";

interface PricingPlanState {
  value: PricingPlan;
}

// Initialize state, perhaps from local storage or default to MONTHLY
let initialState: PricingPlanState = { value: PricingPlan.MONTHLY };
try {
  const local = localStorage.getItem("pricingPlan");
  if (local) {
    initialState = { value: local as PricingPlan };
  }
} catch {
  // Handle any errors
}

export const pricingPlanSlice = createSlice({
  name: "pricingPlan",
  initialState,
  reducers: {
    setPricingPlan: (state, action: PayloadAction<PricingPlan>) => {
      state.value = action.payload;
      localStorage.setItem("pricingPlan", action.payload);
    },
  },
});

export const { setPricingPlan } = pricingPlanSlice.actions;

export default pricingPlanSlice.reducer;
