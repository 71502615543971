import React, { useEffect, useState, useRef } from "react";
import DateFilterButton from "@/components/back/DateFilterButton";
import PageLoader from "@/components/back/Spinner";
import Spinner from "@/components/front/Loader/Spinner";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import OrderDetails from "./OrderDetails";
import RefundButton from "./RefundButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "@/assets/css/responsive.css";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface Order {
  id: number;
  order_name: string;
  order_number: string;
  order_type: string;
  payment_status: string;
  fulfillment_status: string;
  total_price: string;
  tip: string;
  created_at: string;
  order_items: any[];
  customer_name: string;
  customer_phone: string;
}

const OrderHistory: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [activeTab, setActiveTab] = useState("paid");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [axiosInstance, isFetching] = useAxiosInstance();
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const prevOrdersRef = useRef<Order[]>([]);
  const [fetchByInterval, setFetchByInterval] = useState<boolean>(false);
  const [swRegistration, setSwRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  useEffect(() => {
    const storedData = localStorage.getItem("user_data");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const { selectedBranch } = parsedData;
      setSelectedBranch(selectedBranch.id);
    }
  }, []);

  const fetchOrders = async (status: string, byInterval: boolean = false) => {
    setLoading(true);
    setError(null);

    try {
      const start = startDate
        ? new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            0,
            0,
            0,
            0
          ).toISOString()
        : "";
      const end = endDate
        ? new Date(endDate.setHours(23, 59, 59, 999)).toISOString()
        : "";

      let baseUrl = `/api/orders/?branch=${selectedBranch}&created_at_start=${start}&created_at_end=${end}`;

      let data: { orders: Order[]; total_orders: number } = {
        orders: [],
        total_orders: 0,
      };

      if (status === "paid" || status === "unpaid") {
        const url = `${baseUrl}&page=${currentPage}&page_size=${pageSize}&payment_status=${
          status === "paid" ? "Paid" : "Unpaid"
        }`;
        const response = await axiosInstance.get(url);

        if (!response.status.toString().startsWith("2")) {
          throw new Error("Failed to fetch orders");
        }

        data = response.data;
        setOrders(data.orders);
        setTotalOrders(data.total_orders);
        setTotalPages(Math.ceil(data.total_orders / pageSize));
      } else if (status === "refund") {
        const refundStatuses = [
          "Voided",
          "Refunded",
          "Partially Refunded",
          "Partially Paid",
        ];
        const refundPromises = refundStatuses.map((refundStatus) =>
          axiosInstance.get(
            `${baseUrl}&payment_status=${encodeURIComponent(refundStatus)}`
          )
        );
        const responses = await Promise.all(refundPromises);
        const allRefundOrders = responses.flatMap(
          (response) => response.data.orders
        );

        const sortedRefundOrders = allRefundOrders.sort((a, b) => {
          const aNum = parseInt(a.order_number.replace("#", ""));
          const bNum = parseInt(b.order_number.replace("#", ""));
          return bNum - aNum;
        });

        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        data.orders = sortedRefundOrders.slice(startIndex, endIndex);
        data.total_orders = sortedRefundOrders.length;
        setOrders(data.orders);
        setTotalOrders(data.total_orders);
        setTotalPages(Math.ceil(data.total_orders / pageSize));
      }

      if (byInterval) {
        checkForNewOrders(data.orders);
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const playBeepSound = () => {
    console.log("Playing beep sound");
    const beep = new Audio(
      "https://www.soundjay.com/buttons/sounds/beep-02.mp3"
    );
    beep.play().catch((error) => console.error("Audio play error:", error));
  };

  const showNotification = (title: string, body: string) => {
    if (swRegistration) {
      swRegistration.showNotification(title, {
        body,
        icon: "/logo192.png",
        badge: "/logo192.png",
      });
    }
  };

  const notifyUser = () => {
    playBeepSound();
    if ("vibrate" in navigator) {
      navigator.vibrate([200, 100, 200]);
    }
    showNotification("New Order", "You have a new order!");
  };

  const checkForNewOrders = (currentOrders: Order[]) => {
    if (currentOrders.length > 0 && prevOrdersRef.current.length > 0) {
      const newOrders = currentOrders.filter(
        (order) =>
          !prevOrdersRef.current.some((prevOrder) => prevOrder.id === order.id)
      );
      if (newOrders.length > 0) {
        notifyUser();
      }
    }
    prevOrdersRef.current = currentOrders;
  };

  useEffect(() => {
    if (selectedBranch) {
      fetchOrders(activeTab);
    } else {
      setLoading(false);
    }
  }, [selectedBranch, activeTab, currentPage, pageSize, startDate, endDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedBranch) {
        setFetchByInterval(true);
        fetchOrders(activeTab, true);
      }
    }, 180000);

    return () => clearInterval(interval);
  }, [selectedBranch, activeTab, currentPage, pageSize, startDate, endDate]);

  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "denied" &&
      Notification.permission !== "granted"
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          localStorage.setItem("notifications_permission", "granted");
        } else {
          localStorage.setItem("notifications_permission", "denied");
        }
      });
    }
  }, []);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        setSwRegistration(registration);
      });
    }
  }, []);

  const handleStatusChange = async (status: string) => {
    if (selectedOrder) {
      try {
        setSelectedOrder({ ...selectedOrder, fulfillment_status: status });
        const response = await axiosInstance.patch(
          `/api/v1/order/${selectedOrder.id}/`,
          { fulfillment_status: status }
        );

        if (!response.status.toString().startsWith("2")) {
          throw new Error("Failed to update order status");
        }
        const updatedOrderResponse = await axiosInstance.get(
          `/api/v1/order/${selectedOrder.id}/`
        );

        if (!updatedOrderResponse.status.toString().startsWith("2")) {
          throw new Error("Failed to fetch updated order details");
        }

        const updatedOrder = updatedOrderResponse.data;
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === updatedOrder.id ? updatedOrder : order
          )
        );

        setSelectedOrder(updatedOrder);
      } catch (error) {
        console.error("Error updating order status:", error);
        setSelectedOrder({
          ...selectedOrder,
          fulfillment_status: selectedOrder.fulfillment_status,
        });
      }
    }
  };

  const handleFilterStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleFilterEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const getOrderStatusClass = (paymentStatus: string) => {
    switch (paymentStatus) {
      case "Paid":
        return "border-l-4 border-green-500";
      case "Unpaid":
        return "border-l-4 border-red-500";
      default:
        return "border-l-4 border-gray-500";
    }
  };

  const formatToCST = (isoString: string) => {
    const date = new Date(isoString);
    const zonedDate = utcToZonedTime(date, "America/Chicago");
    return format(zonedDate, "yyyy-MM-dd HH:mm:ss");
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="min-h-screen p-4 bg-gray-100">
        <div className="mb-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
          <div className="flex space-x-4">
            <button
              onClick={() => {
                setActiveTab("paid");
                setCurrentPage(1);
              }}
              className={`py-2 px-4 rounded ${
                activeTab === "paid"
                  ? "bg-red-500 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              Paid Orders
            </button>
            <button
              onClick={() => {
                setActiveTab("unpaid");
                setCurrentPage(1);
              }}
              className={`py-2 px-4 rounded ${
                activeTab === "unpaid"
                  ? "bg-red-500 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              Unpaid Orders
            </button>
            <button
              onClick={() => {
                setActiveTab("refund");
                setCurrentPage(1);
              }}
              className={`py-2 px-4 rounded ${
                activeTab === "refund"
                  ? "bg-red-500 text-white"
                  : "bg-gray-300 text-gray-700"
              }`}
            >
              Refund Orders
            </button>
          </div>
        </div>

        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
          <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full mb-4">
            <DateFilterButton
              onStartDateChange={handleFilterStartDateChange}
              onEndDateChange={handleFilterEndDateChange}
              startDate={startDate}
              endDate={endDate}
              labelText="Filter by date"
            />
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : selectedBranch ? (
          <>
            {/* Desktop Table */}
            <div className="desktop-layout bg-white p-4 rounded-lg shadow">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Order ID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Order Number
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Order Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Total
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tip
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {orders.map((order) => (
                      <tr
                        key={order.id}
                        onClick={() => setSelectedOrder(order)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-500 cursor-pointer">
                          {order.order_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-blue-500 cursor-pointer">
                          {order.order_number}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              order.order_type.toLowerCase() === "pick up" ||
                              order.order_type.toLowerCase() === "pickup"
                                ? "bg-green-100 text-green-800"
                                : "bg-blue-100 text-black-800"
                            }`}
                          >
                            {order.order_type}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          <span
                            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                              order.fulfillment_status === "Completed"
                                ? "bg-green-100 text-green-800"
                                : order.fulfillment_status === "Cancelled"
                                ? "bg-red-100 text-red-800"
                                : "bg-yellow-100 text-yellow-800"
                            }`}
                          >
                            {order.fulfillment_status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          ${order.total_price}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          ${order.tip || "0.00"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {order.payment_status === "Paid" && (
                            <RefundButton
                              order={order}
                              onRefundComplete={() => {
                                fetchOrders(activeTab);
                                setSelectedOrder(null);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between mt-4">
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                >
                  Next
                </button>
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                    setCurrentPage(1);
                  }}
                  className="ml-4 border rounded p-2 w-16"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>

            {/* Mobile Layout */}
            <div className="mobile-layout">
              <div className="grid grid-cols-1 gap-4">
                {orders.map((order) => (
                  <div
                    key={order.id}
                    className={`p-4 bg-white rounded-lg shadow flex justify-between items-center relative ${getOrderStatusClass(
                      order.payment_status
                    )}`}
                  >
                    <div className="flex items-center">
                      <div>
                        <p className="font-bold text-gray-900">
                          {order.order_number}
                        </p>
                        <p className="text-gray-700">
                          {order.customer_name}{" "}
                          <span className="text-gray-500">
                            {order.customer_phone}
                          </span>
                        </p>
                        <p className="font-bold text-gray-900">
                          ${order.total_price}
                        </p>
                        <p className="text-gray-700">{order.order_type}</p>
                        <p className="text-gray-700">
                          {formatToCST(order.created_at)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <button
                        onClick={() => setSelectedOrder(order)}
                        className="mb-3 p-2 w-20 h-12 bg-gray-400 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                      {order.payment_status === "Paid" && (
                        <RefundButton
                          order={order}
                          onRefundComplete={() => {
                            fetchOrders(activeTab);
                            setSelectedOrder(null);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col items-center pt-4">
                <div className="mb-4">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">
                      {(currentPage - 1) * pageSize + 1}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium">
                      {Math.min(currentPage * pageSize, totalOrders)}
                    </span>{" "}
                    of <span className="font-medium">{totalOrders}</span>{" "}
                    results
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    disabled={currentPage === 1}
                    className="p-2 disabled:opacity-50"
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <div className="flex items-center space-x-2">
                    {getPageNumbers(currentPage, totalPages).map(
                      (page, index) => (
                        <button
                          key={index}
                          onClick={() =>
                            typeof page === "number" && setCurrentPage(page)
                          }
                          className={`px-2 py-1 rounded ${
                            currentPage === page
                              ? "bg-red-500 text-white"
                              : "bg-gray-200 text-gray-700"
                          }`}
                          disabled={typeof page !== "number"}
                        >
                          {page}
                        </button>
                      )
                    )}
                  </div>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    disabled={currentPage === totalPages}
                    className="p-2 disabled:opacity-50"
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>Please select a branch to view orders.</p>
        )}
        {selectedOrder && (
          <OrderDetails
            isOpen={true}
            onClose={() => setSelectedOrder(null)}
            order={selectedOrder}
            onStatusChange={handleStatusChange}
          />
        )}
      </div>
    </PageLoader>
  );
};

// Helper function to generate pagination numbers with ellipsis
const getPageNumbers = (currentPage: number, totalPages: number) => {
  const delta = 1;
  const range = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(totalPages - 1, currentPage + delta);
    i++
  ) {
    range.push(i);
  }
  if (currentPage - delta > 2) {
    range.unshift("...");
  }
  if (currentPage + delta < totalPages - 1) {
    range.push("...");
  }
  range.unshift(1);
  if (totalPages > 1) {
    range.push(totalPages);
  }
  return range;
};

export default OrderHistory;
