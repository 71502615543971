import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditOptionListProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  optionListId: number | null;
}

interface OptionList {
  id: number;
  name: string;
  description: string;
  options: number[];
  required: boolean;
  option_type: string;
  max_selections?: number | null;
}

interface Option {
  id: number;
  name: string;
}

const EditOptionList = ({
  onMenuItemClick,
  setSuccessMessage,
  optionListId,
}: EditOptionListProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [optionList, setOptionList] = useState<OptionList>({
    id: 0,
    name: "",
    description: "",
    options: [],
    required: false,
    option_type: "increment_decrement", // Default option type
  });
  const [options, setOptions] = useState<Option[]>([]);
  const [allOptions, setAllOptions] = useState<Option[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchOptionList = async () => {
      try {
        if (!loading && axiosInstance && optionListId) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${optionListId}/`);
          setOptionList({
            ...response.data,
            options: response.data.options.map((option: Option) => option.id),
            required: response.data.required,
            option_type: response.data.option_type,
          });
        }
      } catch (error) {
        console.error("Error fetching option list:", error);
      }
    };

    if (optionListId) {
      fetchOptionList();
    }
  }, [axiosInstance, loading, optionListId]);

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION ?? "";
          let allFetchedOptions: Option[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedOptions = [...allFetchedOptions, ...results];
            nextUrl = next;
          }

          setAllOptions(allFetchedOptions);
          setOptions(allFetchedOptions);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchAllOptions();
  }, [axiosInstance, loading, user?.selectedBranch]);

  useEffect(() => {
    if (searchQuery) {
      const filteredOptions = allOptions.filter((option) =>
        option.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(allOptions);
    }
  }, [searchQuery, allOptions]);

  const handleRemoveOption = (optionId: number) => {
    setOptionList((prevOptionList) => ({
      ...prevOptionList,
      options: prevOptionList.options.filter((id) => id !== optionId),
    }));
  };

  const handleUpdateOptionList = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
        const updatedOptionList = {
          name: optionList.name,
          description: optionList.description,
          branch: user.selectedBranch.id,
          options: optionList.options,
          required: optionList.required,
          option_type: optionList.option_type,
          max_selections: optionList.max_selections,
        };
        await axiosInstance.put(
          `${apiUrl}${optionListId}/`,
          updatedOptionList,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const successMessage = t(
          "back.management.menu.optionList.successupdate"
        );
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("optionList", successMessage);
      }
    } catch (error) {
      console.error("Error updating option list:", error);
      setIsSuccess(false);
      setBannerMessage(t("back.management.menu.optionList.failupdate"));
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleOptionChange = (selectedOptionId: number) => {
    setOptionList((prevOptionList) => {
      const options = prevOptionList.options.includes(selectedOptionId)
        ? prevOptionList.options.filter((id) => id !== selectedOptionId)
        : [...prevOptionList.options, selectedOptionId];

      return {
        ...prevOptionList,
        options,
      };
    });
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        {optionListId ? (
          <>
            {/* General Information Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.optionList.generalinfo")}
              </h2>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-full px-2 mb-4 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="optionListName"
                  >
                    {t("back.management.menu.optionList.optionListName")}
                  </label>
                  <input
                    type="text"
                    id="itemName"
                    placeholder={t(
                      "back.management.menu.optionList.enterOptionListName"
                    )}
                    value={optionList.name}
                    onChange={(e) =>
                      setOptionList({ ...optionList, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  {t("back.management.menu.optionList.description")}
                </label>
                <textarea
                  id="description"
                  placeholder={t(
                    "back.management.menu.optionList.enterDescription"
                  )}
                  value={optionList.description}
                  onChange={(e) =>
                    setOptionList({
                      ...optionList,
                      description: e.target.value,
                    })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
                ></textarea>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="option_type"
                >
                  {t("back.management.menu.optionList.option_type")}
                </label>
                <select
                  id="option_type"
                  value={optionList.option_type}
                  onChange={(e) =>
                    setOptionList({
                      ...optionList,
                      option_type: e.target.value,
                      max_selections: null, // Reset maxSelections when changing option_type
                    })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                >
                  <option value="multiple_choice">
                    {t("back.management.menu.option.multipleChoice")}
                  </option>
                  <option value="checkbox">
                    {t("back.management.menu.option.checkbox")}
                  </option>
                  <option value="increment_decrement">
                    {t("back.management.menu.option.increment_decrement")}
                  </option>
                </select>
              </div>
              {optionList.option_type === "checkbox" ||
              optionList.option_type === "increment_decrement" ? (
                <div className="mt-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="maxSelections"
                  >
                    {t("back.management.menu.optionList.maxSelections")}
                  </label>
                  <input
                    type="number"
                    id="maxSelections"
                    placeholder={t(
                      "back.management.menu.optionList.enterMaxSelections"
                    )}
                    value={optionList.max_selections ?? ""}
                    onChange={(e) =>
                      setOptionList({
                        ...optionList,
                        max_selections: parseInt(e.target.value),
                      })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
              ) : null}
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="required"
                >
                  {t("back.management.menu.optionList.isRequired")}
                </label>
                <input
                  type="checkbox"
                  id="required"
                  checked={optionList.required}
                  onChange={(e) =>
                    setOptionList({
                      ...optionList,
                      required: e.target.checked,
                    })
                  }
                  className="mt-1"
                />
              </div>
            </div>

            {/* Add Options Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.optionList.addOptions")}
              </h2>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="searchOptions"
                >
                  {t("back.management.menu.optionList.searchOptions")}
                </label>
                <input
                  type="text"
                  id="searchOptions"
                  placeholder={t(
                    "back.management.menu.optionList.enterSearchQuery"
                  )}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                />
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="options"
                >
                  {t("back.management.menu.optionList.selectOptions")}
                </label>
                <select
                  id="options"
                  multiple
                  value={optionList.options.map(String)}
                  onChange={(e) =>
                    handleOptionChange(parseInt(e.target.value, 10))
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                >
                  {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-4">
                <p className="text-gray-700 text-sm font-bold mb-2">
                  {t("back.management.menu.optionList.selectedOptions")}
                </p>
                <ul>
                  {allOptions
                    .filter((option) => optionList.options.includes(option.id))
                    .map((option) => (
                      <li key={option.id} className="flex items-center mb-2">
                        <span className="text-gray-700 mr-2">
                          {option.name}
                        </span>
                        <button
                          onClick={() => handleRemoveOption(option.id)}
                          className="text-red-500 hover:text-red-700 focus:outline-none"
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("optionList")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.optionList.cancelbutton")}
              </button>
              <button
                onClick={handleUpdateOptionList}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                {t("back.management.menu.optionList.updatebutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.menu.optionList.noselected")}</p>
        )}
      </div>
    </PageLoader>
  );
};

export default EditOptionList;
