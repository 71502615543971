import React, { useState, useEffect, CSSProperties } from "react";
import wrHeroImage from "@/assets/img/wrHero.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useResponsiveStyles = (): Record<string, CSSProperties> => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    container: {
      maxWidth: "1400px",
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      paddingTop: isMobile ? "150px" : "200px",
      flexDirection: isMobile ? "column" : "row",
    },
    content: {
      width: isMobile ? "100%" : "50%",
      textAlign: isMobile ? "center" : "left",
      display: "flex",
      flexDirection: "column",
      justifyContent: isMobile ? "flex-start" : "center",
      paddingLeft: "40px",
    },
    h1: {
      fontSize: isMobile ? "2em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
      maxWidth: "90%",
    },
    highlight: {
      color: "#e60000",
    },
    p: {
      fontSize: isMobile ? "1em" : "1.2em",
      color: "#555",
      lineHeight: "1.6",
      marginBottom: "30px",
      maxWidth: "80%",
    },
    button: {
      backgroundColor: "transparent",
      color: "#e60000",
      border: "none",
      padding: "0",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "1.5em",
      fontWeight: "bold",
    },
    imageContainer: {
      width: isMobile ? "100%" : "50%",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      marginRight: "0.5em",
    },
    image: {
      maxWidth: isMobile ? "60%" : "100%",
      height: "auto",
    },
  };

  return styles;
};

const WRHero = () => {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.h1}>
          <span style={styles.highlight}>
            {t("front.features.wr.spantitle")}
          </span>
          {t("front.features.wr.title1")}
        </h1>
        <p style={styles.p}>{t("front.features.wr.description")}</p>
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <button style={styles.button}>{t("front.features.wr.button")}</button>
        </Link>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={wrHeroImage}
          alt={t("front.features.wr.alt")}
          style={styles.image}
        />
      </div>
    </div>
  );
};

export default WRHero;
