import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import QSHero from "@/components/front/BusinessQS/QSHero";
import QSFeature from "@/components/front/BusinessQS/QSFeature";
import QSFeature2 from "@/components/front/BusinessQS/QSFeature2";
import FeaturesHero2 from "@/components/front/FeaturesHero";
import Contact from "@/views/front/Contact";

import { useTranslation } from "react-i18next";

const StyledLine = () => {
  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: "#aaa",
    width: "80%",
    margin: "20px auto",
  };

  return <div style={lineStyle}></div>;
};

const BusinessQS = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{t("front.business.qs.helmet")} | Redbird Corporation</title>
        </Helmet>
      </HelmetProvider>
      <div>
        <Header />
        <QSHero />
        <QSFeature />
        <QSFeature2 />
        <StyledLine />
        <FeaturesHero2 />
        <Contact />
      </div>
    </div>
  );
};

export default BusinessQS;
