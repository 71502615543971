import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { RootState } from "./store";

import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "../src/store";
import { Theme } from "./application/enums/shared/Theme";
import { AddressProvider } from "./contexts/AddressContext";
import { CustomerProvider } from "./contexts/CustomerContext";
import { UserProvider } from "./contexts/UserContext";
import "./locale/i18n";
import AppRoutes from "./router/router";
import ScrollToTop from "./router/ScrollToTop";

export default function App() {
  const theme = useSelector<RootState>((state) => state.theme.value);

  useEffect(() => {
    const htmlClasses = document.querySelector("html")?.classList;
    if (theme === Theme.LIGHT) {
      htmlClasses?.remove("dark");
    } else {
      htmlClasses?.add("dark");
    }
  }, [theme]);

  return (
    <Provider store={store}>
      <Router>
        <UserProvider>
          <CustomerProvider>
            <AddressProvider
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
            >
              <Toaster position="top-right" />
              <ScrollToTop />
              <HelmetProvider>
                <Helmet>
                  <title>RedBird Corporation</title>
                </Helmet>
                <AppRoutes />
              </HelmetProvider>
            </AddressProvider>
          </CustomerProvider>
        </UserProvider>
      </Router>
    </Provider>
  );
}
