import { useCustomer } from "@/contexts/CustomerContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { E164Number } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const ArrowRightIcon = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="m12 5 7 7-7 7" />
    </svg>
  );
};

const CustomerLogin = () => {
  const { login, customer } = useCustomer();
  const [value, setValue] = useState<E164Number>();
  const [code, setCode] = useState("");
  const [verificationScreen, setVerificationScreen] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [axiosInstance] = useAxiosInstance();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (customer) {
      navigate("/store");
    }
  }, [customer, navigate]);

  useEffect(() => {
    const phoneFromParams = searchParams.get("phone");
    if (phoneFromParams) {
      setValue(("+1" + phoneFromParams) as E164Number);
    }
  }, [searchParams]);

  useEffect(() => {
    if (verificationScreen) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            setIsButtonEnabled(true);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [verificationScreen, isButtonEnabled]);

  const handleResendOTP = async () => {
    try {
      const res = await axiosInstance.post("/api/generate-otp", {
        phone: value,
      });
      if (res.status === 200) {
        toast.success("OTP sent again successfully");
        setCountdown(30);
        setIsButtonEnabled(false);
      }
    } catch (error) {
      toast.error("Failed to resend OTP");
      console.error(error);
    }
  };

  const handleOTPGenerate = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/api/generate-otp", {
        phone: value,
      });
      if (res.status === 200) {
        toast.success("OTP sent successfully");
        setVerificationScreen(true);
      }
    } catch (error) {
      toast.error("Failed to send OTP");
      console.error(error);
    }
  };

  const handleOTPVerify = async (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const res = await axiosInstance.post("/api/verify-otp", {
        phone: value,
        otp: code,
      });
      if (res.status === 202) {
        toast.success("OTP verified successfully");
        navigate(`/customer-register?phone=${value?.toString().substring(1)}`);
      } else if (res.status === 200) {
        if (res.data.user) {
          const user = {
            name: res.data.user.name,
            phone: res.data.user.phone,
            email: res.data.user.email,
            avatar: res.data.user.avatar,
            street: res.data.user.street,
            city: res.data.user.city,
            state: res.data.user.state,
            pincode: res.data.user.pincode,
            country: res.data.user.country,
          };
          login(user);
          toast.success("Logged in successfully");
        }
        navigate("/cart");
      }
    } catch (error) {
      toast.error("Invalid OTP");
      console.error(error);
    }
  };

  return (
    <div className="p-4">
      <Link to="/store" className="text-blue-500 hover:underline">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </Link>
      {verificationScreen ? (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <span className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter the 4 digit code sent to you at {value}
            </span>
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleOTPVerify} className="space-y-6">
              <div className="flex space-x-2 border-b-2">
                <input
                  type="tel"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="focus:outline-none focus:ring-0 outline-none text-lg text-center tracking-[3rem] w-full p-4 ml-2 rounded-md border-none border-gray-300"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  maxLength={4}
                  required
                />
              </div>
              <button
                type="button"
                className={`mt-4 p-4 w-full font-bold rounded-md ${
                  isButtonEnabled
                    ? "bg-gray-300 text-gray-700 hover:bg-gray-400"
                    : "bg-gray-200 text-gray-500 cursor-not-allowed"
                }`}
                disabled={!isButtonEnabled}
                onClick={handleResendOTP}
              >
                I haven't received a code{" "}
                {countdown > 0 ? `(${countdown.toFixed(0)})` : ""}
              </button>
              <div>
                <button
                  type="submit"
                  className="grid grid-cols-8 w-full justify-center rounded-md bg-red-600 text-lg p-4 font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  <span className="col-span-7 inline-flex justify-center">
                    Next
                  </span>
                  <div className="inline-flex justify-end">
                    <ArrowRightIcon className="mr-2" />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="flex min-h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <span className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Enter your mobile number
            </span>
          </div>

          <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={handleOTPGenerate} className="space-y-6">
              <PhoneInput
                placeholder="Enter phone number"
                value={value}
                className="flex-1 w-full rounded-lg"
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                onChange={(value) => setValue(value)}
              />
              <div>
                <button
                  type="submit"
                  className="grid grid-cols-8 w-full justify-center rounded-md bg-red-600 text-lg p-4 font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  <span className="col-span-7 inline-flex justify-center">
                    Next
                  </span>
                  <div className="inline-flex justify-end">
                    <ArrowRightIcon className="mr-2" />
                  </div>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerLogin;
