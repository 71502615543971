import HeaderOnlineOrder from "@/components/front/HeaderOnlineOrder";
import Spinner from "@/components/front/Loader/Spinner";
import GrayOutScreen from "@/components/ui/dropdowns/GrayOutScreen";
import { setBranchId, setServiceFee, setTaxRate } from "@/features/cart/cart";
import { RootState } from "@/store";
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const ProductList = lazy(() => import("@/components/front/ProductList"));
const CategoryNavigation = lazy(
  () => import("@/components/front/CategoryNavigation")
);

const API_URL = process.env.REACT_APP_API_BASE_URL;

interface Branch {
  id: number;
  name: string;
  operating_hours: OperatingHour[];
  service_fee: string;
  tax_rate: string;
}

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

interface Category {
  id: number;
  name: string;
  category_hours: OperatingHour[];
  hasSpecialHours: boolean;
}

interface OnlineOrderProps {
  subdomain: string;
  hostname: string;
  branches: {
    count: number;
    results: Branch[];
  };
  isInitialLoad: boolean;
}

const MemoizedCategoryNavigation = React.memo(CategoryNavigation);

const OnlineOrder: React.FC<OnlineOrderProps> = ({
  branches,
  isInitialLoad,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedBranch = useSelector(
    (state: RootState) => state.foodCart.branchId
  );
  const [loading, setLoading] = useState<boolean>(isInitialLoad);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isSelectedBranchOpen, setIsSelectedBranchOpen] =
    useState<boolean>(true);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const fetchedBranchesRef = useRef<Set<string>>(new Set());
  const isFetchingRef = useRef(false);

  const checkIfBranchIsOpen = (operatingHours: OperatingHour[]): boolean => {
    const now = new Date();
    const dayOfWeek = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = now.toTimeString().split(" ")[0];

    const [currentHour, currentMinute, currentSecond] = currentTime
      .split(":")
      .map(Number);
    const currentTimeInSeconds =
      currentHour * 3600 + currentMinute * 60 + currentSecond;

    return operatingHours.some((hour) => {
      if (hour.day !== dayOfWeek) return false;

      const [openHour, openMinute] = hour.open_time.split(":").map(Number);
      const [closeHour, closeMinute] = hour.close_time.split(":").map(Number);

      const openTimeInSeconds = openHour * 3600 + openMinute * 60;
      const closeTimeInSeconds = closeHour * 3600 + closeMinute * 60;

      return (
        currentTimeInSeconds >= openTimeInSeconds &&
        currentTimeInSeconds <= closeTimeInSeconds
      );
    });
  };

  const checkIfCategoryIsOpen = (categoryHours: OperatingHour[]): boolean => {
    const now = new Date();
    const dayOfWeek = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = now.toTimeString().split(" ")[0];

    const [currentHour, currentMinute, currentSecond] = currentTime
      .split(":")
      .map(Number);
    const currentTimeInSeconds =
      currentHour * 3600 + currentMinute * 60 + currentSecond;

    return categoryHours.some((hour) => {
      if (hour.day !== dayOfWeek) return false;

      const [openHour, openMinute] = hour.open_time.split(":").map(Number);
      const [closeHour, closeMinute] = hour.close_time.split(":").map(Number);

      const openTimeInSeconds = openHour * 3600 + openMinute * 60;
      const closeTimeInSeconds = closeHour * 3600 + closeMinute * 60;

      return (
        currentTimeInSeconds >= openTimeInSeconds &&
        currentTimeInSeconds <= closeTimeInSeconds
      );
    });
  };

  const filterCategoriesByTime = useCallback(
    (categories: Category[]): Category[] => {
      return categories.filter(
        (category) =>
          !category.hasSpecialHours ||
          checkIfCategoryIsOpen(category.category_hours)
      );
    },
    []
  );

  const fetchCategories = useCallback(
    async (branchId: string) => {
      if (fetchedBranchesRef.current.has(branchId) || isFetchingRef.current) {
        return;
      }

      isFetchingRef.current = true;
      setLoading(true);
      try {
        console.log(`Fetching categories for branch: ${branchId}`);
        const response = await fetch(
          `${API_URL}api/v1/category-front/?branch=${branchId}&status=Active`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        const updatedCategories = result.map((category: Category) => ({
          ...category,
          hasSpecialHours: category.name
            .toLowerCase()
            .includes("lunch special"),
        }));

        setAllCategories(updatedCategories);

        const filteredCategories = filterCategoriesByTime(updatedCategories);

        const sortedCategories = filteredCategories.sort(
          (a: Category, b: Category) => a.name.localeCompare(b.name)
        );

        setCategories(sortedCategories);
        if (sortedCategories.length > 0 && !selectedCategory) {
          setSelectedCategory(sortedCategories[0].name);
        }

        setInitialLoadComplete(true);
        fetchedBranchesRef.current.add(branchId);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
        isFetchingRef.current = false;
      }
    },
    [filterCategoriesByTime, selectedCategory]
  );

  useEffect(() => {
    const setInitialBranch = async () => {
      if (branches.count === 1) {
        const singleBranch = branches.results[0];
        dispatch(setBranchId(singleBranch.id.toString()));
        setOperatingHours(singleBranch.operating_hours);
        setIsSelectedBranchOpen(
          checkIfBranchIsOpen(singleBranch.operating_hours)
        );
        dispatch(setServiceFee(parseFloat(singleBranch.service_fee)));
        dispatch(setTaxRate(parseFloat(singleBranch.tax_rate)));

        await fetchCategories(singleBranch.id.toString());
      } else if (selectedBranch) {
        const branch = branches.results.find(
          (b) => b.id.toString() === selectedBranch
        );
        if (branch) {
          setOperatingHours(branch.operating_hours);
          setIsSelectedBranchOpen(checkIfBranchIsOpen(branch.operating_hours));
          if (!fetchedBranchesRef.current.has(selectedBranch)) {
            await fetchCategories(selectedBranch);
          }
        }
      }
      setLoading(false);
    };

    setInitialBranch();
  }, [branches, selectedBranch, dispatch, fetchCategories]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedBranch) {
        const branch = branches.results.find(
          (b) => b.id.toString() === selectedBranch
        );
        if (branch) {
          setIsSelectedBranchOpen(checkIfBranchIsOpen(branch.operating_hours));
        }
      }
      setCategories(filterCategoriesByTime(allCategories));
    }, 60000);

    return () => clearInterval(intervalId);
  }, [branches, selectedBranch, allCategories, filterCategoriesByTime]);

  useEffect(() => {
    if (
      !isInitialLoad &&
      selectedBranch &&
      isSelectedBranchOpen &&
      !initialLoadComplete
    ) {
      fetchCategories(selectedBranch);
    }
  }, [
    selectedBranch,
    isSelectedBranchOpen,
    isInitialLoad,
    initialLoadComplete,
    fetchCategories,
  ]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBranchId = event.target.value;
    dispatch(setBranchId(selectedBranchId));
    setSelectedCategory(null);
    setCategories([]);
    const selectedBranch = branches.results.find(
      (branch) => branch.id === parseInt(selectedBranchId)
    );
    if (selectedBranch) {
      setOperatingHours(selectedBranch.operating_hours);
      setIsSelectedBranchOpen(
        checkIfBranchIsOpen(selectedBranch.operating_hours)
      );
      dispatch(setServiceFee(parseFloat(selectedBranch.service_fee)));
      dispatch(setTaxRate(parseFloat(selectedBranch.tax_rate)));
    }
    setInitialLoadComplete(false);
    fetchCategories(selectedBranchId);
  };

  const handleCategorySelect = useCallback((category: string) => {
    setSelectedCategory(category);
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative pt-20">
      <HeaderOnlineOrder isSelectedBranchOpen={isSelectedBranchOpen}/>
      {branches.count > 1 && (
        <div className="flex justify-center pt-10">
          <select
            value={selectedBranch || ""}
            onChange={handleSelectChange}
            className="py-2 pr-8 border rounded"
          >
            <option value="" disabled>
              Select a Branch
            </option>
            {branches.results.map((branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {!loading && selectedBranch && (
        <div>
          {!isSelectedBranchOpen && (
            <GrayOutScreen message="Thank you for choosing us! Unfortunately, we are currently closed. Please come back during our opening hours." />
          )}
          {selectedBranch === "10" && (
            <div className="text-center p-4 m-4 border-2 border-green-500 rounded-lg bg-green-100 text-green-700 text-lg">
              Enjoy a special treat! Spend $30 or more and get your first order
              of our delicious Fried Dumplings for FREE! Each entrée comes with
              one complimentary white or fried rice. Additional rice orders are
              just $1.50 each (please add from Extra Rice category).
            </div>
          )}
          {selectedBranch === "12" && (
            <div className="text-center p-4 m-4 border-2 border-blue-500 rounded-lg bg-blue-100 text-blue-700 text-lg">
              Limited Time Offer: 50% off on all drinks (excludes fizzy drinks)
              with Redbird Online orders! No entree required. Ends June 29th.
              Don't miss out!
            </div>
          )}
          {categories.length > 0 && (
            <>
              <div className="flex flex-col items-center justify-center my-4 px-4 space-y-4">
                <h1 className="text-2xl font-bold text-center">
                  Welcome to{" "}
                  {
                    branches.results.find(
                      (branch) => branch.id === parseInt(selectedBranch)
                    )?.name
                  }
                </h1>
                <div className="relative flex items-center w-full max-w-2xl px-4">
                  <input
                    type="text"
                    placeholder="Search from menu..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="p-2 border rounded-full w-full"
                  />
                </div>
              </div>
              <Suspense fallback={<Spinner />}>
                <MemoizedCategoryNavigation
                  categories={categories.map((category) => category.name)}
                  selectedCategory={selectedCategory}
                  onCategorySelect={handleCategorySelect}
                />
              </Suspense>
              <Suspense fallback={<Spinner />}>
                <ProductList
                  branchId={selectedBranch}
                  category={selectedCategory || categories[0].name}
                  search={searchTerm}
                />
              </Suspense>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OnlineOrder;
