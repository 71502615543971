import CareersContact from "@/assets/img/careersContact.png";
import Footer from "@/components/front/Footer";
import Header from "@/components/front/Header";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  message: string;
  phone: string;
}

interface ContactProps {
  stickyFooter?: boolean;
}

export default function Contact({ stickyFooter = false }: ContactProps) {
  const { t } = useTranslation();

  const containerClass = stickyFooter ? "flex flex-col min-h-screen" : "";
  const contentClass = stickyFooter ? "flex-grow" : "";

  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    message: "",
    phone: "",
  });
  const [actionURL, setActionURL] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | number;
    if (submitSuccess) {
      setShowBanner(true);
      timer = setTimeout(() => {
        setShowBanner(false);
        setSubmitSuccess(false);
      }, 3000);
    }
    return () => clearTimeout(timer as unknown as number);
  }, [submitSuccess]);

  useEffect(() => {
    setActionURL(process.env.REACT_APP_INTEGRATIONS_CONTACT_FORMSPREE ?? "");
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(actionURL, formData);
      if (response.status === 200) {
        setSuccessMessage("Email sent out successfully!");
        setSubmitSuccess(true);
        toast.success("Thank you for your message!");
      } else {
        toast.error("There was an error sending your message.");
      }
    } catch (error) {
      console.error("There was an error sending the message", error);
      toast.error("There was an error sending your message.");
    }
  };

  return (
    <div className={containerClass}>
      <Header />
      <div className={contentClass}>
        <div className="bg-white dark:bg-gray-900 pt-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="md:flex md:items-start md:justify-between">
              <div className="md:w-1/2 flex flex-col relative py-16">
                <div>
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-800 dark:text-slate-200 sm:text-4xl">
                    {t("front.company.careers.contacttitle")}
                  </h2>
                  <p className="mt-4 text-lg leading-6 max-w-md">
                    {t("front.company.careers.contactdescription")}
                  </p>
                </div>
                <div className="mt-6 hidden lg:block">
                  <img
                    src={CareersContact}
                    alt="Contact us"
                    className="w-128 h-128 object-cover mx-auto"
                  />
                </div>
              </div>

              <div className="relative md:w-1/2 py-12 sm:py-6 w-full">
                {showBanner && (
                  <WarningBanner
                    title="Success"
                    text={successMessage}
                    isSuccess={true}
                  />
                )}
                <div className="mt-12">
                  {!actionURL && (
                    <WarningBanner
                      title={t("shared.notSet")}
                      text={t("front.contact.setup")}
                    />
                  )}
                  <form
                    onSubmit={handleSubmit}
                    action={actionURL}
                    method="POST"
                    className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                  >
                    <div>
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                      >
                        {t("front.contact.firstName")}
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          value={formData.firstName}
                          onChange={handleInputChange}
                          type="text"
                          name="firstName"
                          id="first_name"
                          autoComplete="given-name"
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                      >
                        {t("front.contact.lastName")}
                      </label>
                      <div className="mt-1">
                        <input
                          value={formData.lastName}
                          onChange={handleInputChange}
                          type="text"
                          name="lastName"
                          id="last_name"
                          autoComplete="family-name"
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                      >
                        {t("front.contact.email")}
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          value={formData.email}
                          onChange={handleInputChange}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                      >
                        {t("front.contact.phone")}
                      </label>
                      <div className="mt-1">
                        <input
                          required
                          value={formData.phone}
                          onChange={handleInputChange}
                          type="tel"
                          name="phone"
                          id="phone"
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="company"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.company.careers.job")}
                        </label>
                        <span
                          id="company_description"
                          className="text-sm text-gray-500"
                        >
                          {t("shared.optional")}
                        </span>
                      </div>
                      <div className="mt-1">
                        <input
                          value={formData.organization}
                          onChange={handleInputChange}
                          type="text"
                          name="organization"
                          id="company"
                          autoComplete="organization"
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="comments"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.contact.comments")}
                        </label>
                      </div>
                      <div className="mt-1">
                        <textarea
                          required
                          value={formData.message}
                          onChange={handleInputChange}
                          id="comments"
                          name="message"
                          aria-describedby="comments_description"
                          rows={4}
                          className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                        ></textarea>
                      </div>
                    </div>

                    <div className="text-right sm:col-span-2">
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 w-full"
                      >
                        {t("front.company.careers.button")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
