// featurepos.tsx

import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import KiosksHero from "@/components/front/FeatureKiosks/KiosksHero";
import KiosksFeature from "@/components/front/FeatureKiosks/KiosksFeature";
import KiosksFeature2 from "@/components/front/FeatureKiosks/KiosksFeature2";
import FeaturesHero2 from "@/components/front/FeaturesHero";
import Contact from "@/views/front/Contact";

import { useTranslation } from "react-i18next";

const StyledLine = () => {
  const lineStyle: React.CSSProperties = {
    height: 1,
    backgroundColor: "#aaa",
    width: "80%",
    margin: "20px auto",
  };

  return <div style={lineStyle}></div>;
};

const FeatureKiosks = () => {
  const { t } = useTranslation();

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            {t("front.features.kiosks.helmet")} | Redbird Corporation
          </title>
        </Helmet>
      </HelmetProvider>
      <div>
        <Header />
        <KiosksHero />
        <KiosksFeature />
        <KiosksFeature2 />
        <StyledLine />
        <FeaturesHero2 />
        <Contact />
      </div>
    </div>
  );
};

export default FeatureKiosks;
