import DateFilterButton from "@/components/back/DateFilterButton";
import FilterOptionsButton from "@/components/back/FilterOptionsButton";
import SearchInput from "@/components/back/SearchInput";
import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faChevronLeft,
  faChevronRight,
  faEdit,
  faFileExport,
  faPlus,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PromotionItem {
  id: number;
  name: string;
  promotion_type: string;
  promotion_area_type: string;
  status: string;
  photo: string;
  branch_id: number;
  created_at: string;
}

interface SortState {
  field: keyof PromotionItem;
  direction: "asc" | "desc";
}

interface FilterState {
  status: string;
}

interface PromotionProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function Prpmotion({
  onMenuItemClick,
  successMessage,
}: PromotionProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const { user } = useUser();
  const [promotionItems, setPromotionItems] = useState<PromotionItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "promotion_type",
    direction: "asc",
  });
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterState, setFilterState] = useState<FilterState>({
    status: "",
  });
  const [axiosInstance, loading, isFetching] = useAxiosInstance();

  const fetchPromotionItems = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
        let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;
        if (searchQuery) {
          url += `&name=${encodeURIComponent(searchQuery)}`;
        }
        if (filterStartDate) {
          const formattedStartDate = filterStartDate.toISOString();
          url += `&created_at__gt=${formattedStartDate}`;
        }
        if (filterEndDate) {
          const formattedEndDate = filterEndDate.toISOString();
          url += `&created_at__lt=${formattedEndDate}`;
        }
        if (filterState.status) {
          url += `&status=${filterState.status}`;
        }
        const response = await axiosInstance.get(url);
        let fetchedItems = response.data.results.map((item: PromotionItem) => {
          const zonedDate = utcToZonedTime(item.created_at, "America/Chicago");
          const formattedDate = format(zonedDate, "MM/dd/yyyy, HH:mm");
          return { ...item, created_at: formattedDate };
        });
        //sorting logic
        fetchedItems.sort((a: PromotionItem, b: PromotionItem) => {
          if (sortState.field === "created_at") {
            // Convert back to dates for comparison
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return sortState.direction === "asc"
              ? dateA.getTime() - dateB.getTime()
              : dateB.getTime() - dateA.getTime();
          } else {
            const aValue = a[sortState.field];
            const bValue = b[sortState.field];
            if (typeof aValue === "number" && typeof bValue === "number") {
              return sortState.direction === "asc"
                ? aValue - bValue
                : bValue - aValue;
            } else if (
              typeof aValue === "string" &&
              typeof bValue === "string"
            ) {
              return sortState.direction === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }
          }
          return 0;
        });
        setPromotionItems(fetchedItems);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching promotion items:", error);
    }
  };

  const handleFilterStartDateChange = (date: Date | null) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDateChange = (date: Date | null) => {
    setFilterEndDate(date);
  };

  const handleFilterChange = (field: keyof FilterState, value: string) => {
    setFilterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRemoveFilters = () => {
    setFilterState({
      status: "",
    });
    setCurrentPage(1);
    fetchPromotionItems();
  };

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
      console.log("Banner message set:", successMessage);
      console.log("Show banner:", true);
    }
  }, [successMessage]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchPromotionItems();
    }, 500);

    return () => clearTimeout(timerId);
  }, [
    currentPage,
    searchQuery,
    sortState,
    axiosInstance,
    loading,
    user,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage(1);
      await fetchPromotionItems();
    };

    fetchData();
  }, [filterState]);

  const editItem = (id: number) => {
    onMenuItemClick("editPromotion", "", id);
  };

  const openDeleteConfirmation = (id: number) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      await deleteItem(itemToDelete);
      closeDeleteConfirmation();
    }
  };

  const deleteItem = async (id: number) => {
    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
        await axiosInstance.delete(`${apiUrl}${id}`);
        fetchPromotionItems();
        setBannerMessage("Promotion deleted successfully.");
        setIsSuccess(true);
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error deleting promotion item:", error);
      setBannerMessage("Failed to delete promotion.");
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  const handleSortChange = (field: keyof PromotionItem) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],

      ...promotionItems.map((item) => [
        item.id,
        item.promotion_type,
        item.promotion_area_type,
        item.status,
        item.created_at,
        item.name,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "promotion.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}
          {/* Title and Action Buttons */}
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold">
              {t("back.management.menu.promotion.title")}
            </h1>
            <div>
              <button
                onClick={downloadCSV}
                className="mr-2 px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                {t("back.management.menu.promotion.export")}
              </button>
              <button
                onClick={() => onMenuItemClick("addPromotion")}
                className="px-4 py-2 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t("back.management.menu.promotion.addpromotion")}
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="relative flex-1 max-w-md mr-4">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
              <SearchInput
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={t(
                  "back.management.menu.promotion.searchPromotion"
                )}
              />
            </div>
            <div className="relative">
              <DateFilterButton
                onStartDateChange={handleFilterStartDateChange}
                onEndDateChange={handleFilterEndDateChange}
                startDate={filterStartDate}
                endDate={filterEndDate}
                labelText={t("back.management.menu.promotion.selectdate")}
              />

              <FilterOptionsButton
                filterOptions={[
                  {
                    label: t("back.management.menu.promotion.status"),
                    value: filterState.status,
                    onChange: (value) => handleFilterChange("status", value),
                    placeholder: t(
                      "back.management.menu.promotion.selectStatus"
                    ),
                    type: "select",
                    options: [
                      {
                        value: "Active",
                        label: t("back.management.menu.promotion.active"),
                      },
                      {
                        value: "Inactive",
                        label: t("back.management.menu.promotion.inactive"),
                      },
                      {
                        value: "Draft",
                        label: t("back.management.menu.promotion.draft"),
                      },
                    ],
                  },
                ]}
                onRemoveFilters={handleRemoveFilters}
              />
            </div>
          </div>

          <Transition appear show={deleteConfirmationOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={closeDeleteConfirmation}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                </Transition.Child>

                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>

                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {t(
                        "back.management.menu.promotion.deleteConfirmation.title"
                      )}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t(
                          "back.management.menu.promotion.deleteConfirmation.message"
                        )}
                      </p>
                    </div>

                    <div className="mt-4 flex justify-center">
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="h-12 w-12 text-red-600"
                      />
                    </div>

                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                        onClick={closeDeleteConfirmation}
                      >
                        {t(
                          "back.management.menu.promotion.deleteConfirmation.cancel"
                        )}
                      </button>
                      <button
                        type="button"
                        className="ml-2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                        onClick={confirmDelete}
                      >
                        {t(
                          "back.management.menu.promotion.deleteConfirmation.confirm"
                        )}
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm divide-y divide-gray-500">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("name")}
                  >
                    <span className="flex items-center">
                      {t("back.management.menu.promotion.table.promotionName")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "name"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                        className="ml-1"
                        style={{ marginLeft: "4px" }}
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("promotion_type")}
                  >
                    <span className="flex items-center">
                      {t("back.management.menu.promotion.table.promotionType")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "promotion_type"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                        className="ml-1"
                        style={{ marginLeft: "4px" }}
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("promotion_area_type")}
                  >
                    <span className="flex items-center">
                      {t(
                        "back.management.menu.promotion.table.promotionAreaType"
                      )}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "promotion_area_type"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                        className="ml-1"
                        style={{ marginLeft: "4px" }}
                      />
                    </span>
                  </th>
                  <th className="px-6 py-3 font-bold text-left">
                    {t("back.management.menu.promotion.table.status")}
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("created_at")}
                  >
                    <span className="flex items-center">
                      {t("back.management.menu.promotion.table.date")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "created_at"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                        className="ml-1"
                        style={{ marginLeft: "4px" }}
                      />
                    </span>
                  </th>
                  <th className="px-6 py-3 font-bold text-left">
                    {t("back.management.menu.promotion.table.action")}
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-100">
                {promotionItems.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.name}
                    </td>
                    <td className="px-6 py-4 text-gray-900 flex items-center font-bold">
                      {item.promotion_type} {/* Changed from item.name */}
                    </td>
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.promotion_area_type}
                    </td>
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.status}
                    </td>
                    <td className="px-6 py-4 text-gray-900">
                      {item.created_at}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => editItem(item.id)}
                        className="mr-2"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="text-yellow-600 hover:text-yellow-800"
                        />
                      </button>
                      <button onClick={() => openDeleteConfirmation(item.id)}>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="text-red-600 hover:text-red-800"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination and results info */}
          <div className="flex justify-between items-center pt-4">
            {/* Results info */}
            <div>
              <p className="text-sm text-gray-700">
                {t("back.management.table.showing")}{" "}
                <span className="font-medium">
                  {(currentPage - 1) * itemsPerPage + 1}
                </span>{" "}
                {t("back.management.table.to")}{" "}
                <span className="font-medium">
                  {Math.min(currentPage * itemsPerPage, totalItems)}
                </span>{" "}
                {t("back.management.table.of")}{" "}
                <span className="font-medium">{totalItems}</span>{" "}
                {t("back.management.table.results")}
              </p>
            </div>

            {/* Pagination component */}
            <div className="flex items-center">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
                className="p-2 disabled:opacity-50"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              {/* Page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`p-2 ${
                    currentPage === index + 1
                      ? "text-blue-600"
                      : "text-gray-700"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
                className="p-2 disabled:opacity-50"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
