import DateFilterButton from "@/components/back/DateFilterButton";
import FilterOptionsButton from "@/components/back/FilterOptionsButton";
import SearchInput from "@/components/back/SearchInput";
import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/back/PageHeader";
import DataTable, { Column } from "@/components/back/DataTable";
import DeleteConfirmationDialog from "@/components/back/DeleteConfirmationDialog";

interface ProductItem {
  id: number;
  name: string;
  menu_display_name: string;
  image: string;
  category: number;
  unit_price: string;
  stock: number;
  status: string;
  created_at: string;
  category_name: string;
}

interface SortState {
  field: keyof ProductItem;
  direction: "asc" | "desc";
}

interface FilterState {
  status: string;
  category: string;
  priceGt: string;
  priceLt: string;
}

interface ProductProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function Product({
  onMenuItemClick,
  successMessage,
}: ProductProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [productItems, setProductItems] = useState<ProductItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    direction: "asc",
  });
  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterState, setFilterState] = useState<FilterState>({
    status: "",
    category: "",
    priceGt: "",
    priceLt: "",
  });

  const columns: Column<ProductItem>[] = [
    {
      key: "image",
      header: "",
      render: (item: ProductItem) =>
        item.image ? (
          <img src={item.image} alt={item.name} className="w-16 h-16" />
        ) : null,
    },
    {
      key: "menu_display_name",
      header: t("back.management.menu.product.table.menuDisplayName"),
      sortable: true,
      bold: true,
    },
    {
      key: "category_name",
      header: t("back.management.menu.product.table.category"),
      sortable: true,
    },
    {
      key: "unit_price",
      header: t("back.management.menu.product.table.price"),
      sortable: true,
      bold: true,
    },
    {
      key: "stock",
      header: t("back.management.menu.product.table.stock"),
      sortable: true,
      bold: true,
    },
    {
      key: "status",
      header: t("back.management.menu.product.table.status"),
      bold: true,
    },
    {
      key: "created_at",
      header: t("back.management.menu.product.table.date"),
      sortable: true,
    },
    {
      key: "action",
      header: t("back.management.menu.product.table.action"),
    },
  ];

  const getStatusColor = (item: ProductItem) => {
    switch (item.status) {
      case "In Stock":
        return "bg-green-500";
      case "Low Stock":
        return "bg-yellow-500";
      case "Out of Stock":
        return "bg-red-500";
      default:
        return "bg-gray-500";
    }
  };

  const renderMobileCard = (item: ProductItem) => (
    <div className="flex items-center">
      {item.image && (
        <img src={item.image} alt={item.name} className="w-16 h-16 mr-4" />
      )}
      <div>
        <p className="font-bold text-gray-900">{item.menu_display_name}</p>
        <p className="text-gray-700">{item.category_name}</p>
        <p className="font-bold text-gray-900">${item.unit_price}</p>
      </div>
    </div>
  );

  const fetchProductItems = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
        let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;

        if (searchQuery) {
          url += `&menu_display_name=${encodeURIComponent(searchQuery)}`;
        }
        if (filterState.status) {
          url += `&status=${filterState.status}`;
        }
        if (filterState.category) {
          url += `&category=${encodeURIComponent(filterState.category)}`;
        }
        if (filterState.priceGt) {
          url += `&unit_price__gt=${filterState.priceGt}`;
        }
        if (filterState.priceLt) {
          url += `&unit_price__lt=${filterState.priceLt}`;
        }
        if (filterStartDate) {
          const formattedStartDate = filterStartDate.toISOString();
          url += `&created_at__gt=${formattedStartDate}`;
        }
        if (filterEndDate) {
          const formattedEndDate = filterEndDate.toISOString();
          url += `&created_at__lt=${formattedEndDate}`;
        }

        const response = await axiosInstance.get(url);
        let fetchedItems = response.data.results.map((item: ProductItem) => {
          // Convert and format the 'created_at' date
          const zonedDate = utcToZonedTime(item.created_at, "America/Chicago");
          const formattedDate = format(zonedDate, "MM/dd/yyyy, HH:mm");
          return { ...item, created_at: formattedDate };
        });

        // Sorting logic
        fetchedItems.sort((a: ProductItem, b: ProductItem) => {
          if (sortState.field === "created_at") {
            // Convert back to dates for comparison
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return sortState.direction === "asc"
              ? dateA.getTime() - dateB.getTime()
              : dateB.getTime() - dateA.getTime();
          } else {
            const aValue = a[sortState.field];
            const bValue = b[sortState.field];
            if (typeof aValue === "number" && typeof bValue === "number") {
              return sortState.direction === "asc"
                ? aValue - bValue
                : bValue - aValue;
            } else if (
              typeof aValue === "string" &&
              typeof bValue === "string"
            ) {
              return sortState.direction === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }
          }
          return 0;
        });

        setProductItems(fetchedItems);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  };

  const handleFilterStartDateChange = (date: Date | null) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDateChange = (date: Date | null) => {
    setFilterEndDate(date);
  };

  const handleFilterChange = (field: keyof FilterState, value: string) => {
    setFilterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRemoveFilters = () => {
    setFilterState({
      status: "",
      category: "",
      priceGt: "",
      priceLt: "",
    });
    setCurrentPage(1);
    fetchProductItems();
  };

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
      console.log("Banner message set:", successMessage);
      console.log("Show banner:", true);
    }
  }, [successMessage]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchProductItems();
    }, 500);

    return () => clearTimeout(timerId);
  }, [
    currentPage,
    searchQuery,
    sortState,
    axiosInstance,
    loading,
    user,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage(1);
      await fetchProductItems();
    };

    fetchData();
  }, [filterState]);

  const editItem = (id: number) => {
    onMenuItemClick("editProduct", "", id);
  };

  const openDeleteConfirmation = (id: number) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          await axiosInstance.delete(`${apiUrl}${itemToDelete}`);
          fetchProductItems();
          setBannerMessage("Product deleted successfully.");
          setIsSuccess(true);
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error deleting menu item:", error);
        setBannerMessage("Failed to delete product.");
        setIsSuccess(false);
        setShowBanner(true);
      }
      closeDeleteConfirmation();
    }
  };

  const handleSortChange = (field: keyof ProductItem) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],

      ...productItems.map((item) => [
        item.id,
        item.name,
        item.menu_display_name,
        item.category,
        item.category_name,
        item.unit_price,
        item.stock,
        item.created_at,
        item.status,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "product.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}

          <PageHeader
            title={t("back.management.menu.product.title")}
            exportLabel={t("back.management.menu.product.export")}
            addLabel={t("back.management.menu.product.addproduct")}
            onExport={downloadCSV}
            onAdd={() => onMenuItemClick("addProduct")}
          />

          <DeleteConfirmationDialog
            isOpen={deleteConfirmationOpen}
            onClose={closeDeleteConfirmation}
            onConfirm={confirmDelete}
            title={t("back.management.menu.product.deleteConfirmation.title")}
            message={t(
              "back.management.menu.product.deleteConfirmation.message"
            )}
          />

          {/* Desktop Layout */}
          <div className="desktop-layout">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md mr-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t("back.management.menu.product.searchProduct")}
                />
              </div>
              <div className="relative">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.product.selectdate")}
                />

                <FilterOptionsButton
                  filterOptions={[
                    {
                      label: t("back.management.menu.product.status"),
                      value: filterState.status,
                      onChange: (value) => handleFilterChange("status", value),
                      placeholder: t(
                        "back.management.menu.product.selectStatus"
                      ),
                      type: "select",
                      options: [
                        {
                          value: "In Stock",
                          label: t("back.management.menu.product.inStock"),
                        },
                        {
                          value: "Low Stock",
                          label: t("back.management.menu.product.lowStock"),
                        },
                        {
                          value: "Out of Stock",
                          label: t("back.management.menu.product.outOfStock"),
                        },
                      ],
                    },
                    {
                      label: t("back.management.menu.product.category"),
                      value: filterState.category,
                      onChange: (value) =>
                        handleFilterChange("category", value),
                      placeholder: t(
                        "back.management.menu.product.enterCategory"
                      ),
                      type: "input",
                    },
                    {
                      label: t("back.management.menu.product.priceGreaterThan"),
                      value: filterState.priceGt,
                      onChange: (value) => handleFilterChange("priceGt", value),
                      placeholder: t("back.management.menu.product.enterPrice"),
                      type: "input",
                    },
                    {
                      label: t("back.management.menu.product.priceLessThan"),
                      value: filterState.priceLt,
                      onChange: (value) => handleFilterChange("priceLt", value),
                      placeholder: t("back.management.menu.product.enterPrice"),
                      type: "input",
                    },
                  ]}
                  onRemoveFilters={handleRemoveFilters}
                />
              </div>
            </div>

            <DataTable
              data={productItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
            />
          </div>

          {/* Mobile Layout */}
          <div className="mobile-layout">
            <div className="flex flex-col mb-4">
              <div className="relative flex-1 max-w-md mb-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t("back.management.menu.product.searchProduct")}
                />
              </div>
              <div className="relative mb-4">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.product.selectdate")}
                />

                <FilterOptionsButton
                  filterOptions={[
                    {
                      label: t("back.management.menu.product.status"),
                      value: filterState.status,
                      onChange: (value) => handleFilterChange("status", value),
                      placeholder: t(
                        "back.management.menu.product.selectStatus"
                      ),
                      type: "select",
                      options: [
                        {
                          value: "In Stock",
                          label: t("back.management.menu.product.inStock"),
                        },
                        {
                          value: "Low Stock",
                          label: t("back.management.menu.product.lowStock"),
                        },
                        {
                          value: "Out of Stock",
                          label: t("back.management.menu.product.outOfStock"),
                        },
                      ],
                    },
                    {
                      label: t("back.management.menu.product.category"),
                      value: filterState.category,
                      onChange: (value) =>
                        handleFilterChange("category", value),
                      placeholder: t(
                        "back.management.menu.product.enterCategory"
                      ),
                      type: "input",
                    },
                    {
                      label: t("back.management.menu.product.priceGreaterThan"),
                      value: filterState.priceGt,
                      onChange: (value) => handleFilterChange("priceGt", value),
                      placeholder: t("back.management.menu.product.enterPrice"),
                      type: "input",
                    },
                    {
                      label: t("back.management.menu.product.priceLessThan"),
                      value: filterState.priceLt,
                      onChange: (value) => handleFilterChange("priceLt", value),
                      placeholder: t("back.management.menu.product.enterPrice"),
                      type: "input",
                    },
                  ]}
                  onRemoveFilters={handleRemoveFilters}
                />
              </div>
            </div>

            <DataTable
              data={productItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
            />
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
