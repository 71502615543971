import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "@/components/front/Logo";
import LogoLight from "@/assets/img/logo-light.png";
import LogoDark from "@/assets/img/logo-dark.png";
import LocaleSelector from "@/components/ui/selectors/LocaleSelector";
import FeaturesDropdown from "@/components/ui/selectors/FeaturesDropdown";
import BusinessDropdown from "@/components/ui/selectors/BusinessDropdown";
import PricingDropdown from "@/components/ui/selectors/PricingDropdown";
import CompanyDropdown from "@/components/ui/selectors/CompanyDropdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { RootState } from "@/store";
import { Theme } from "@/application/enums/shared/Theme";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState("");

  const theme = useSelector((state: any) => {
    return state.theme.value;
  });

  const openDropdown = (dropdownName: string) => {
    setActiveDropdown(dropdownName);
  };

  const closeDropdown = () => {
    setActiveDropdown("");
  };

  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <div className="pb-6">
        <div className="relative pt-6 z-10">
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10 md:justify-center"
              aria-label="Global"
            >
              <div className="flex items-center justify-between w-full md:w-auto">
                <Logo className="hidden lg:block" size="h-12" />
                <div className="-mr-1 flex w-full items-center justify-between lg:hidden">
                  <div className="flex-grow-0">
                    <button
                      onClick={() => setOpen(!open)}
                      type="button"
                      className="bg-white dark:bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                      id="main-menu"
                      aria-haspopup="true"
                    >
                      <span className="sr-only">{t("shared.close")}</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="items-center">
                    <Link to="/">
                      <img
                        src={LogoLight}
                        alt="Logo"
                        className="md:hidden h-14 w-auto"
                      />
                    </Link>
                  </div>

                  <div className="items-center md:hidden">
                    <Link
                      to="/contact"
                      className="px-2 py-3 text-base font-medium text-white bg-red-500 rounded-lg mobile-menu-link"
                      role="menuitem"
                    >
                      {t("front.navbar.register")}
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex ml-auto">
                <div className="hidden lg:flex space-x-2 sm:space-x-4 md:space-x-6">
                  <div className="mt-6 text-xl">
                    <FeaturesDropdown
                      isActive={activeDropdown === "features"}
                      requestOpen={() => openDropdown("features")}
                      requestClose={closeDropdown}
                    />
                  </div>
                  <div className="mt-6 text-xl">
                    <BusinessDropdown
                      isActive={activeDropdown === "business"}
                      requestOpen={() => openDropdown("business")}
                      requestClose={closeDropdown}
                    />
                  </div>
                  <div className="mt-6 text-xl">
                    <PricingDropdown
                      isActive={activeDropdown === "pricing"}
                      requestOpen={() => openDropdown("pricing")}
                      requestClose={closeDropdown}
                    />
                  </div>
                  <div className="mt-6 text-xl">
                    <CompanyDropdown
                      isActive={activeDropdown === "company"}
                      requestOpen={() => openDropdown("company")}
                      requestClose={closeDropdown}
                    />
                  </div>

                  <div
                    className="mt-6 hidden lg:block"
                    style={{ marginRight: "80px" }}
                  >
                    <LocaleSelector />
                  </div>
                </div>

                <div className="flex items-center mt-3">
                  <Link
                    to="/contact"
                    className="hidden md:block px-3 py-3 md:px-5 md:py-3 mr-6 rounded-md text-base font-medium text-white bg-red-500 hover:bg-red-600"
                    role="menuitem"
                  >
                    {t("front.navbar.register")}
                  </Link>
                  <Link
                    to="/login"
                    className="hidden md:block px-3 py-3 md:px-5 md:py-3 mr-6 rounded-md text-base font-medium text-white bg-black hover:bg-gray-500"
                    role="menuitem"
                  >
                    {t("front.navbar.login")}
                  </Link>
                </div>
              </div>
            </nav>
          </div>

          {/* Mobile menu */}
          <Transition
            show={open}
            as={Fragment}
            enter="duration-300 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-200 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-50 bg-white dark:bg-gray-900 overflow-y-auto">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  {(() => {
                    if (theme === Theme.DARK) {
                      return (
                        <img
                          alt="Icon"
                          className="h-12 w-auto sm:h-16"
                          src={LogoDark}
                        />
                      );
                    } else {
                      return (
                        <Link to="/">
                          <img
                            src={LogoLight}
                            alt="Logo"
                            className="md:hidden h-14 w-auto"
                          />
                        </Link>
                      );
                    }
                  })()}
                </div>
                <button
                  onClick={() => setOpen(!open)}
                  type="button"
                  className="text-red-500 text-xl focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <div
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div className="px-5 pt-2 pb-10 space-y-12" role="none">
                  <Link
                    to="/"
                    className="block px-3 py-5 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 mobile-menu-link"
                    role="menuitem"
                  >
                    {t("front.navbar.home")}
                  </Link>
                  <FeaturesDropdown
                    isActive={activeDropdown === "features"}
                    requestOpen={() => openDropdown("features")}
                    requestClose={closeDropdown}
                    className="mobile-menu-link"
                  />
                  <BusinessDropdown
                    isActive={activeDropdown === "business"}
                    requestOpen={() => openDropdown("business")}
                    requestClose={closeDropdown}
                    className="mobile-menu-link"
                  />
                  <PricingDropdown
                    isActive={activeDropdown === "pricing"}
                    requestOpen={() => openDropdown("pricing")}
                    requestClose={closeDropdown}
                    className="mobile-menu-link"
                  />
                  <CompanyDropdown
                    isActive={activeDropdown === "company"}
                    requestOpen={() => openDropdown("company")}
                    requestClose={closeDropdown}
                    className="mobile-menu-link"
                  />
                  <div
                    className="flex justify-between items-center py-1"
                    role="none"
                  >
                    <div className="flex-grow-0">
                      <LocaleSelector />
                    </div>
                    <div className="flex-grow"></div>
                    <div className="flex-grow-0">
                      <Link
                        to="/login"
                        className="block px-3 py-3 text-base font-medium text-white bg-red-500 rounded-md"
                        role="menuitem"
                      >
                        {t("front.navbar.login")}
                      </Link>
                    </div>
                  </div>
                  {/* Download App Button for Mobile */}
                  <div className="flex justify-center mt-6">
                    <button
                      onClick={() => navigate("/downloadapp")}
                      className="flex items-center justify-center p-4 bg-white rounded-full shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <img
                        src="/logo512.png"
                        alt="Download App"
                        className="h-10 w-10 mr-3"
                      />
                      <span className="text-lg font-bold text-gray-900">
                        {t("front.footer.downloadApp", "Download Our App")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
}
