import React, { useState, useEffect, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import FeaturesHeroImage from "@/assets/img/FeaturesHero.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeadset, faChalkboardUser } from "@fortawesome/free-solid-svg-icons";
// import { faBell as farBell } from "@fortawesome/free-regular-svg-icons";

const FeaturesHero = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    container: {
      textAlign: "center",
      padding: "50px",
    },
    title: {
      fontSize: "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
    },
    subtitle: {
      fontSize: "1.5em",
      color: "#666",
      marginBottom: "40px",
    },
    feature: {
      display: isMobile ? "block" : "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
    featureTitle: {
      fontSize: "2rem",
      fontWeight: "bold",
      color: "#C70039",
      marginBottom: "10px",
    },
    featureDescription: {
      fontSize: isMobile ? "1em" : "1.2em",
      color: "#666",
      maxWidth: "80%",
      margin: "auto",
    },
    icon: {
      color: "#000000",
      fontSize: "2em",
      marginBottom: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div className="relative z-10 px-6 pt-16 md:pt-24 pb-16 md:pb-24 space-y-3">
        <h1 className="relative z-10 pb-6 text-3xl sm:text-5xl md:text-6xl lg:text-7.5xl font-black tracking-snug text-center leading-11 sm:leading-15 md:leading-18 lg:leading-22 text-gray-800 dark:text-white">
          <span className="flex justify-center">
            <span className="mr-4">{t("front.features.pos.title5")}</span>
          </span>
        </h1>
        <div className="relative z-10 pb-10 text-gray-500 text-lg md:text-2xl text-center leading-normal md:leading-9">
          <p className="sm:text-lg max-w-2xl mx-auto">
            {t("front.features.pos.description6")}
          </p>
        </div>
        <img
          src={FeaturesHeroImage}
          alt="Description"
          className="mt-4 mx-auto shadow-lg transform transition-transform duration-200"
        />
      </div>
      <div className="px-6 py-10">
        <div style={styles.feature}>
          <div>
            {/* <FontAwesomeIcon icon={faHeadset} style={styles.icon} /> */}
            <h2 style={styles.featureTitle}>
              {t("front.features.pos.title6")}
            </h2>
            <p style={styles.featureDescription}>
              {t("front.features.pos.description7")}
            </p>
          </div>
          <div>
            {/* <FontAwesomeIcon icon={farBell} style={styles.icon} /> */}
            <h2 style={styles.featureTitle}>
              {t("front.features.pos.title7")}
            </h2>
            <p style={styles.featureDescription}>
              {t("front.features.pos.description8")}
            </p>
          </div>
          <div>
            {/* <FontAwesomeIcon icon={faChalkboardUser} style={styles.icon} /> */}
            <h2 style={styles.featureTitle}>
              {t("front.features.pos.title8")}
            </h2>
            <p style={styles.featureDescription}>
              {t("front.features.pos.description9")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesHero;
