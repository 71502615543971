import React, {CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import Contact from "@/views/front/Contact";

import PricingToggle from "@/components/ui/toggles/PricingToggle";

const Pricing = () => {
  const { t } = useTranslation();

  const styles: Record<string, CSSProperties> = {
    container: {
      padding: "2rem",
      marginTop: "6rem",
    },
    header: {
      textAlign: "center",
      marginBottom: "2rem",
    },
    title: {
      fontSize: "2.25rem",
      fontWeight: "bold",
      marginTop: "2rem",
      marginBottom: "1rem",
    },
    highlight: {
      color: "#e60000",
    },
    subtitle: {
      marginBottom: "2rem",
    },
    toggleButton: {
      padding: "0.5rem 1rem",
      backgroundColor: "#e5e7eb",
      marginRight: "0.25rem",
    },
    cardTitleDescription: {
      height: "180px",
    },
    cardTitle: {
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    cardDescription: {
      marginBottom: "1rem",
    },
    cardPrice: {
      fontWeight: "bold",
      fontSize: "1.875rem",
      marginBottom: "1rem",
    },
    middlesection: {
      marginBottom: "1.5rem",
    },
    getStartedButton: {
      backgroundColor: "#ef4444",
      color: "white",
      padding: "0.5rem 1.5rem",
      borderRadius: "0.375rem",
      display: "block",
      width: "fit-content",
      margin: "0 auto",
    },
    featureTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      marginBottom: "1rem",
    },
    featureList: {
      listStyleType: "none",
      padding: 0,
      margin: "1rem 0",
    },
    featureListItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "0.5rem",
    },
    featureIcon: {
      color: "#e60000",
      marginRight: "0.5rem",
    },
  };

  const pricingPlan = useSelector(
    (state: RootState) => state.pricingPlan.value
  );
  const getPricingKey = (baseKey: string) => {
    return `${baseKey}.${
      pricingPlan === "monthly" ? "monthlyPrice" : "yearlyPrice"
    }`;
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{t("front.pricing.helmet")} | Redbird Corporation</title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <div>
        <div style={styles.container}>
          <div style={styles.header}>
            <h1 style={styles.title}>
              {t("front.pricing.title1")}&nbsp;
              <span style={styles.highlight}>
                {t("front.pricing.spantitle")}
              </span>
              {t("front.pricing.title2")}
            </h1>
            <p style={styles.subtitle}>{t("front.pricing.subtitle")}</p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginBottom: "1rem",
              marginRight: "18rem",
              color: "#e60000",
            }}
          >
            <span style={{ marginRight: "0.5rem", fontWeight: "bold" }}>
              {t("front.pricing.toggletitle")}
            </span>
            <PricingToggle />
          </div>

          <div className="pricing-container">
            {/* Pricing Card */}
            <div className="pricing-card bg-white shadow-lg rounded-lg p-6">
              <div style={styles.cardTitleDescription}>
                <h2 className="text-lg font-bold" style={styles.cardTitle}>
                  {t("front.pricing.plan1.title")}
                </h2>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan1.description")}
                </p>
              </div>

              <div style={styles.middlesection}>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan1.startingat")}
                </p>
                <p className="text-3xl font-bold" style={styles.cardPrice}>
                  {t(getPricingKey("front.pricing.plan1"))}
                </p>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    style={styles.getStartedButton}
                  >
                    {t("front.pricing.plan1.button")}
                  </button>
                </Link>
              </div>

              <div>
                <ul style={styles.featureList}>
                  <p className="text-gray-600" style={styles.featureTitle}>
                    {t("front.pricing.plan1.feature0")}
                  </p>
                  {/* Feature List */}
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan1.feature1")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan1.feature2")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan1.feature3")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan1.feature4")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan1.feature5")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="pricing-card bg-white shadow-lg rounded-lg p-6">
              <div style={styles.cardTitleDescription}>
                <h2 className="text-lg font-bold" style={styles.cardTitle}>
                  {t("front.pricing.plan2.title")}
                </h2>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan2.description")}
                </p>
              </div>

              <div style={styles.middlesection}>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan2.startingat")}
                </p>
                <p className="text-3xl font-bold" style={styles.cardPrice}>
                  {t(getPricingKey("front.pricing.plan2"))}
                </p>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    style={styles.getStartedButton}
                  >
                    {t("front.pricing.plan2.button")}
                  </button>
                </Link>
              </div>

              <div>
                <p className="text-gray-600" style={styles.featureTitle}>
                  {t("front.pricing.plan2.feature0")}
                </p>
                <ul style={styles.featureList}>
                  {/* Feature List */}
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan2.feature1")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan2.feature2")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan2.feature3")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="pricing-card bg-white shadow-lg rounded-lg p-6">
              <div style={styles.cardTitleDescription}>
                <h2 className="text-lg font-bold" style={styles.cardTitle}>
                  {t("front.pricing.plan3.title")}
                </h2>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan3.description")}
                </p>
              </div>

              <div style={styles.middlesection}>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan3.startingat")}
                </p>
                <p className="text-3xl font-bold" style={styles.cardPrice}>
                  {t(getPricingKey("front.pricing.plan3"))}
                </p>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    style={styles.getStartedButton}
                  >
                    {t("front.pricing.plan3.button")}
                  </button>
                </Link>
              </div>

              <div>
                <ul style={styles.featureList}>
                  <p className="text-gray-600" style={styles.featureTitle}>
                    {t("front.pricing.plan3.feature0")}
                  </p>
                  {/* Feature List */}
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature1")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature2")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature3")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature4")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature5")}
                  </li>
                </ul>
              </div>
            </div>

            <div className="pricing-card bg-white shadow-lg rounded-lg p-6">
              <div style={styles.cardTitleDescription}>
                <h2 className="text-lg font-bold" style={styles.cardTitle}>
                  {t("front.pricing.plan4.title")}
                </h2>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan4.description")}
                </p>
              </div>

              <div style={styles.middlesection}>
                <p className="text-gray-600" style={styles.cardDescription}>
                  {t("front.pricing.plan4.startingat")}
                </p>
                <p className="text-3xl font-bold" style={styles.cardPrice}>
                  {t(getPricingKey("front.pricing.plan4"))}
                </p>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    style={styles.getStartedButton}
                  >
                    {t("front.pricing.plan4.button")}
                  </button>
                </Link>
              </div>

              <div>
                <ul style={styles.featureList}>
                  <p className="text-gray-600" style={styles.featureTitle}>
                    {t("front.pricing.plan4.feature0")}
                  </p>
                  {/* Feature List */}
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan4.feature1")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan4.feature2")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan4.feature3")}
                  </li>
                </ul>
                <ul style={styles.featureList}>
                  <li style={styles.featureListItem}>
                    <span className="text-green-500" style={styles.featureIcon}>
                      &#10003;
                    </span>
                    {t("front.pricing.plan3.feature4")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default Pricing;
