import { CartItem } from "../CartPage";

export const applyBranch10Promotion = (cart: CartItem[]): CartItem[] => {
  const subtotal = cart.reduce((total, item) => {
    if (item.id !== 1510) {
      return total + parseFloat(item.unit_price) * item.quantity;
    }
    return total;
  }, 0);

  const newCart: CartItem[] = cart.filter((item) => item.id !== 1510);

  if (subtotal >= 30) {
    const item1510: CartItem = {
      id: 1510,
      name: "Fried Dumpling (Free)",
      unit_price: "0.00",
      quantity: 1,
      selected_options: {},
      uniqueId: "free_dumpling",
      description: "",
      image:
        "https://redbirdimages.s3.amazonaws.com/images/6099bb38fc9e4d2fa6f4ff74b8f116c3",
      product_option_lists: [],
    };

    if (!newCart.some((item) => item.id === 1510)) {
      newCart.push(item1510);
    }
  }

  return newCart;
};
