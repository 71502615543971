import React, { useState } from "react";
import RefundPopup from "./RefundPopup";

interface RefundButtonProps {
  order: {
    id: number;
    order_number: string;
    created_at: string;
    total_price: string;
    payment_status: string;
  };
  onRefundComplete: () => void;
}

const RefundButton: React.FC<RefundButtonProps> = ({
  order,
  onRefundComplete,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  return (
    <div>
      <button
        className="bg-red-500 text-white py-1 px-4 rounded h-12"
        onClick={(e) => {
          e.stopPropagation();
          openPopup();
        }}
      >
        Refund
      </button>
      {isPopupOpen && (
        <RefundPopup
          order={order}
          onClose={closePopup}
          onRefundComplete={onRefundComplete}
        />
      )}
    </div>
  );
};

export default RefundButton;
