import React from "react";

interface SummaryData {
  total_subtotal_sales: number;
  total_tips: number;
  total_tax: number;
  total_service_fee_charge: number;
  total_paid_orders: number;
  total_unpaid_orders: number;
}

interface PrintableDailyReportProps {
  summaryData: SummaryData;
}

const PrintableDailyReport = React.forwardRef<
  HTMLDivElement,
  PrintableDailyReportProps
>(({ summaryData }, ref) => (
  <div ref={ref} className="printable-report w-[76mm] p-2 text-md font-mono">
    <h2 className="text-center font-bold mb-2">Daily Sales Report</h2>
    <div className="border-b border-black mb-2"></div>
    <div className="space-y-1">
      <div className="flex justify-between">
        <span>Subtotal Sales:</span>
        <span>${summaryData.total_subtotal_sales.toFixed(2)}</span>
      </div>
      <div className="flex justify-between">
        <span>Tips:</span>
        <span>${summaryData.total_tips.toFixed(2)}</span>
      </div>
      <div className="flex justify-between">
        <span>Tax:</span>
        <span>${summaryData.total_tax.toFixed(2)}</span>
      </div>
      <div className="flex justify-between">
        <span>Service Fee:</span>
        <span>${summaryData.total_service_fee_charge.toFixed(2)}</span>
      </div>
      <div className="border-b border-black my-1"></div>
      <div className="flex justify-between font-bold">
        <span>Total Sales:</span>
        <span>
          $
          {(
            summaryData.total_subtotal_sales +
            summaryData.total_tips +
            summaryData.total_tax +
            summaryData.total_service_fee_charge
          ).toFixed(2)}
        </span>
      </div>
      <div className="border-b border-black my-1"></div>
      <div className="flex justify-between">
        <span>Paid Orders:</span>
        <span>{summaryData.total_paid_orders}</span>
      </div>
      <div className="flex justify-between">
        <span>Unpaid Orders:</span>
        <span>{summaryData.total_unpaid_orders}</span>
      </div>
    </div>
    <div className="text-center text-[10px] mt-4">
      {new Date().toLocaleString()}
    </div>
    <div className="text-center text-[10px] mt-2 mb-4">Powered by Redbird</div>
    <div className="border-t border-dashed border-black pt-1 text-center text-[8px]">
      - - - - - - - - - - Cut Here - - - - - - - - - -
    </div>
  </div>
));

PrintableDailyReport.displayName = "PrintableDailyReport";

export default PrintableDailyReport;