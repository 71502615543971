import Logo from "@/components/front/Logo";
import { useCustomer } from "@/contexts/CustomerContext";
import { selectCartItemCount } from "@/features/cart/cart";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface HeaderOnlineOrderProps {
  isSelectedBranchOpen: boolean;
}

export default function HeaderOnlineOrder({
  isSelectedBranchOpen,
}: HeaderOnlineOrderProps) {
  const itemCount = useSelector(selectCartItemCount);
  const { customer } = useCustomer();

  const cartIconClass = isSelectedBranchOpen
    ? "text-gray-500 hover:text-gray-700"
    : "text-gray-300 cursor-not-allowed";

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "white",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      }}
    >
      <div className="pb-6">
        <div className="relative pt-6 z-10">
          <div className="max-w-8xl mx-auto px-4 sm:px-6">
            <nav
              className="relative flex items-center justify-between sm:h-10"
              aria-label="Global"
            >
              <div className="flex items-center justify-between w-full">
                <Link to="/">
                  <Logo className="h-12 w-auto" />
                </Link>
                <div className="relative flex items-center gap-4">
                  {customer?.name ? (
                    <div className="flex items-center gap-2">
                      <Link
                        to="/customer-profile"
                        className="flex items-center gap-2 text-gray-500 hover:text-gray-700"
                      >
                        <span className="text-gray-500 hover:text-gray-700">
                          {customer.name}
                        </span>
                      </Link>
                    </div>
                  ) : (
                    <Link
                      to="/customer-login"
                      className="text-gray-500 hover:text-gray-700"
                    >
                      Login/Sign Up
                    </Link>
                  )}
                  {isSelectedBranchOpen ? (
                    <Link to="/cart" className={cartIconClass}>
                      <FontAwesomeIcon icon={faShoppingCart} size="2x" />
                      {itemCount > 0 && (
                        <div className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                          {itemCount}
                        </div>
                      )}
                    </Link>
                  ) : (
                    <span className={cartIconClass}>
                      <FontAwesomeIcon icon={faShoppingCart} size="2x" />
                      {itemCount > 0 && (
                        <div className="absolute -top-1 -right-1 bg-gray-400 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                          {itemCount}
                        </div>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
