import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AxiosError } from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

interface AddPromotionProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

interface Promotion {
  id: number;
  name: string;
  parent_category: number | null;
}

interface Category {
  id: number;
  name: string;
}

interface Item {
  id: number;
  name: string;
  category_id: number;
}

const AddPromotion = ({
  onMenuItemClick,
  setSuccessMessage,
}: AddPromotionProps) => {
  const { t } = useTranslation();

  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [promotionType, setPromotionType] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [items, setItems] = useState<Item[]>([]);
  const [selectedDiscountItem, setSelectedDiscountItem] = useState<number>(0);
  const [thresholdCategory, setThresholdCategory] = useState<{
    id: number;
    name: string;
  }>({
    id: 0,
    name: "",
  });
  const [discountItemCategories, setDiscountItemCategories] = useState<
    Category[]
  >([]);
  const [selectedDiscountItemCategory, setSelectedDiscountItemCategory] =
    useState<string>("");
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [thresholdAmount, setThresholdAmount] = useState("");
  const [itemAmount, setItemAmount] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [promotionAreaTyoe, setPromotionAreaType] = useState("");
  const [showSpaceWarning, setShowSpaceWarning] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          setCategories(response.data.results);
          setDiscountItemCategories(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [axiosInstance, loading, user?.selectedBranch]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        if (!loading && axiosInstance && selectedDiscountItemCategory) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          const response = await axiosInstance.get(
            `${apiUrl}?category=${selectedDiscountItemCategory}`
          );
          setItems(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    if (selectedDiscountItemCategory) {
      fetchItems();
    } else {
      setItems([]);
    }
  }, [axiosInstance, loading, selectedDiscountItemCategory]);

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
    const fetchPromotions = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          setPromotions(response.data);
        }
      } catch (error) {
        console.error("Error fetching promotions:", error);
      }
    };

    fetchPromotions();
  }, [axiosInstance, loading, user?.selectedBranch]);

  interface PromotionData {
    name: string;
    description: string;
    status: string;
    promotion_type: string;
    promotion_area_type: string;
    discount_type: string;
    branch: any;
    start_date: string | null;
    end_date: string | null;
    dollar_amount_to_be_reached: number;
    product_amount_to_be_reached: number;
    dollar_amount_off: number;
    percentage_off: number;
    category?: number;
    item?: number;
  }

  const handleAddPromotion = async () => {
    const selectedBranch = user?.selectedBranch?.id;
    const promotionData: PromotionData = {
      name,
      description: description || "",
      status,
      promotion_type: promotionType,
      promotion_area_type: promotionAreaTyoe,
      discount_type: discountType,
      branch: selectedBranch,
      start_date: startTime ? format(startTime, "yyyy-MM-dd") : null,
      end_date: endTime ? format(endTime, "yyyy-MM-dd") : null,
      dollar_amount_to_be_reached:
        promotionType === "Dollar Amount Cap" ? parseFloat(thresholdAmount) : 0,
      product_amount_to_be_reached:
        promotionType === "Product Amount Cap" ? parseInt(itemAmount, 10) : 0,
      dollar_amount_off:
        discountType === "Fixed Amount" ? parseFloat(discountValue) : 0,
      percentage_off:
        discountType === "Percentage" ? parseFloat(discountValue) : 0,
    };

    if (promotionType !== "Entire Order Discount") {
      promotionData.category = thresholdCategory.id;
      promotionData.item = selectedDiscountItem;
    }

    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
        const response = await axiosInstance.post(apiUrl, promotionData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 201) {
          const successMessage = t("back.management.menu.promotion.successadd");
          setSuccessMessage("Promotion added successfully");
          setSuccessMessage(successMessage);
          setIsSuccess(true);
          setBannerMessage(successMessage);
          setShowBanner(true);
          onMenuItemClick("promotion", successMessage);
        }
      }
    } catch (error) {
      console.error("Error adding promotion:", error);
      if (error instanceof Error) {
        if (error.name === "AxiosError") {
          const axiosError = error as AxiosError;
          if (axiosError.response) {
            const { status, data } = axiosError.response;
            if (status === 400) {
              // Bad Request error
              const errorData = data as { description?: string };
              const errorMessage = errorData.description || "Bad Request";
              setIsSuccess(false);
              setBannerMessage(errorMessage);
              setShowBanner(true);
            } else {
              // Other error status codes
              setIsSuccess(false);
              setBannerMessage(t("back.management.menu.promotion.failadd"));
              setShowBanner(true);
            }
          } else {
            // Network or other errors
            setIsSuccess(false);
            setBannerMessage(t("back.management.menu.promotion.failadd"));
            setShowBanner(true);
          }
        } else {
          // Other error types
          setIsSuccess(false);
          setBannerMessage(error.message);
          setShowBanner(true);
        }
      } else {
        // Unknown error type
        setIsSuccess(false);
        setBannerMessage(t("back.management.menu.promotion.failadd"));
        setShowBanner(true);
      }
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const promotionTypeOptions = [
    {
      label: t("back.management.menu.promotion.orderAmountThreshold"),
      value: "Dollar Amount Cap",
    },
    {
      label: t("back.management.menu.promotion.itemCountThreshold"),
      value: "Product Amount Cap",
    },
    {
      label: t("back.management.menu.promotion.singleItemDiscount"),
      value: "One Item Discount",
    },
    {
      label: t("back.management.menu.promotion.entireOrderDiscount"),
      value: "Entire Order Discount",
    },
  ];

  const renderDynamicRow = (type: string) => {
    switch (type) {
      case "Dollar Amount Cap":
        return (
          <>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.thresholdcategory")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={thresholdCategory.id}
                onChange={(e) => {
                  const selectedCategoryId = parseInt(e.target.value);
                  const selectedCategory = categories.find(
                    (category) => category.id === selectedCategoryId
                  );
                  setThresholdCategory({
                    id: selectedCategoryId,
                    name: selectedCategory?.name || "",
                  });
                }}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectcategory")}
                </option>
                {categories.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.thresholdamount")}
              </label>
              <input
                type="number"
                placeholder={t(
                  "back.management.menu.promotion.enterthreshholdamount"
                )}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={thresholdAmount}
                onChange={(e) => setThresholdAmount(e.target.value)}
                min="0"
              />
            </div>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.discounttype")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectdiscounttype")}
                </option>
                <option value="Percentage">
                  {t("back.management.menu.promotion.percentageoff")}
                </option>
                <option value="Fixed Amount">
                  {t("back.management.menu.promotion.amountoff")}
                </option>
              </select>
            </div>
            {discountType && (
              <>
                <div className="px-2 mb-4 w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItemCategory")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItemCategory}
                    onChange={(e) => {
                      setSelectedDiscountItemCategory(e.target.value);
                      setSelectedDiscountItem(0);
                    }}
                  >
                    <option value="">
                      {t(
                        "back.management.menu.promotion.selectDiscountItemCategory"
                      )}
                    </option>
                    {discountItemCategories.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItem")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItem}
                    onChange={(e) =>
                      setSelectedDiscountItem(parseInt(e.target.value))
                    }
                  >
                    <option value="">
                      {t("back.management.menu.promotion.selectDiscountItem")}
                    </option>
                    {items.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2 px-2 mb-4">
                  {discountType === "Percentage" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountpercentage")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountpercentage"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}

                  {discountType === "Fixed Amount" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountamount")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountamount"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        );
      case "Product Amount Cap":
        return (
          <>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.thresholdcategory")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={thresholdCategory.id}
                onChange={(e) => {
                  const selectedCategoryId = parseInt(e.target.value);
                  const selectedCategory = categories.find(
                    (category) => category.id === selectedCategoryId
                  );
                  setThresholdCategory({
                    id: selectedCategoryId,
                    name: selectedCategory?.name || "",
                  });
                }}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectcategory")}
                </option>
                {categories.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.itemCountAmount")}
              </label>
              <input
                type="number"
                placeholder={t(
                  "back.management.menu.promotion.enterItemCountAmount"
                )}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={itemAmount}
                onChange={(e) => setItemAmount(e.target.value)}
                min="0"
              />
            </div>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.discounttype")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectdiscounttype")}
                </option>
                <option value="Percentage">
                  {t("back.management.menu.promotion.percentageoff")}
                </option>
                <option value="Fixed Amount">
                  {t("back.management.menu.promotion.amountoff")}
                </option>
              </select>
            </div>
            {discountType && (
              <>
                <div className="px-2 mb-4 w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItemCategory")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItemCategory}
                    onChange={(e) => {
                      setSelectedDiscountItemCategory(e.target.value);
                      setSelectedDiscountItem(0);
                    }}
                  >
                    <option value="">
                      {t(
                        "back.management.menu.promotion.selectDiscountItemCategory"
                      )}
                    </option>
                    {discountItemCategories.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItem")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItem}
                    onChange={(e) =>
                      setSelectedDiscountItem(parseInt(e.target.value))
                    }
                  >
                    <option value="">
                      {t("back.management.menu.promotion.selectDiscountItem")}
                    </option>
                    {items.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2 px-2 mb-4">
                  {discountType === "Percentage" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountpercentage")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountpercentage"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}

                  {discountType === "Fixed Amount" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountamount")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountamount"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        );
      case "One Item Discount":
        return (
          <>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.discounttype")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectdiscounttype")}
                </option>
                <option value="Percentage">
                  {t("back.management.menu.promotion.percentageoff")}
                </option>
                <option value="Fixed Amount">
                  {t("back.management.menu.promotion.amountoff")}
                </option>
              </select>
            </div>
            {discountType && (
              <>
                <div className="px-2 mb-4 w-full">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItemCategory")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItemCategory}
                    onChange={(e) => {
                      setSelectedDiscountItemCategory(e.target.value);
                      setSelectedDiscountItem(0);
                    }}
                  >
                    <option value="">
                      {t(
                        "back.management.menu.promotion.selectDiscountItemCategory"
                      )}
                    </option>
                    {discountItemCategories.map(({ name }) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-1/2 px-2 mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    {t("back.management.menu.promotion.discountItem")}
                  </label>
                  <select
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                    value={selectedDiscountItem}
                    onChange={(e) =>
                      setSelectedDiscountItem(parseInt(e.target.value))
                    }
                  >
                    <option value="">
                      {t("back.management.menu.promotion.selectDiscountItem")}
                    </option>
                    {items.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2 px-2 mb-4">
                  {discountType === "Percentage" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountpercentage")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountpercentage"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}
                  {discountType === "Fixed Amount" && (
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.menu.promotion.discountamount")}
                      </label>
                      <input
                        type="number"
                        placeholder={t(
                          "back.management.menu.promotion.enterdiscountamount"
                        )}
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                        value={discountValue}
                        onChange={(e) => setDiscountValue(e.target.value)}
                      />
                    </div>
                  )}
                  ;
                </div>
              </>
            )}
          </>
        );

      case "Entire Order Discount":
        return (
          <>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("back.management.menu.promotion.discounttype")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <option value="">
                  {t("back.management.menu.promotion.selectdiscounttype")}
                </option>
                <option value="Percentage">
                  {t("back.management.menu.promotion.percentageoff")}
                </option>
                <option value="Fixed Amount">
                  {t("back.management.menu.promotion.amountoff")}
                </option>
              </select>
            </div>
            {discountType && (
              <div className="w-1/2 px-2 mb-4">
                {discountType === "Percentage" && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {t("back.management.menu.promotion.discountpercentage")}
                    </label>
                    <input
                      type="number"
                      placeholder={t(
                        "back.management.menu.promotion.enterdiscountpercentage"
                      )}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                      value={discountValue}
                      onChange={(e) => setDiscountValue(e.target.value)}
                    />
                  </div>
                )}

                {discountType === "Fixed Amount" && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {t("back.management.menu.promotion.discountamount")}
                    </label>
                    <input
                      type="number"
                      placeholder={t(
                        "back.management.menu.promotion.enterdiscountamount"
                      )}
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                      value={discountValue}
                      onChange={(e) => setDiscountValue(e.target.value)}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const statusOptions = [
    <option key="active" value="Active">
      {t("back.management.menu.category.status1")}
    </option>,
    <option key="inactivate" value="Inactive">
      {t("back.management.menu.category.status2")}
    </option>,
    <option key="draft" value="Draft">
      {t("back.management.menu.category.status4")}
    </option>,
  ];

  const promotionAreaTypeOptions = [
    <option key="External" value="External">
      {t("back.management.menu.promotion.external")}
    </option>,
    <option key="Internal" value="Internal">
      {t("back.management.menu.promotion.internal")}
    </option>,
  ];

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.promotion.addpromotion")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.promotion.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="categoryName"
              >
                {t("back.management.menu.promotion.promotionname")}
              </label>
              <input
                type="text"
                id="itemName"
                placeholder="Enter promotion type"
                value={name}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setName(inputValue);
                  setShowSpaceWarning(inputValue.includes(" "));
                }}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
              {showSpaceWarning && (
                <div className="text-red-500 text-sm mt-1">
                  {t("back.management.menu.promotion.noSpaceAllowed")}
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 px-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="promotionAreaType"
              >
                {t("back.management.menu.promotion.promotionAreaType")}
              </label>
              <select
                value={promotionAreaTyoe}
                onChange={(e) => setPromotionAreaType(e.target.value)}
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.promotion.selectPromotionAreaType")}
                </option>
                {promotionAreaTypeOptions}
              </select>
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.category.description")}
            </label>
            <textarea
              id="description"
              placeholder="Enter promotion description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            ></textarea>
          </div>
        </div>

        {/* Promotion Type Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.promotion.promotiontype")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2 mt-2"
                htmlFor="promotionType"
              >
                {t("back.management.menu.promotion.type")}
              </label>
              <select
                id="promotionType"
                value={promotionType}
                onChange={(e) => setPromotionType(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">{t("Select Promotion Type")}</option>
                {promotionTypeOptions.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </select>
            </div>

            {/* Start Time and End Time */}
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="startDate"
              >
                {t("Start Date")}
              </label>
              <div className="relative">
                <ReactDatePicker
                  id="startDate"
                  selected={startTime}
                  onChange={(date: Date | null) => setStartTime(date)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring pl-10"
                  dateFormat="MM/dd/yyyy"
                  placeholderText={t("Select Start Date")}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="text-gray-400"
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="endDate"
              >
                {t("End Date")}
              </label>
              <div className="relative">
                <ReactDatePicker
                  id="endDate"
                  selected={endTime}
                  onChange={(date: Date | null) => setEndTime(date)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring pl-10"
                  dateFormat="MM/dd/yyyy"
                  placeholderText={t("Select End Date")}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="text-gray-400"
                  />
                </div>
              </div>
            </div>

            {renderDynamicRow(promotionType)}
          </div>
        </div>

        {/* Apply Coupon Code Section */}
        {/* <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">{t("Apply Coupon Code")}</h2>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="applyCoupon"
            checked={applyCoupon}
            onChange={toggleApplyCoupon}
            className="mr-2"
          />
          <label
            htmlFor="applyCoupon"
            className="text-gray-700 text-sm font-bold"
          >
            {t("Would you like to apply a coupon code to this promotion?")}
          </label>
        </div>

        {applyCoupon && (
          <>
            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("Coupon Code")}
              </label>
              <input
                type="text"
                value={couponCode}
                readOnly
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
              />
            </div>

            <div className="px-2 mb-4 w-full">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("Coupon Validity")}
              </label>
              <select
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                value={couponValidity}
                onChange={(e) => setCouponValidity(e.target.value)}
              >
                <option value="">{t("Select Validity")}</option>
                <option value="date">{t("Valid Through a Date")}</option>
                <option value="usage">{t("Valid for a Number of Uses")}</option>
              </select>
            </div>

            {couponValidity === "date" && (
              <div className="px-2 mb-4 w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("Expiration Date")}
                </label>
                <ReactDatePicker
                  selected={couponExpirationDate}
                  onChange={(date) => setCouponExpirationDate(date)}
                  dateFormat="MM/dd/yyyy"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                />
              </div>
            )}

            {couponValidity === "usage" && (
              <div className="px-2 mb-4 w-full">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("Usage Count")}
                </label>
                <input
                  type="number"
                  value={couponUsageCount}
                  onChange={(e) => setCouponUsageCount(e.target.value)}
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  min="1"
                />
              </div>
            )}
          </>
        )}
      </div> */}

        {/* Status Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.status")}
          </h2>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.category.selectstatus")}
            </option>
            {statusOptions}
          </select>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("promotion")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.category.cancelbutton")}
          </button>
          <button
            onClick={handleAddPromotion}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
          >
            {t("back.management.menu.promotion.confirmbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddPromotion;
