import PageLoader from "@/components/back/Spinner";
import { User, useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  City,
  Country,
  ICity,
  ICountry,
  IState,
  State,
} from "country-state-city";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddBranchProps {
  onMenuItemClick: (componentName: string) => void;
}

interface Selection {
  name: string;
  isoCode: string;
}

const AddBranch = ({ onMenuItemClick }: AddBranchProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user, login } = useUser();

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<Selection>({
    name: "",
    isoCode: "",
  });
  const [selectedState, setSelectedState] = useState<Selection>({
    name: "",
    isoCode: "",
  });
  const [selectedCity, setSelectedCity] = useState({ name: "" });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("1");
  const [countries, setCountries] = useState<ICountry[]>(
    Country.getAllCountries()
  );
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  useEffect(() => {
    if (selectedCountry.isoCode) {
      const states = State.getStatesOfCountry(selectedCountry.isoCode);
      setStates(
        states.map((state) => ({
          name: state.name,
          isoCode: state.isoCode,
          countryCode: selectedCountry.isoCode,
        }))
      );
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry.isoCode && selectedState.isoCode) {
      const cities = City.getCitiesOfState(
        selectedCountry.isoCode,
        selectedState.isoCode
      );
      setCities(
        cities.map((city) => ({
          name: city.name,
          stateCode: city.stateCode,
          countryCode: city.countryCode,
        }))
      );
    } else {
      setCities([]);
    }
  }, [selectedCountry, selectedState]);

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);

      // Convert file to Base64
      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setImageBase64(base64Data);
        }
      });
    }
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
  };

  const handleAddBranch = async () => {
    const fullPhoneNumber = `+${selectedPhoneCode}${phone}`;

    const branchData = {
      name,
      user: user?.id,
      email: user?.email,
      phone: fullPhoneNumber,
      address: {
        street,
        city: selectedCity.name,
        latitude: latitude,
        longitude: longitude,
        province: selectedState.name,
        province_code: selectedState.isoCode,
        Country: selectedCountry.name,
        Country_code: selectedCountry.isoCode,
        postal_code: zipCode,
      },
      logo: imageBase64,
    };
    console.log(branchData);
    try {
      if (!loading && axiosInstance) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_BRANCHES;
        if (!apiUrl) {
          console.error("API URL for branches is not defined in .env");
          return;
        }
        await axiosInstance.post(apiUrl, JSON.stringify(branchData), {
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("Branch added successfully");
        const userBranchesResponse = await axiosInstance.get(
          `${apiUrl}?user=${user?.id}`
        );
        const userBranches = userBranchesResponse.data.results;

        // Update the user data in the local storage with the fetched branches
        const storedUserData = localStorage.getItem("user_data");
        if (storedUserData) {
          const userData: User = JSON.parse(storedUserData);
          userData.branches = userBranches;
          localStorage.setItem("user_data", JSON.stringify(userData));
          login(userData);
        }

        setName("");
        setStreet("");
        setLatitude("");
        setLongitude("");
        setImagePreviewUrl("");
        setIsImageUploaded(false);
        setImageBase64("");
        onMenuItemClick("dashboard");
      }
    } catch (error) {
      console.error("Error adding branch:", error);
    }
  };

  const geocodeAddress = async () => {
    const addressString = `${street}, ${selectedCity.name}, ${selectedState.name}, ${selectedCountry.name}`;
    const encodedAddress = encodeURI(addressString);
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyAnVKeSTixBr0zPPECufZ1qoU6dEsfPF5o`
      );
      const { lat, lng } = response.data.results[0].geometry.location;
      setLatitude(lat);
      setLongitude(lng);
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  useEffect(() => {
    if (
      street &&
      selectedCity.name &&
      selectedState.name &&
      selectedCountry.name
    ) {
      geocodeAddress();
    }
  }, [street, selectedCity.name, selectedState.name, selectedCountry.name]);

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        <h1 className="text-xl font-bold">
          {t("back.management.dashboard.branch.addnewbranch")}
        </h1>

        {/* Branch Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold mb-4">
            {t("back.management.dashboard.branch.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="branchName"
              >
                {t("back.management.dashboard.branch.branchname")}
              </label>
              <input
                type="text"
                id="branchName"
                placeholder={t(
                  "back.management.dashboard.branch.branchnameplaceholder"
                )}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>

            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="phone"
              >
                {t("back.management.dashboard.branch.phone")}
              </label>
              <div className="flex items-center">
                <span className="px-3 py-2 border border-gray-300 rounded-md shadow-sm">
                  +{selectedPhoneCode}
                </span>
                <input
                  type="text"
                  id="phone"
                  placeholder={t("Phone Number")}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="flex-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                />
              </div>
            </div>

            {/* Address Section */}
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="street"
              >
                {t("back.management.dashboard.branch.street")}
              </label>
              <input
                type="text"
                id="street"
                placeholder={t(
                  "back.management.dashboard.branch.streetplaceholder"
                )}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>

            {/* Country Selector */}
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="country"
              >
                {t("back.management.dashboard.branch.country")}
              </label>
              <select
                id="country"
                value={selectedCountry.isoCode}
                onChange={(e) => {
                  const selectedIsoCode = e.target.value;
                  const selectedCountry = countries.find(
                    (country) => country.isoCode === selectedIsoCode
                  );
                  setSelectedCountry({
                    name: selectedCountry?.name || "",
                    isoCode: selectedIsoCode,
                  });
                  setSelectedPhoneCode(selectedCountry?.phonecode || "1"); // Default to 1 if not found
                }}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">{t("Select Country")}</option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            {/* State Selector */}
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="state"
              >
                {t("back.management.dashboard.branch.state")}
              </label>
              <select
                id="state"
                value={selectedState.isoCode}
                onChange={(e) =>
                  setSelectedState({
                    name: e.target.options[e.target.selectedIndex].text,
                    isoCode: e.target.value,
                  })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.dashboard.branch.selectstate")}
                </option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>

            {/* City Selector */}
            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="city"
              >
                {t("back.management.dashboard.branch.city")}
              </label>
              <select
                id="city"
                value={selectedCity.name}
                onChange={(e) => setSelectedCity({ name: e.target.value })}
                disabled={!selectedState.isoCode}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.dashboard.branch.selectcity")}
                </option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full md:w-1/2 px-2 mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="zipCode"
              >
                {t("back.management.dashboard.branch.zipCode")}
              </label>
              <input
                type="text"
                id="zipCode"
                placeholder={t(
                  "back.management.dashboard.branch.zipCodeplaceholder"
                )}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
        </div>

        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h1 className="text-lg font-semibold mb-4">
            {t("back.management.dashboard.branch.imageupload")}
          </h1>
          <div className="w-full px-2">
            <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
              {t("back.management.dashboard.branch.uploadimage")}
            </p>
            <div
              className={`block w-full border-2 ${
                isImageUploaded ? "border-solid" : "border-dotted"
              } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
            >
              <input
                id="fileUpload"
                type="file"
                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                onChange={handleFileChange}
              />
              {imagePreviewUrl ? (
                <>
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    className="max-h-full max-w-full p-2"
                  />
                  <button
                    onClick={handleRemoveImage}
                    className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                    style={{ width: "30px", height: "30px" }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </>
              ) : (
                <div className="text-center">
                  <FontAwesomeIcon
                    icon={faImage}
                    size="2x"
                    className="text-gray-400 mb-2"
                  />
                  <p className="text-gray-600 mb-2">
                    {t("back.management.dashboard.branch.uploadimagehint")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("dashboard")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.dashboard.branch.cancelbutton")}
          </button>
          <button
            onClick={handleAddBranch}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.dashboard.branch.confirmbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddBranch;
