import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import WarningBanner from "@/components/ui/banners/WarningBanner";

interface BranchRequiredWrapperProps {
  children: React.ReactNode;
}

const BranchRequiredWrapper: React.FC<BranchRequiredWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!user?.selectedBranch) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [user?.selectedBranch]);

  return (
    <>
      {showBanner && (
        <WarningBanner
          title="Warning"
          text={t("back.shared.noBranchSelected")}
          isSuccess={false}
        />
      )}
      {user?.selectedBranch && children}
    </>
  );
};

export default BranchRequiredWrapper;
