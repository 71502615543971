import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faCircle,
  faImage,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditProductProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  productId: number | null;
}

interface Option {
  id: number;
  name: string;
  description: string;
  surcharge: string;
  branch: number;
  color: string;
  created_at: string;
  image: string;
  is_active: boolean;
  option_list: number | null;
}

interface OptionList {
  id: number;
  name: string;
  options: Option[];
  required: boolean;
  option_type: string;
  max_selections: number | null;
}

interface Product {
  id: number;
  name: string;
  image: string;
  color: string;
  description: string;
  unit_price: string;
  promotion: string;
  stock: number;
  menu_display_name: string;
  category: number | null;
  product_option_lists: any[];
}

const EditProduct = ({
  onMenuItemClick,
  setSuccessMessage,
  productId,
}: EditProductProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [product, setProduct] = useState<Product>({
    id: 0,
    name: "",
    image: "",
    color: "",
    description: "",
    unit_price: "",
    promotion: "",
    stock: 0,
    menu_display_name: "",
    category: null,
    product_option_lists: [],
  });
  const [categories, setCategories] = useState<Product[]>([]);
  const [allOptionLists, setAllOptionLists] = useState<OptionList[]>([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${productId}/`);
          setProduct(response.data);
          setIsImageUploaded(!!response.data.image);
          setImagePreviewUrl(response.data.image);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    const fetchAllCategories = async () => {
      let allCategories: Product[] = [];
      let apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
      apiUrl += `?branch=${user?.selectedBranch?.id}`;

      while (apiUrl) {
        try {
          const response = await axiosInstance.get(apiUrl);
          allCategories = [...allCategories, ...response.data.results];
          apiUrl = response.data.next;
        } catch (error) {
          console.error("Error fetching categories:", error);
          break;
        }
      }

      setCategories(allCategories);
    };

    const fetchAllOptionLists = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
          let allFetchedOptionLists: OptionList[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedOptionLists = [...allFetchedOptionLists, ...results];
            nextUrl = next;
          }

          setAllOptionLists(allFetchedOptionLists);
        }
      } catch (error) {
        console.error("Error fetching option lists:", error);
      }
    };
    if (productId) {
      fetchProduct();
      fetchAllCategories();
      fetchAllOptionLists();
    }
  }, [axiosInstance, loading, productId, user?.selectedBranch]);

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  const handleColorChange = (value: string) => {
    setProduct({ ...product, color: value });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setImageBase64(base64Data);
        }
      });
    }
  };

  const handleUpdateProduct = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";

        const transformedProductOptionLists = product.product_option_lists.map(
          (optionList) => ({
            ...optionList, // retain all details of the option list
            options: optionList.options.map((option: { id: any }) => option.id), // transform options to only include their IDs
          })
        );
        const updatedProduct: any = {
          name: product.name,
          color: product.color,
          description: product.description,
          unit_price: parseFloat(product.unit_price),
          promotion: product.promotion,
          stock: parseInt(product.stock.toString(), 10),
          branch: user.selectedBranch.id,
          category: product.category,
          menu_display_name: product.menu_display_name,
          product_option_lists: transformedProductOptionLists,
        };

        if (isImageUploaded && imageBase64) {
          updatedProduct.image = imageBase64;
        }

        await axiosInstance.put(`${apiUrl}${productId}/`, updatedProduct, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const successMessage = "Product updated successfully";
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("product", successMessage);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      setIsSuccess(false);
      setBannerMessage("Failed to update product");
      setShowBanner(true);
    }
  };

  const handleOptionListChange = (selectedOptionLists: any) => {
    setProduct((prevProduct) => {
      // Convert selectedOptionLists to a set of IDs to handle merging
      const selectedOptionListIds = new Set(
        selectedOptionLists.map((optionList: any) => optionList.id)
      );

      // Merge with existing product_option_lists, ensuring no duplicates
      const updatedOptionLists = [
        ...prevProduct.product_option_lists.filter(
          (optionList: any) => !selectedOptionListIds.has(optionList.id)
        ),
        ...selectedOptionLists,
      ];

      return {
        ...prevProduct,
        product_option_lists: updatedOptionLists,
      };
    });
  };

  const handleRemoveOptionList = (id: number) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_option_lists: prevProduct.product_option_lists.filter(
        (optionList: any) => optionList.id !== id
      ),
    }));
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
    setImageBase64("");
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        {productId ? (
          <>
            <h1 className="text-xl font-bold">
              {t("back.management.menu.product.editproduct")}
            </h1>

            {/* General Information Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.generalinfo")}
              </h2>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="productName"
                  >
                    {t("back.management.menu.product.productname")}
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter product name"
                    value={product.name}
                    onChange={(e) =>
                      setProduct({ ...product, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
                <div className="w-full md:w-1/2 px-2">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="posMenuColor"
                  >
                    {t("back.management.menu.product.poscolor")}
                  </label>
                  <select
                    id="posMenuColor"
                    value={product.color}
                    onChange={(e) => handleColorChange(e.target.value)}
                    className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  >
                    <option value="">
                      {t("back.management.menu.product.selectcolor")}
                    </option>
                    {colorOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {product.color && (
                    <FontAwesomeIcon
                      icon={faCircle}
                      color={product.color}
                      className="mt-2"
                    />
                  )}
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="menuDisplayName"
                >
                  {t("back.management.menu.product.menuDisplayName")}
                </label>
                <input
                  type="text"
                  id="menuDisplayName"
                  placeholder="Enter menu display name"
                  value={product.menu_display_name}
                  onChange={(e) =>
                    setProduct({
                      ...product,
                      menu_display_name: e.target.value,
                    })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                />
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  {t("back.management.menu.product.description")}
                </label>
                <textarea
                  id="description"
                  placeholder="Enter product description"
                  value={product.description}
                  onChange={(e) =>
                    setProduct({ ...product, description: e.target.value })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
                ></textarea>
              </div>
            </div>

            {/* Media Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.media")}
              </h2>
              <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
                {t("back.management.menu.product.uploadimage")}
              </p>
              <div
                className={`block w-full border-2 ${
                  isImageUploaded ? "border-solid" : "border-dotted"
                } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
              >
                <input
                  id="fileUpload"
                  type="file"
                  className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                  onChange={handleFileChange}
                />
                {imagePreviewUrl ? (
                  <>
                    <img
                      src={imagePreviewUrl}
                      alt="Preview"
                      className="max-h-full max-w-full p-2"
                    />
                    <button
                      onClick={handleRemoveImage}
                      className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                      style={{ width: "30px", height: "30px" }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </>
                ) : (
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={faImage}
                      size="2x"
                      className="text-gray-400 mb-2"
                    />
                    <p className="text-gray-600 mb-2">
                      {t("back.management.menu.product.uploadimagehint")}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {/* Pricing Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.pricing")}
              </h2>
              <input
                type="text"
                placeholder="Base Price"
                value={product.unit_price}
                onChange={(e) =>
                  setProduct({ ...product, unit_price: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>

            {/* Inventory Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.inventory")}
              </h2>
              <input
                type="text"
                placeholder="Stock"
                value={product.stock}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    stock: parseInt(e.target.value, 10) || 0,
                  })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>

            {/* Category Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.category")}
              </h2>
              <select
                value={product.category || ""}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    category: e.target.value ? parseInt(e.target.value) : null,
                  })
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.product.selectcategory")}
                </option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Product Option List Section */}
            <div className="mb-4">
              <label
                htmlFor="productOptionLists"
                className="block text-gray-700 font-bold mb-2"
              >
                {t("back.management.menu.product.addoptionLists")}
              </label>

              <select
                id="productOptionLists"
                name="productOptionLists"
                multiple
                value={product.product_option_lists.map((optionList) =>
                  optionList.id.toString()
                )} // Use array of selected IDs
                onChange={(e) => {
                  const selectedOptions = Array.from(
                    e.target.selectedOptions,
                    (option) => {
                      return allOptionLists.find(
                        (opt) => opt.id.toString() === option.value
                      );
                    }
                  ).filter(Boolean);
                  handleOptionListChange(selectedOptions);
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                {allOptionLists.map((optionList) => (
                  <option key={optionList.id} value={optionList.id.toString()}>
                    {optionList.name}
                  </option>
                ))}
              </select>

              <div>
                <h2 className="text-lg font-semibold">
                  {t("back.management.menu.product.option_lists")}
                </h2>
                <div className="mt-2 space-y-4">
                  {allOptionLists.length > 0 &&
                    product.product_option_lists.length > 0 && (
                      <ul>
                        {product.product_option_lists.map((optionList) => (
                          <li
                            key={optionList.id}
                            className="flex items-center mb-2"
                          >
                            <span className="text-gray-700 mr-2">
                              {optionList.name}
                            </span>
                            <button
                              onClick={() =>
                                handleRemoveOptionList(optionList.id)
                              }
                              className="text-red-500 hover:text-red-700 focus:outline-none"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("product")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.product.cancelbutton")}
              </button>
              <button
                onClick={handleUpdateProduct}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                {t("back.management.menu.product.updatebutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.menu.product.noproductselected")}</p>
        )}
      </div>
    </PageLoader>
  );
};

export default EditProduct;
