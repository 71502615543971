import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddOptionListProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

interface OptionList {
  id: number;
  name: string;
}

interface Option {
  id: number;
  name: string;
}

const AddOptionList = ({
  onMenuItemClick,
  setSuccessMessage,
}: AddOptionListProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [optionLists, setOptionLists] = useState<OptionList[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [allOptions, setAllOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [optionType, setOptionType] = useState("multiple_choice");
  const [maxSelections, setMaxSelections] = useState<number | null>(null);

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
    const fetchCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          setOptionLists(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [axiosInstance, loading, user?.selectedBranch]);

  useEffect(() => {
    const fetchAllOptions = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION ?? "";
          let allFetchedOptions: Option[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedOptions = [...allFetchedOptions, ...results];
            nextUrl = next;
          }

          setAllOptions(allFetchedOptions);
          setOptions(allFetchedOptions);
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchAllOptions();
  }, [axiosInstance, loading, user?.selectedBranch]);

  useEffect(() => {
    if (searchQuery) {
      const filteredOptions = allOptions.filter((option) =>
        option.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(allOptions);
    }
  }, [searchQuery, allOptions]);

  const handleRemoveOption = (optionId: number) => {
    setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
  };

  const handleAddOptionList = async () => {
    const selectedBranch = user?.selectedBranch?.id;
    const optionListData = {
      name,
      description,
      branch: selectedBranch,
      options: selectedOptions,
      option_type: optionType,
      required: isRequired,
      max_selections: optionType !== "multiple_choice" ? maxSelections : null,
    };
    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
        const response = await axiosInstance.post(apiUrl, optionListData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 201) {
          const successMessage = t(
            "back.management.menu.optionList.successadd"
          );
          setSuccessMessage(successMessage);
          setIsSuccess(true);
          setBannerMessage(successMessage);
          setShowBanner(true);
          onMenuItemClick("optionList", successMessage);
        }
      }
    } catch (error) {
      console.error("Error adding option list:", error);
      const errorMessage = t("back.management.menu.optionList.erroradd");
      setBannerMessage(errorMessage);
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleOptionChange = (selectedOptionId: number) => {
    if (selectedOptions.includes(selectedOptionId)) {
      setSelectedOptions(
        selectedOptions.filter((id) => id !== selectedOptionId)
      );
    } else {
      setSelectedOptions([...selectedOptions, selectedOptionId]);
    }
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.optionList.addNewOptionList")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.optionList.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-full px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="categoryName"
              >
                {t("back.management.menu.optionList.optionListName")}
              </label>
              <input
                type="text"
                id="itemName"
                placeholder={t(
                  "back.management.menu.optionList.enterOptionListName"
                )}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.optionList.description")}
            </label>
            <textarea
              id="description"
              placeholder={t(
                "back.management.menu.optionList.enterDescription"
              )}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            ></textarea>
          </div>
          <div className="my-4 bg-white p-4 shadow rounded-lg">
            <h2 className="text-lg font-semibold">
              {t("back.management.menu.option.optionType")}
            </h2>
            <select
              id="optionType"
              value={optionType}
              onChange={(e) => setOptionType(e.target.value)}
              className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            >
              <option value="multiple_choice">
                {t("back.management.menu.option.multipleChoice")}
              </option>
              <option value="checkbox">
                {t("back.management.menu.option.checkbox")}
              </option>
              <option value="increment_decrement">
                {t("back.management.menu.option.increment_decrement")}
              </option>
            </select>
          </div>
          {optionType !== "multiple_choice" && (
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="maxSelections"
              >
                {t("back.management.menu.optionList.maxSelections")}
              </label>
              <input
                type="number"
                id="maxSelections"
                placeholder={t(
                  "back.management.menu.optionList.enterMaxSelections"
                )}
                value={maxSelections ?? ""}
                onChange={(e) => setMaxSelections(parseInt(e.target.value))}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          )}
          <div className="my-4 bg-white p-4 shadow rounded-lg">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              <input
                type="checkbox"
                checked={isRequired}
                onChange={(e) => setIsRequired(e.target.checked)}
                className="mr-2 leading-tight"
              />
              {t("back.management.menu.optionList.isRequired")}
            </label>
          </div>
        </div>

        {/* Add Options Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.optionList.addOptions")}
          </h2>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="searchOptions"
            >
              {t("back.management.menu.optionList.searchOptions")}
            </label>
            <input
              type="text"
              id="searchOptions"
              placeholder={t(
                "back.management.menu.optionList.enterSearchQuery"
              )}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            />
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="options"
            >
              {t("back.management.menu.optionList.selectOptions")}
            </label>
            <select
              id="options"
              multiple
              value={selectedOptions.map(String)}
              onChange={(e) => handleOptionChange(parseInt(e.target.value, 10))}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            >
              {options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <p className="text-gray-700 text-sm font-bold mb-2">
              {t("back.management.menu.optionList.selectedOptions")}
            </p>
            <ul>
              {allOptions
                .filter((option) => selectedOptions.includes(option.id))
                .map((option) => (
                  <li key={option.id} className="flex items-center mb-2">
                    <span className="text-gray-700 mr-2">{option.name}</span>
                    <button
                      onClick={() => handleRemoveOption(option.id)}
                      className="text-red-500 hover:text-red-700 focus:outline-none"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("category")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.category.cancelbutton")}
          </button>
          <button
            onClick={handleAddOptionList}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.optionList.confirmbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddOptionList;
