import React from "react";
import Navbar from "@/views/back/management/Navbar";
import Sidebar from "@/views/back/management/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

interface ManagementLayoutProps {
  children: React.ReactNode;
  onMenuItemClick: (componentName: string) => void;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const ManagementLayout: React.FC<ManagementLayoutProps> = ({
  children,
  onMenuItemClick,
  isSidebarOpen,
  toggleSidebar,
}) => {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        onMenuItemClick={onMenuItemClick}
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
      />
      <div
        className={`flex-1 flex flex-col overflow-hidden transition-transform duration-300 transform md:ml-64 ${
          isSidebarOpen ? "translate-x-64 md:translate-x-0" : "translate-x-0"
        }`}
        style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <Navbar onMenuItemClick={onMenuItemClick} />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <section className="p-4">{children}</section>
        </main>
      </div>
      <button
        className="fixed top-4 left-4 z-50 md:hidden"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} size="2x" />
      </button>
    </div>
  );
};

export default ManagementLayout;
