import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoginRegister from "@/assets/img/loginRegister.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LogoLight from "@/assets/img/logo-light.png";
import { Link, useNavigate } from "react-router-dom";
import axiosInstanceNoAuth from "@/utils/axiosConfigNoAuth";
import { Country } from "country-state-city";

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const priorityCountryCodes = ["US", "CN"];
  const allCountries = Country.getAllCountries();

  const filteredCountries = allCountries.filter((country) =>
    priorityCountryCodes.includes(country.isoCode)
  );
  const otherCountries = allCountries.filter(
    (country) => !priorityCountryCodes.includes(country.isoCode)
  );
  const sortedCountries = [...filteredCountries, ...otherCountries];

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const roleOptions = [
    { label: "Owner", value: "Owner" },
    { label: "Manager", value: "Manager" },
    { label: "Employee", value: "Employee" },
    { label: "Customer", value: "Customer" },
  ];

  const apiUrl = process.env.REACT_APP_REDBIRDPOSEBE_REGISTER ?? "";
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fullPhoneNumber = `+${selectedCountryCode}${phoneNumber}`;

    const payload = {
      username,
      email,
      password1: password,
      password2: confirmpassword,
      first_name: firstName,
      last_name: lastName,
      group_of_user: selectedRole,
      phone: fullPhoneNumber,
    };

    try {
      const response = await axiosInstanceNoAuth.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        navigate("/login");
      } else {
        alert("Failed to register. Please check your information.");
      }
    } catch (error) {
      const axiosError = error as any;
      console.error("Error registering", axiosError.response?.data);
      const errorMessage =
        axiosError.response?.data?.error ||
        "An error occurred. Please try again later.";
      alert(errorMessage);
    }
  };

  return (
    <div className="min-h-screen flex overflow-hidden">
      <Link to="/" className="absolute top-0 left-0 p-4">
        <img src={LogoLight} alt="Home" className="h-12 w-30" />
      </Link>
      <div className="flex-1 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-20">
          <div>
            <h2 className="mt-6 text-center text-5xl text-gray-900 mb-8">
              {t("account.login.welcome")}
            </h2>
            <div className="text-center text-3xl font-extrabold ">
              <span className="text-black">
                {t("account.register.signup_to")}&nbsp;
              </span>
              <span className="text-red-600">
                {t("account.login.redtitle")}
              </span>
              <span className="text-black">
                {t("account.login.service_name")}
              </span>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" value="true" />
            <div>
              <label
                htmlFor="first_name"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.firstname_label")}
              </label>
              <input
                id="first_name"
                name="first_name"
                type="text"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.register.firstname_placeholder")}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.lastname_label")}
              </label>
              <input
                id="last_name"
                name="last_name"
                type="text"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.register.lastname_placeholder")}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="username"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.username_label")}{" "}
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.register.username_placeholder")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.email_label")}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.register.email_placeholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <div>
                <label
                  htmlFor="phone_number"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("account.register.phone_label")}
                </label>
                <div className="flex">
                  <select
                    id="country_code"
                    name="country_code"
                    className="appearance-none rounded-l-md block px-2 py-2 border border-r-0 border-gray-300 bg-white text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm w-20"
                    value={selectedCountryCode}
                    onChange={(e) => setSelectedCountryCode(e.target.value)}
                  >
                    {sortedCountries.map((country) => (
                      <option key={country.isoCode} value={country.phonecode}>
                        {country.flag} +{country.phonecode}
                      </option>
                    ))}
                  </select>
                  <input
                    id="phone_number"
                    name="phone_number"
                    type="tel"
                    required
                    className="appearance-none rounded-r-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                    placeholder={t("account.register.phone_placeholder")}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="group_of_user"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.role_label")}
              </label>
              <select
                id="group_of_user"
                name="group_of_user"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              >
                <option value="">{t("account.register.selectrole")}</option>
                {roleOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {/* <div>
              <label
                htmlFor="company_name"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.company_label")}
              </label>
              <input
                id="company_name"
                name="company_name"
                type="text"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.register.company_placeholder")}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div> */}
            <div className="relative">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.password_label")}
              </label>
              <div className="flex items-center border border-gray-300 rounded-md">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="appearance-none rounded-l-md block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder={t("account.register.password_placeholder")}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Password must be at least 8 characters long and include at least one number, one uppercase and one lowercase letter."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="px-3"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
            <div className="relative">
              <label
                htmlFor="confirm_password"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.register.confirm_password_label")}
              </label>
              <div className="flex items-center border border-gray-300 rounded-md">
                <input
                  id="confirm_password"
                  name="confirm_password"
                  type={showPassword ? "text" : "password"}
                  required
                  className="appearance-none rounded-l-md block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder={t(
                    "account.register.confirm_password_placeholder"
                  )}
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="px-3"
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
              {passwordError && (
                <p className="text-red-500 text-xs italic">{passwordError}</p>
              )}
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {t("account.register.signup_button")}
              </button>
            </div>
          </form>
          <div className="text-sm text-center font-medium">
            {t("account.register.yes_account")}{" "}
            <Link to="/login" className="font-medium text-red-600 ml-2">
              {t("account.register.login")}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-1/2 relative">
        <div className="absolute top-0 right-0 bottom-0 w-3/5 bg-red-600"></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <img
            src={LoginRegister}
            className="object-contain"
            style={{ maxHeight: "80%" }}
            alt="Login Visual"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
