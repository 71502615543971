import ResetPasswordImg from "@/assets/img/loginRegister.png";
import LogoLight from "@/assets/img/logo-light.png";
import Spinner from "@/components/front/Loader/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import axiosInstanceNoAuth from "@/utils/axiosConfigNoAuth";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // Initialize any needed services or listeners here
  }, []);

  const handleResetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!navigator.onLine) {
      setShowBanner(true);
      setAnimateOut(false);
      setTimeout(() => setAnimateOut(true), 3000);
      setIsLoading(false);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_RESET_PASSWORD ?? "";
      const response = await axiosInstanceNoAuth.post(apiUrl, { email });

      if (response.data) {
        setIsSuccess(true);
      }
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 500) {
          setShowBanner(true);
        } else {
          console.error("Reset password error", axiosError);
        }
      } else {
        console.error("Reset password error", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  return (
    <div className="min-h-screen flex overflow-hidden">
      <Link to="/" className="absolute top-0 left-0 p-4">
        <img src={LogoLight} alt="Home" className="h-12 w-30" />
      </Link>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
      <div className="flex-1 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-20">
          <div>
            {showBanner && (
              <WarningBanner
                title={t("account.reset.invalidEmail")}
                text={t("account.reset.invalidEmailMessage")}
                className={`${
                  animateOut ? "animate-slideOutRight" : "animate-slideDown"
                }`}
              />
            )}
            {isSuccess && (
              <div
                className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">
                  {t("account.reset.success")}
                </strong>
                <span className="block sm:inline">
                  {t("account.reset.successMessage")}
                </span>
              </div>
            )}
            <h2 className="mt-6 text-center text-3xl font-bold md:text-5xl text-gray-900 mb-8">
              {t("account.reset.welcome")}
            </h2>
            <div className="text-center text-2xl md:text-3xl font-extrabold">
              <span className="text-blue-500">
                {t("account.reset.headline")}&nbsp;
              </span>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleResetPassword}>
            <div>
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.reset.email_label")}{" "}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.reset.email_placeholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {t("account.reset.reset_button")}
              </button>
            </div>
          </form>
          <div className="text-sm text-center font-medium">
            {t("account.reset.remembered")}{" "}
            <Link to="/login" className="font-medium text-red-600 ml-2">
              {t("account.reset.login")}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-1/2 relative hidden lg:block">
        <div className="absolute top-0 right-0 bottom-0 w-3/5 bg-red-600"></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <img
            src={ResetPasswordImg}
            className="object-contain"
            style={{ maxHeight: "80%" }}
            alt="Reset Password Visual"
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
