import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useUser, User } from "@/contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface TitleProps {
  onMenuItemClick: (componentName: string) => void;
  isSidebarOpen: boolean;
}

const Title: React.FC<TitleProps> = ({ onMenuItemClick, isSidebarOpen }) => {
  const { t } = useTranslation();
  const { user, login } = useUser();
  const [selectedBranch, setSelectedBranch] = useState<any>(null);

  useEffect(() => {
    setSelectedBranch(user?.selectedBranch || null);
  }, [user]);

  const handleDeselectBranch = () => {
    if (user) {
      const updatedUserData: User = {
        ...user,
        selectedBranch: null,
      };
      login(updatedUserData);
    }
  };

  return (
    <div
      className="mb-8"
      style={{
        transition: "transform 0.3s",
        transform: isSidebarOpen ? "translateX(64px)" : "translateX(0)",
      }}
    >
      <div className="flex justify-between items-center mb-4">
        {selectedBranch && (
          <button
            className="flex items-center px-2 py-1 sm:px-4 sm:py-2 border-2 border-red-500 text-red-500 rounded-lg text-xs sm:text-base"
            onClick={handleDeselectBranch}
          >
            <span className="mr-1 sm:mr-2">{selectedBranch.name}</span>
            <span className="flex items-center justify-center w-4 h-4 sm:w-5 sm:h-5 bg-red-500 text-white rounded-full">
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </button>
        )}
        <button
          onClick={() => onMenuItemClick("addBranch")}
          className="bg-red-500 text-white font-bold py-1 px-2 sm:py-2 sm:px-4 rounded-full text-xs sm:text-base"
        >
          {t("back.management.dashboard.branch.addnewbranch")}
        </button>
      </div>
      <div className="flex justify-between items-center">
        <h1 className="text-2xl lg:text-4xl font-bold text-gray-800">
          {`${t("back.management.dashboard.title")} ${user?.first_name} ${
            user?.last_name
          }`}
        </h1>
      </div>
      <p className="text-lg lg:text-xl text-gray-600">
        {t("back.management.dashboard.subtitle")}
      </p>
    </div>
  );
};

export default Title;
