import React, { useState, useEffect } from "react";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import BranchOperatingHoursInput from "@/components/ui/selectors/BranchOperatingHoursInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
  branch: number;
}

interface Branch {
  id: number;
  name: string;
  phone: string;
  email: string;
  delivery_cost?: number;
  base_distance?: number;
  delivery_cost_per_additional_mile?: number;
  max_distance?: number;
  delivery_feature: boolean;
  operating_hours: OperatingHour[];
}

const BusinessInformation = () => {
  const { user } = useUser();
  const [axiosInstance] = useAxiosInstance();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Branch>({} as Branch);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteHourId, setDeleteHourId] = useState<number | null>(null);

  const fetchBranchesByBusinessName = async (businessName: string) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/?business_name=${businessName}`;
      const response = await axiosInstance.get(apiUrl);
      setBranches(response.data.results);
      const curBranch = response.data.results.find(
        (branch: Branch) => branch.id === user?.selectedBranch?.id
      );
      setSelectedBranch(curBranch);
      if (curBranch) {
        setOperatingHours(curBranch.operating_hours);
      }
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("user_data");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const businessName = userData?.owner?.business_name;

    if (businessName) {
      fetchBranchesByBusinessName(businessName);
    }
  }, [axiosInstance]);

  const handleDeleteOperatingHour = async () => {
    if (deleteHourId === null) return;

    try {
      const apiUrl = `${process.env.REACT_APP_REDBIRDPOSBE_BRANCH_OPERATING_HOURS}${deleteHourId}/`;
      await axiosInstance.delete(apiUrl);
      setOperatingHours((prevHours) =>
        prevHours.filter((hour) => hour.id !== deleteHourId)
      );
      setShowDeleteConfirmation(false);
      setDeleteHourId(null);
    } catch (error) {
      console.error("Error deleting operating hour:", error);
    }
  };

  const saveOperatingHours = async (newHours: OperatingHour[]) => {
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_BRANCH_OPERATING_HOURS ?? "";
      for (const hour of newHours) {
        await axiosInstance.post(apiUrl, hour);
      }
      const storedUserData = localStorage.getItem("user_data");
      const userData = storedUserData ? JSON.parse(storedUserData) : null;
      const businessName = userData?.owner?.business_name;
      if (businessName) {
        fetchBranchesByBusinessName(businessName);
      }
    } catch (error) {
      console.error("Error adding operating hours:", error);
    }
  };

  const handleDeliveryFeatureChange = async (
    branchId: number,
    value: boolean
  ) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${branchId}/?business_name=${user?.owner?.business_name}`;
      await axiosInstance.put(apiUrl, {
        user: user?.id,
        name: selectedBranch?.name,
        delivery_feature: value,
        phone: selectedBranch?.phone,
        email: selectedBranch?.email,
        delivery_cost: selectedBranch?.delivery_cost,
        base_distance: selectedBranch?.base_distance,
        delivery_cost_per_additional_mile:
          selectedBranch?.delivery_cost_per_additional_mile,
        max_distance: selectedBranch?.max_distance,
      });
      const storedUserData = localStorage.getItem("user_data");
      const userData = storedUserData ? JSON.parse(storedUserData) : null;
      const businessName = userData?.owner?.business_name;
      if (businessName) {
        fetchBranchesByBusinessName(businessName);
      }
    } catch (error) {
      console.error("Error updating delivery feature:", error);
    }
  };

  return (
    <div>
      {/* Branch's Information Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Branch's Information</h3>
        <div className="grid sm:grid-cols-1 md:grid-cols-2">
          <p>
            <strong>Name:</strong> {user?.first_name} {user?.last_name}
          </p>
          <p>
            <strong>Email:</strong> {user?.email}
          </p>
          <p className="order-last md:order-none">
            <strong>Delivery Option:</strong>{" "}
            <input
              type="checkbox"
              checked={
                branches.find(
                  (branch) => branch.id === user?.selectedBranch?.id
                )?.delivery_feature
              }
              onChange={(e) =>
                handleDeliveryFeatureChange(
                  user?.selectedBranch?.id ?? 0,
                  e.target.checked
                )
              }
            />
          </p>
        </div>
      </div>

      {/* Branch Operating Hours Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <BranchOperatingHoursInput
          operatingHours={operatingHours}
          setOperatingHours={setOperatingHours}
          branchId={selectedBranch?.id || 0}
        />
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this operating hour?</p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteOperatingHour}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BusinessInformation;
