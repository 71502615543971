import React, { useState, CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface OrderItem {
  id: number;
  itemName: string;
  category: string;
  price: string;
  stock: number;
  status: string;
  addedDate: string;
  photo: string;
}

const OrderTitle: React.FC = () => {
  const { t } = useTranslation();
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],

      ...orderItems.map((item) => [
        item.id,
        item.itemName,
        item.stock,
        item.price,
        item.category,
        item.addedDate,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "order.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="flex justify-between items-center mb-4">
      <h1 className="text-3xl font-bold">{t("back.management.order.title")}</h1>
      <div>
        <button
          onClick={downloadCSV}
          className="mr-2 px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          {t("back.management.order.export")}
        </button>
      </div>
    </div>
  );
};

export default OrderTitle;
