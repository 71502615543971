import PageLoader from "@/components/back/Spinner";
import { User, useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faCodeBranch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Branch {
  id: number;
  name: string;
  is_open: boolean;
}

interface BranchProps {
  onMenuItemClick: (componentName: string) => void;
}

export default function BranchComponent({ onMenuItemClick }: BranchProps) {
  const { t } = useTranslation();
  const { user, login } = useUser();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Branch | null>();
  const [branchDetails, setBranchDetails] = useState<Branch | null>(null);
  const [axiosInstance, loading, isFetching] = useAxiosInstance();

  const fetchBranchesByBusinessName = async (businessName: string) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/?business_name=${businessName}`;
      const response = await axiosInstance.get(apiUrl);
      setBranches(response.data.results);
      setSelectedBranch(
        response.data.results.find(
          (branch: Branch) => branch.id === user?.selectedBranch?.id
        )
      );
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem("user_data");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const businessName = userData?.owner?.business_name;

    if (businessName) {
      fetchBranchesByBusinessName(businessName);
    }
  }, [axiosInstance]);

  useEffect(() => {
    if (branches.length === 1 && !selectedBranch) {
      handleSelectBranch(branches[0]);
    }
  }, [user?.selectedBranch, branches]);

  useEffect(() => {
    // This effect will run whenever the user context changes.
    if (user && user.branches.length === 1) {
      handleSelectBranch(user.branches[0]);
    } else {
      setSelectedBranch(user?.selectedBranch || null);
    }
  }, [user]);

  const handleSelectBranch = (branch: Branch) => {
    setSelectedBranch(branch);

    const storedUserData = localStorage.getItem("user_data");
    if (storedUserData) {
      const userData: User = JSON.parse(storedUserData);
      userData.selectedBranch = branch;
      localStorage.setItem("user_data", JSON.stringify(userData));
      login(userData);
    }
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto p-4 mt-5">
        <div
          className="flex flex-col items-center justify-center bg-white p-4 shadow rounded-lg w-full mx-auto h-auto my-8"
          style={{ width: "100%", minHeight: "50vh" }}
        >
          {branches.length > 0 ? (
            <>
              <FontAwesomeIcon
                icon={faCodeBranch}
                className="text-red-500 text-3xl mb-4"
              />
              <h2 className="text-xl font-semibold mb-4">
                {t("back.management.dashboard.branch.selectbranch")}
              </h2>
              <select
                value={selectedBranch?.id || ""}
                onChange={(e) => {
                  const branchId = Number(e.target.value);
                  const branch = branches.find(
                    (b: Branch) => b.id === branchId
                  );
                  if (branch) {
                    handleSelectBranch(branch);
                  }
                }}
                className="mt-1 w-3/4 md:w-1/5 rounded-md border-gray-300 text-gray-600"
              >
                <option value="">
                  {t("back.management.dashboard.branch.selectbranchbutton")}
                </option>
                {branches.map((branch: Branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </>
          ) : (
            <div className="flex flex-col items-center">
              <FontAwesomeIcon
                icon={faPlus}
                className="text-red-500 text-3xl mb-4"
              />
              <h2 className="text-xl font-semibold mb-4">
                {t("back.management.dashboard.branch.nobranch")}
              </h2>
              <button
                onClick={() => onMenuItemClick("addBranch")}
                className="bg-red-500 text-white font-bold py-2 px-4 rounded-full"
              >
                {t("back.management.dashboard.branch.addbranch")}
              </button>
            </div>
          )}
          {branchDetails && (
            <div className="p-4 border border-gray-200 rounded-lg mt-4">
              <h3 className="text-lg font-semibold">
                {t("back.management.dashboard.branch.branchdetail")}
              </h3>
              <p>{branchDetails.name}</p>
            </div>
          )}
        </div>
      </div>
    </PageLoader>
  );
}
