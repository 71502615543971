import React, { useState, useEffect, CSSProperties } from "react";
import cafebakeryHeroImage from "@/assets/img/cafebakeryHero.png";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

const useResponsiveStyles = (): Record<string, CSSProperties> => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    container: {
      maxWidth: "1400px",
      margin: "auto",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      paddingTop: isMobile ? "150px" : "200px",
      flexDirection: isMobile ? "column" : "row",
    },
    content: {
      width: isMobile ? "100%" : "50%",
      textAlign: isMobile ? "center" : "left",
      display: "flex",
      flexDirection: "column",
      justifyContent: isMobile ? "flex-start" : "center",
      paddingLeft: "40px",
    },
    h1: {
      fontSize: isMobile ? "1.5em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
      maxWidth: "90%",
    },
    highlight: {
      color: "#e60000",
    },
    p: {
      fontSize: isMobile ? "1em" : "1.2em",
      color: "#555",
      lineHeight: "1.6",
      marginBottom: "30px",
      maxWidth: "80%",
    },
    button: {
      backgroundColor: "#e60000",
      color: "white",
      border: "none",
      padding: "10px 20px",
      cursor: "pointer",
      fontSize: "1em",
      fontWeight: "bold",
      borderRadius: "4px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      margin: "4px 2px",
      transitionDuration: "0.4s",
    },
    secondaryButton: {
      backgroundColor: "white",
      color: "#e60000",
      border: "2px solid #e60000",
      padding: "8px 16px",
      cursor: "pointer",
      fontSize: "1em",
      fontWeight: "bold",
      borderRadius: "4px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      margin: "4px 2px",
      transitionDuration: "0.4s",
    },
    imageContainer: {
      width: isMobile ? "100%" : "50%",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      marginRight: "0.5em",
    },
    image: {
      maxWidth: isMobile ? "60%" : "100%",
      height: "auto",
    },
  };

  return styles;
};

const CafeBakeryHero = () => {
  const { t } = useTranslation();
  const styles = useResponsiveStyles();

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.h1}>{t("front.business.cafebakery.title")}</h1>
        <p style={styles.p}>{t("front.business.cafebakery.description")}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Link
            to="/contact"
            style={{ textDecoration: "none", marginRight: "10px" }}
          >
            <button style={styles.button}>
              {t("front.business.cafebakery.button")}
            </button>
          </Link>
          <Link to="/pricing" style={{ textDecoration: "none" }}>
            <button style={styles.secondaryButton}>
              {t("front.business.cafebakery.button2")}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "8px" }}
              />
            </button>
          </Link>
        </div>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={cafebakeryHeroImage}
          alt={t("front.business.cafebakery.alt")}
          style={styles.image}
        />
      </div>
    </div>
  );
};

export default CafeBakeryHero;
