import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import Footer from "@/components/front/Footer";

const Terms: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <HelmetProvider>
        <Helmet>
          <title>{"Privacy & Policy"} | Redbird Corporation</title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <div className="container mx-auto p-4 mt-40 mb-40">
        <h1 className="text-4xl font-bold mb-4">
          Terms and Conditions of Redbird Corporation
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p>
            Welcome to Redbird Corporation. By using our website and services,
            you agree to comply with and be bound by the following terms and
            conditions. Please review these terms carefully. If you do not agree
            to these terms, you should not use this site or our services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">2. Use of the Site</h2>
          <p>
            You agree to use our website only for lawful purposes and in a way
            that does not infringe the rights of, restrict, or inhibit anyone
            else's use and enjoyment of the site. Prohibited behavior includes
            harassing or causing distress or inconvenience to any other user,
            transmitting obscene or offensive content, or disrupting the normal
            flow of dialogue within our site.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            3. Intellectual Property
          </h2>
          <p>
            All content included on this site, such as text, graphics, logos,
            images, and software, is the property of Redbird Corporation or its
            content suppliers and is protected by international copyright laws.
            The compilation of all content on this site is the exclusive
            property of Redbird Corporation.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            4. Product Information
          </h2>
          <p>
            While we strive to ensure that all information on our website is
            accurate, errors may occasionally occur. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update information at any time without prior notice. This may
            include changes to prices, descriptions, and availability.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            5. Limitation of Liability
          </h2>
          <p>
            Redbird Corporation shall not be liable for any direct, indirect,
            incidental, special, or consequential damages that result from the
            use or the inability to use our website or services, including but
            not limited to reliance by a user on any information obtained from
            Redbird Corporation, or that result from mistakes, omissions,
            interruptions, deletion of files or emails, errors, defects,
            viruses, delays in operation or transmission, or any failure of
            performance, whether or not resulting from acts of God,
            communications failure, theft, destruction, or unauthorized access
            to Redbird Corporation's records, programs, or services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of [Your Country/State], and you
            irrevocably submit to the exclusive jurisdiction of the courts in
            that location.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            7. Changes to These Terms
          </h2>
          <p>
            We reserve the right to modify these terms and conditions at any
            time. Any changes will be posted on this page, and it is your
            responsibility to review these terms periodically. Your continued
            use of the site following the posting of changes will mean that you
            accept and agree to the changes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
          <p>
            If you have any questions about these terms and conditions, please
            contact us at:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>Email: redbirdcorptx@gmail.com</li>
          </ul>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
