import contactPhoto from "@/assets/img/contactphoto.png";
import PageLoader from "@/components/back/Spinner";
import Footer from "@/components/front/Footer";
import Header from "@/components/front/Header";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  organization: string;
  message_body: string;
  phone: string;
}

interface ContactProps {
  stickyFooter?: boolean;
  isStandalone?: boolean;
}

export default function Contact({
  stickyFooter = false,
  isStandalone = false,
}: ContactProps) {
  const { t } = useTranslation();

  const containerClass = stickyFooter ? "flex flex-col min-h-screen" : "";
  const contentClass = stickyFooter ? "flex-grow" : "";
  const marginTopClass = isStandalone ? "mt-40" : "";

  const [formData, setFormData] = useState<FormData>({
    first_name: "",
    last_name: "",
    email: "",
    organization: "",
    message_body: "",
    phone: "",
  });

  const [actionURL, setActionURL] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | number;
    if (submitSuccess) {
      setAnimateOut(false);
      timer = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setSubmitSuccess(false), 500);
      }, 3000);
    }
    return () => clearTimeout(timer as unknown as number);
  }, [submitSuccess]);

  useEffect(() => {
    setActionURL(process.env.REACT_APP_INTEGRATIONS_CONTACT_FORMSPREE ?? "");
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!loading && axiosInstance) {
        const response = await axiosInstance.post(actionURL, formData);
        if (response.status === 200) {
          setSuccessMessage("Email sent out successfully!");
          setSubmitSuccess(true);
          toast.success("Thank you for your message!");
        } else {
          toast.error("There was an error sending your message.");
        }
      }
    } catch (error) {
      console.error("There was an error sending the message", error);
      toast.error("There was an error sending your message.");
    }
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className={containerClass}>
        <Header />
        <div className={contentClass}>
          <div
            className={`${contentClass} ${marginTopClass} bg-white dark:bg-gray-900 pt-0`}
          >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="md:flex md:items-start md:justify-between">
                <div className="md:w-1/2 flex flex-col relative mt-16 mb-4 md:mb-20">
                  <div>
                    <h2 className="text-3xl font-extrabold tracking-tight text-gray-800 dark:text-slate-200 sm:text-4xl">
                      {t("front.contact.title")}
                    </h2>
                    <p className="mt-4 text-lg leading-6 text-gray-500">
                      {t("front.contact.headline")}
                    </p>
                  </div>
                  <div className="mt-6 hidden lg:block">
                    <img
                      src={contactPhoto}
                      alt="Contact us"
                      className="w-128 h-128 object-cover mx-auto"
                    />
                  </div>
                </div>

                <div className="relative md:w-1/2 pt-4 sm:py-2 w-full">
                  {submitSuccess && (
                    <WarningBanner
                      title="Success"
                      text={successMessage}
                      isSuccess={true}
                      className={`${
                        animateOut
                          ? "animate-slideOutRight"
                          : "animate-slideDown"
                      }`}
                    />
                  )}
                  <div className="mt-12">
                    {!actionURL && (
                      <WarningBanner
                        title={t("shared.notSet")}
                        text={t("front.contact.setup")}
                        className={`${
                          animateOut
                            ? "animate-slideOutRight"
                            : "animate-slideDown"
                        }`}
                      />
                    )}
                    <form
                      onSubmit={handleSubmit}
                      action={actionURL}
                      method="POST"
                      className="mt-9 mb-10 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                    >
                      <div>
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.contact.firstName")}
                        </label>
                        <div className="mt-1">
                          <input
                            required
                            value={formData.first_name}
                            onChange={handleInputChange}
                            type="text"
                            name="first_name"
                            id="first_name"
                            autoComplete="given-name"
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.contact.lastName")}
                        </label>
                        <div className="mt-1">
                          <input
                            value={formData.last_name}
                            onChange={handleInputChange}
                            type="text"
                            name="last_name"
                            id="last_name"
                            autoComplete="family-name"
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.contact.email")}
                        </label>
                        <div className="mt-1">
                          <input
                            required
                            value={formData.email}
                            onChange={handleInputChange}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                        >
                          {t("front.contact.phone")}
                        </label>
                        <div className="mt-1">
                          <input
                            required
                            value={formData.phone}
                            onChange={handleInputChange}
                            type="tel"
                            name="phone"
                            id="phone"
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                          >
                            {t("front.contact.organization")}
                          </label>
                          <span
                            id="company_description"
                            className="text-sm text-gray-500"
                          >
                            {t("shared.optional")}
                          </span>
                        </div>
                        <div className="mt-1">
                          <input
                            value={formData.organization}
                            onChange={handleInputChange}
                            type="text"
                            name="organization"
                            id="company"
                            autoComplete="organization"
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="comments"
                            className="block text-sm font-medium text-gray-900 dark:text-slate-300"
                          >
                            {t("front.contact.comments")}
                          </label>
                        </div>
                        <div className="mt-1">
                          <textarea
                            required
                            value={formData.message_body}
                            onChange={handleInputChange}
                            id="comments"
                            name="message_body"
                            aria-describedby="comments_description"
                            rows={4}
                            className="bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-slate-200 appearance-none rounded-none relative block w-full px-3 py-2 border-gray-300 dark:border-gray-600 placeholder-gray-500 rounded-b-sm focus:outline-none focus:ring-theme-300 focus:border-theme-300 focus:z-10 sm:text-sm"
                          ></textarea>
                        </div>
                      </div>

                      <div className="text-right sm:col-span-2">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 w-full"
                        >
                          {t("front.contact.send")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </PageLoader>
  );
}
