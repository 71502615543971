import DateFilterButton from "@/components/back/DateFilterButton";
import SearchInput from "@/components/back/SearchInput";
import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/back/PageHeader";
import DataTable, { Column } from "@/components/back/DataTable";
import DeleteConfirmationDialog from "@/components/back/DeleteConfirmationDialog";

interface OptionListItem {
  id: number;
  name: string;
  created_at: string;
}

interface SortState {
  field: keyof OptionListItem;
  direction: "asc" | "desc";
}

interface OptionListProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number
  ) => void;
  successMessage: string;
}

export default function OptionList({
  onMenuItemClick,
  successMessage,
}: OptionListProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [optionListItems, setOptionListItems] = useState<OptionListItem[]>([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    direction: "asc",
  });

  const columns: Column<OptionListItem>[] = [
    {
      key: "name",
      header: t("back.management.menu.optionList.table.name"),
      sortable: true,
      bold: true,
    },
    {
      key: "created_at",
      header: t("back.management.menu.optionList.table.date"),
      sortable: true,
    },
    {
      key: "action",
      header: t("back.management.menu.optionList.table.action"),
    },
  ];

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Date"],

      ...optionListItems.map((item) => [item.id, item.name, item.created_at]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "optionList.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleFilterStartDateChange = (date: Date | null) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDateChange = (date: Date | null) => {
    setFilterEndDate(date);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const editItem = (id: number) => {
    onMenuItemClick("editOptionList", "", id);
  };

  const openDeleteConfirmation = (id: number) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
          await axiosInstance.delete(`${apiUrl}${itemToDelete}`);
          fetchOptionListItems();
          setBannerMessage(t("back.management.menu.optionList.successdelete"));
          setIsSuccess(true);
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error deleting menu item:", error);
        setBannerMessage(t("back.management.menu.optionList.faileddelete"));
        setIsSuccess(false);
        setShowBanner(true);
      }
      closeDeleteConfirmation();
    }
  };

  const fetchOptionListItems = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
        let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;
        if (searchQuery) {
          url += `&name=${encodeURIComponent(searchQuery)}`;
        }
        if (filterStartDate) {
          const formattedStartDate = filterStartDate.toISOString();
          url += `&created_at__gt=${formattedStartDate}`;
        }
        if (filterEndDate) {
          const formattedEndDate = filterEndDate.toISOString();
          url += `&created_at__lt=${formattedEndDate}`;
        }
        const response = await axiosInstance.get(url);
        let fetchedItems = response.data.results.map((item: OptionListItem) => {
          // Convert and format the 'created_at' date
          const zonedDate = utcToZonedTime(item.created_at, "America/Chicago");
          const formattedDate = format(zonedDate, "MM/dd/yyyy, HH:mm");
          return { ...item, created_at: formattedDate };
        });

        // Sorting logic
        fetchedItems.sort((a: OptionListItem, b: OptionListItem) => {
          if (sortState.field === "created_at") {
            // Convert back to dates for comparison
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return sortState.direction === "asc"
              ? dateA.getTime() - dateB.getTime()
              : dateB.getTime() - dateA.getTime();
          } else {
            const aValue = a[sortState.field];
            const bValue = b[sortState.field];
            if (typeof aValue === "number" && typeof bValue === "number") {
              return sortState.direction === "asc"
                ? aValue - bValue
                : bValue - aValue;
            } else if (
              typeof aValue === "string" &&
              typeof bValue === "string"
            ) {
              return sortState.direction === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }
          }
          return 0;
        });

        setOptionListItems(fetchedItems);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching optionList items:", error);
    }
  };

  const handleSortChange = (field: keyof OptionListItem) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
    }
  }, [successMessage]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchOptionListItems();
    }, 500);

    return () => clearTimeout(timerId);
  }, [
    currentPage,
    searchQuery,
    sortState,
    axiosInstance,
    loading,
    user,
    filterStartDate,
    filterEndDate,
  ]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}

          <PageHeader
            title={t("back.management.menu.optionList.title")}
            exportLabel={t("back.management.menu.optionList.export")}
            addLabel={t("back.management.menu.optionList.addOptionList")}
            onExport={downloadCSV}
            onAdd={() => onMenuItemClick("addOptionList")}
          />

          <DeleteConfirmationDialog
            isOpen={deleteConfirmationOpen}
            onClose={closeDeleteConfirmation}
            onConfirm={confirmDelete}
            title={t(
              "back.management.menu.optionList.deleteConfirmation.title"
            )}
            message={t(
              "back.management.menu.optionList.deleteConfirmation.message"
            )}
          />

          {/* Desktop Layout */}
          <div className="hidden md:block desktop-layout">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md mr-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t(
                    "back.management.menu.optionList.searchOptionList"
                  )}
                />
              </div>
              <div className="relative mr-36">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.optionList.selectDate")}
                />
              </div>
            </div>

            <DataTable
              data={optionListItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={() => ""}
              renderMobileCard={renderMobileCard}
            />
          </div>

          {/* Mobile Layout */}
          <div className="block md:hidden mobile-layout">
            <div className="flex flex-col mb-4">
              <div className="relative flex-1 max-w-md mb-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <SearchInput
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder={t(
                    "back.management.menu.optionList.searchOptionList"
                  )}
                />
              </div>
              <div className="relative mb-4">
                <DateFilterButton
                  onStartDateChange={handleFilterStartDateChange}
                  onEndDateChange={handleFilterEndDateChange}
                  startDate={filterStartDate}
                  endDate={filterEndDate}
                  labelText={t("back.management.menu.optionList.selectDate")}
                />
              </div>
            </div>

            <DataTable
              data={optionListItems}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={() => ""}
              renderMobileCard={renderMobileCard}
            />
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}

const renderMobileCard = (item: OptionListItem) => (
  <div className="flex items-center">
    <div>
      <p className="font-bold text-gray-900">{item.name}</p>
      <p className="text-gray-700">{item.created_at}</p>
    </div>
  </div>
);
