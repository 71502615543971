import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import OperatingHoursInput from "@/components/ui/selectors/OperatingHoursInput";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditCategoryProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  categoryId: number | null;
}

interface Category {
  id: number;
  name: string;
  description: string;
  status: string;
  parent_category: number | null;
  option_lists: number[];
}

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

interface OptionList {
  id: number;
  name: string;
}

const EditCategory = ({
  onMenuItemClick,
  setSuccessMessage,
  categoryId,
}: EditCategoryProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [category, setCategory] = useState<Category>({
    id: 0,
    name: "",
    description: "",
    status: "",
    parent_category: null,
    option_lists: []
  });
  const [categories, setCategories] = useState<Category[]>([]);
  const [optionLists, setOptionLists] = useState<OptionList[]>([]);
  const [allOptionLists, setAllOptionsLists] = useState<OptionList[]>([]);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [newOperatingHours, setNewOperatingHours] = useState<OperatingHour[]>(
    []
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteHourId, setDeleteHourId] = useState<number | null>(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${categoryId}/`);
          setCategory(response.data);
          setOperatingHours(response.data.category_hours || []);
        }
      } catch (error) {
        console.error("Error fetching category:", error);
      }
    };

    const fetchAllParentCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          let allFetchedCategories: Category[] = [];
          let apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          apiUrl += `?branch=${user.selectedBranch.id}&exclude=${categoryId}`;

          while (apiUrl) {
            const response = await axiosInstance.get(apiUrl);
            const { results, next } = response.data;
            allFetchedCategories = [...allFetchedCategories, ...results];
            apiUrl = next;
          }

          setCategories(allFetchedCategories);
        }
      } catch (error) {
        console.error("Error fetching parent categories:", error);
      }
    };

    if (categoryId) {
      fetchCategory();
      fetchAllParentCategories();
    }
  }, [axiosInstance, loading, categoryId, user?.selectedBranch]);

  useEffect(() => {
    const fetchAllOptionLists = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          let allFetchedOptionLists: { id: number; name: string }[] = [];
          let apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_OPTIONLIST_INFORMATION ?? "";
          apiUrl += `?branch=${user.selectedBranch.id}`;

          while (apiUrl) {
            const response = await axiosInstance.get(apiUrl);
            const { results, next } = response.data;
            allFetchedOptionLists = [...allFetchedOptionLists, ...results];
            apiUrl = next;
          }
          setAllOptionsLists(allFetchedOptionLists)
          setOptionLists(allFetchedOptionLists);
        }
      } catch (error) {
        console.error("Error fetching option lists:", error);
      }
    };

    fetchAllOptionLists();
  }, [axiosInstance, loading, user?.selectedBranch]);

  const handleUpdateCategory = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        const updatedCategory = {
          name: category.name,
          description: category.description,
          status: category.status,
          branch: user.selectedBranch.id,
          parent_category: category.parent_category || null,
          option_lists: category.option_lists || null,
        };

        await axiosInstance.put(`${apiUrl}${categoryId}/`, updatedCategory, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const hoursApiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_HOURS ?? "";
        for (const hour of newOperatingHours) {
          await axiosInstance.post(hoursApiUrl, {
            day: hour.day,
            open_time: hour.open_time,
            close_time: hour.close_time,
            category: category.id,
          });
        }

        const successMessage = t("back.management.menu.category.successupdate");
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("category", successMessage);
      }
    } catch (error) {
      console.error("Error updating category:", error);
      if (axios.isAxiosError(error)) {
        setBannerMessage(
          error.response?.data?.message || "Failed to update category"
        );
      } else {
        setBannerMessage("Failed to update category");
      }
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  const handleRemoveOptionList = (optionListId: number) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      option_lists: prevCategory.option_lists.filter(
        (id) => id !== optionListId
      ),
    }));
  };

  const handleOptionListChange = (selectedOptionListId: number) => {
    setCategory((prevCategory) => {
      const optionList = prevCategory.option_lists ?? []; // Ensure option_list is always an array
  
      const updatedOptionList = optionList.includes(selectedOptionListId)
        ? optionList.filter((id) => id !== selectedOptionListId)
        : [...optionList, selectedOptionListId];
  
      return {
        ...prevCategory,
        option_lists: updatedOptionList, // Correct the property name here
      };
    });
  };


  const handleDeleteCategoryHour = async () => {
    if (deleteHourId === null) return;

    try {
      const hoursApiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_HOURS ?? "";
      await axiosInstance.delete(`${hoursApiUrl}${deleteHourId}/`);
      setOperatingHours((prevHours) =>
        prevHours.filter((hour) => hour.id !== deleteHourId)
      );
      setShowDeleteConfirmation(false);
      setDeleteHourId(null);
      setBannerMessage("Successfully deleted category hour");
      setIsSuccess(true);
      setShowBanner(true);
    } catch (error) {
      console.error("Error deleting category hour:", error);
      setBannerMessage("Failed to delete category hour");
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const statusOptions = [
    <option key="active" value="Active">
      {t("back.management.menu.category.status1")}
    </option>,
    <option key="inactivate" value="Inactive">
      {t("back.management.menu.category.status2")}
    </option>,
    <option key="noProduct" value="No Product">
      {t("back.management.menu.category.status3")}
    </option>,
    <option key="draft" value="Draft">
      {t("back.management.menu.category.status4")}
    </option>,
  ];

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        {categoryId ? (
          <>
            <h1 className="text-xl font-bold">
              {t("back.management.menu.category.editcategory")}
            </h1>

            {/* General Information Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.generalinfo")}
              </h2>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-full px-2 mb-4 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="categoryName"
                  >
                    {t("back.management.menu.category.categoryname")}
                  </label>
                  <input
                    type="text"
                    id="itemName"
                    placeholder="Enter category name"
                    value={category.name}
                    onChange={(e) =>
                      setCategory({ ...category, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  {t("back.management.menu.category.description")}
                </label>
                <textarea
                  id="description"
                  placeholder="Enter category description"
                  value={category.description}
                  onChange={(e) =>
                    setCategory({ ...category, description: e.target.value })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
                ></textarea>
              </div>
            </div>

            {/* Parent Category Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.assignparentcategory")}
              </h2>
              <select
                value={category.parent_category || ""}
                onChange={(e) =>
                  setCategory({
                    ...category,
                    parent_category: e.target.value
                      ? parseInt(e.target.value)
                      : null,
                  })
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.category.selectparentcategory")}
                </option>
                {categories
                  .filter((cat) => cat.id !== category.id)
                  .map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
            </div>

            {/* Option List Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.assignoptionlists")}
              </h2>
              <select
                id="optionList"
                multiple  // Enable multiple selection
                value={Array.isArray(category.option_lists) ? category.option_lists.map(String) : []}   // Use array of selected IDs
                onChange={(e) =>
                  handleOptionListChange(parseInt(e.target.value, 10))
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                style={{ minHeight: '150px' }}  // Ensure minimum height to show multiple options
              >
                {optionLists.map((optionList) => (
                  <option key={optionList.id} value={optionList.id}>
                    {optionList.name}
                  </option>
                ))}
              </select>

              <div>
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.optionlists")}
              </h2>
              <div className="mt-2 space-y-4">
                {allOptionLists.length > 0 && category.option_lists && (
                   <ul>
                   {allOptionLists
                     .filter((optionList) => category?.option_lists.includes(optionList.id))
                     .map((optionList) => (
                       <li key={optionList.id} className="flex items-center mb-2">
                         <span className="text-gray-700 mr-2">
                           {optionList.name}
                         </span>
                         <button
                           onClick={() => handleRemoveOptionList(optionList.id)}
                           className="text-red-500 hover:text-red-700 focus:outline-none"
                         >
                           <FontAwesomeIcon icon={faTrashAlt} />
                         </button>
                       </li>
                     ))}
                 </ul>
                )}
                </div>
                </div>
            </div>

           

          

            {/* Status Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.status")}
              </h2>
              <select
                value={category.status}
                onChange={(e) =>
                  setCategory({ ...category, status: e.target.value })
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.category.selectstatus")}
                </option>
                {statusOptions}
              </select>
            </div>

            {/* Operating Hours Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.operatinghours")}
              </h2>
              <OperatingHoursInput
                operatingHours={newOperatingHours}
                setOperatingHours={setNewOperatingHours}
              />
              {operatingHours.map((hour) => (
                <div
                  key={hour.id}
                  className="flex items-center justify-between"
                >
                  <div>
                    <p>{`${hour.day}: ${hour.open_time} - ${hour.close_time}`}</p>
                  </div>
                  <button
                    onClick={() => {
                      setShowDeleteConfirmation(true);
                      setDeleteHourId(hour.id);
                    }}
                    className="text-red-500"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ))}
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("category")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.category.cancelbutton")}
              </button>
              <button
                onClick={handleUpdateCategory}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                {t("back.management.menu.category.updatebutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.menu.category.nocategoryselected")}</p>
        )}

        {showDeleteConfirmation && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
              <p>Are you sure you want to delete this category hour?</p>
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteCategoryHour}
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLoader>
  );
};

export default EditCategory;
