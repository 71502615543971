import { useState, useEffect } from "react";
import Logo from "@/components/front/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUtensils,
  faShoppingCart,
  faUsers,
  faCreditCard,
  faHeadset,
  faBusinessTime,
  faUser,
  faGear,
  faChevronDown,
  faChevronUp,
  faRectangleAd,
  faFile,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface SidebarProps {
  onMenuItemClick: (componentName: string) => void;
  isOpen: boolean;
  onClose: () => void;
}

export default function Sidebar({
  onMenuItemClick,
  isOpen,
  onClose,
}: SidebarProps) {
  const { t } = useTranslation();
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const toggleMenu = () => {
    setOpenSubMenu(openSubMenu === "menu" ? null : "menu");
  };

  const toggleCustomerSubMenu = () => {
    setOpenSubMenu(openSubMenu === "customer" ? null : "customer");
  };

  const toggleExpensesSubMenu = () => {
    setOpenSubMenu(openSubMenu === "expenses" ? null : "expenses");
  };

  const toggleBusinessSubMenu = () => {
    setOpenSubMenu(openSubMenu === "business" ? null : "business");
  };

  const [sidebarHeight, setSidebarHeight] = useState("100vh");

  useEffect(() => {
    const updateSidebarHeight = () => {
      setSidebarHeight(`${window.innerHeight}px`);
    };

    window.addEventListener("resize", updateSidebarHeight);
    updateSidebarHeight();

    return () => window.removeEventListener("resize", updateSidebarHeight);
  }, []);

  return (
    <aside
      className={`fixed top-0 left-0 h-screen w-64 bg-white text-black z-40 transform transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:relative md:translate-x-0 overflow-y-auto`}
      style={{ height: sidebarHeight }}
      aria-label="Sidebar"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-center mb-5 p-2.5">
          <Logo size="h-12" />
        </div>

        <button
          onClick={() => {
            onMenuItemClick("dashboard");
            onClose();
          }}
          className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
        >
          <div className="w-5 mr-2.5 flex justify-center">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
          {t("back.management.sidebar.dashboard")}
        </button>
        <div className="flex-grow overflow-y-auto">
          <button
            onClick={toggleMenu}
            className="flex items-center justify-between p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="flex items-center">
              <div className="w-5 mr-2.5 flex justify-center">
                <FontAwesomeIcon icon={faUtensils} />
              </div>
              <span>{t("back.management.sidebar.menu.title")}</span>
            </div>
            <FontAwesomeIcon
              icon={openSubMenu === "menu" ? faChevronUp : faChevronDown}
            />
          </button>
          <div
            className={`overflow-hidden ${
              openSubMenu === "menu"
                ? "transition-max-height duration-700"
                : "transition-max-height duration-300"
            } ease-in-out ${openSubMenu === "menu" ? "max-h-96" : "max-h-0"}`}
          >
            <button
              onClick={() => {
                onMenuItemClick("category");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.menu.category")}
            </button>
            <button
              onClick={() => {
                onMenuItemClick("product");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.menu.product")}
            </button>
            <button
              onClick={() => {
                onMenuItemClick("optionList");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.menu.optionList")}
            </button>
            <button
              onClick={() => {
                onMenuItemClick("option");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.menu.option")}
            </button>
          </div>

          <button
            onClick={() => {
              onMenuItemClick("orders");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            {t("back.management.sidebar.orderToday")}
          </button>

          <button
            onClick={() => {
              onMenuItemClick("report");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            {" "}
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faFile} />
            </div>
            {t("back.management.sidebar.report")}
          </button>

          {/* <button
            onClick={() => {
              onMenuItemClick("printer");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            {" "}
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faPrint} />
            </div>
            {t("back.management.sidebar.printer")}
          </button> */}

          {/* <button
            onClick={toggleCustomerSubMenu}
            className="flex items-center justify-between p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="flex items-center">
              <div className="w-5 mr-2.5 flex justify-center">
                <FontAwesomeIcon icon={faUsers} />
              </div>
              <span>{t("back.management.sidebar.customers.title")}</span>
            </div>
            <FontAwesomeIcon
              icon={openSubMenu === "customer" ? faChevronUp : faChevronDown}
            />
          </button>
          <div
            className={`overflow-hidden ${
              openSubMenu === "customer"
                ? "transition-max-height duration-700"
                : "transition-max-height duration-300"
            } ease-in-out ${
              openSubMenu === "customer" ? "max-h-96" : "max-h-0"
            }`}
          >
            <button
              onClick={() => {
                onMenuItemClick("customerloyalty");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.customers.loyalty")}
            </button>
            <button
              onClick={() => {
                onMenuItemClick("customerloyalty");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.customers.information")}
            </button>
          </div>

          <button
            onClick={() => {
              onMenuItemClick("customerloyalty");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            {" "}
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faRectangleAd} />
            </div>
            {t("back.management.sidebar.menu.promotion")}
          </button>

          <button
            onClick={toggleExpensesSubMenu}
            className="flex items-center justify-between p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="flex items-center">
              <div className="w-5 mr-2.5 flex justify-center">
                <FontAwesomeIcon icon={faCreditCard} />
              </div>
              <span>{t("back.management.sidebar.expenses.title")}</span>
            </div>
            <FontAwesomeIcon
              icon={openSubMenu === "expenses" ? faChevronUp : faChevronDown}
            />
          </button>

          <div
            className={`overflow-hidden ${
              openSubMenu === "expenses"
                ? "transition-max-height duration-700"
                : "transition-max-height duration-300"
            } ease-in-out ${
              openSubMenu === "expenses" ? "max-h-96" : "max-h-0"
            }`}
          >
            <button
              onClick={() => {
                onMenuItemClick("customerloyalty");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.expenses.type")}
            </button>
            <button
              onClick={() => {
                onMenuItemClick("customerloyalty");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.expenses.detail")}
            </button>
          </div>

          <button
            onClick={() => {
              onMenuItemClick("customerloyalty");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faUser} />
            </div>
            {t("back.management.sidebar.employee")}
          </button> */}

          <button
            onClick={toggleBusinessSubMenu}
            className="flex items-center justify-between p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="flex items-center">
              <div className="w-5 mr-2.5 flex justify-center">
                <FontAwesomeIcon icon={faBusinessTime} />
              </div>
              <span>{t("back.management.sidebar.business.title")}</span>
            </div>
            <FontAwesomeIcon
              icon={openSubMenu === "business" ? faChevronUp : faChevronDown}
            />
          </button>
          <div
            className={`overflow-hidden ${
              openSubMenu === "business"
                ? "transition-max-height duration-700"
                : "transition-max-height duration-300"
            } ease-in-out ${
              openSubMenu === "business" ? "max-h-96" : "max-h-0"
            }`}
          >
            <button
              onClick={() => {
                onMenuItemClick("devices");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.business.device")}
            </button>
            {/* <button
              onClick={() => {
                onMenuItemClick("businessHours");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.business.inventory")}
            </button> */}
            <button
              onClick={() => {
                onMenuItemClick("businessInformation");
                onClose();
              }}
              className="block w-full py-2 px-4 text-center font-bold transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              {t("back.management.sidebar.business.information")}
            </button>
          </div>

          {/* <button
            onClick={() => {
              onMenuItemClick("support");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faHeadset} />
            </div>
            {t("back.management.sidebar.support")}
          </button> */}

          <button
            onClick={() => {
              onMenuItemClick("setting");
              onClose();
            }}
            className="flex items-center p-2.5 pr-10 font-bold cursor-pointer transition-colors duration-200 ease-in-out pl-4 mb-2 w-full"
          >
            <div className="w-5 mr-2.5 flex justify-center">
              <FontAwesomeIcon icon={faGear} />
            </div>
            {t("back.management.sidebar.settings")}
          </button>
        </div>
      </div>
    </aside>
  );
}
