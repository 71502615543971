// fsFeature.tsx

import React, { CSSProperties, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faThumbsUp as farThumbsUp } from "@fortawesome/free-regular-svg-icons";
// import { faWifi, faShieldHalved } from "@fortawesome/free-solid-svg-icons";

const FSFeature = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    container: {
      textAlign: "center",
      padding: "50px",
    },
    title: {
      fontSize: isMobile ? "2em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
    },
    subtitle: {
      fontSize: "1.2rem",
      marginBottom: "40px",
      color: "#666",
    },
    image: {
      maxWidth: "100%",
      height: "auto",
      marginBottom: "40px",
      display: "block",
      margin: "auto",
      borderRadius: "20px",
    },
    feature: {
      display: isMobile ? "block" : "flex",
      justifyContent: "space-around",
      alignItems: "center",
      marginTop: "20px",
      marginBottom: "20px",
    },
    featureTitle: {
      fontSize: "2rem",
      color: "#C70039",
      fontWeight: "bold",
    },
    featureDescription: {
      fontSize: isMobile ? "1em" : "1.2em",
      color: "#666",
      maxWidth: "80%",
      margin: "auto",
    },
    icon: {
      color: "#000000",
      fontSize: "2em",
      marginBottom: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>{t("front.business.fs.title2")}</h2>
      <p style={styles.subtitle}>{t("front.business.fs.subtitle")}</p>
      <div style={styles.feature}>
        <div>
          {/* <FontAwesomeIcon icon={farThumbsUp} style={styles.icon} /> */}
          <h3 style={styles.featureTitle}>
            {t("front.business.fs.feature1.title")}
          </h3>
          <p style={styles.featureDescription}>
            {t("front.business.fs.feature1.description")}
          </p>
        </div>
        <div>
          {/* <FontAwesomeIcon icon={faWifi} style={styles.icon} /> */}
          <h3 style={styles.featureTitle}>
            {t("front.business.fs.feature2.title")}
          </h3>
          <p style={styles.featureDescription}>
            {t("front.business.fs.feature2.description")}
          </p>
        </div>
        <div>
          {/* <FontAwesomeIcon icon={faShieldHalved} style={styles.icon} /> */}
          <h3 style={styles.featureTitle}>
            {t("front.business.fs.feature3.title")}
          </h3>
          <p style={styles.featureDescription}>
            {t("front.business.fs.feature3.description")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FSFeature;
