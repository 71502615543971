import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-phone-number-input/style.css";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/css/index.css";
import store from "./store/index";

async function unregisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.getRegistration("/");
      if (registration) {
        await registration.unregister();
        console.log("Root service worker unregistered");
      }
    } catch (error) {
      console.error("Error unregistering service worker:", error);
    }
  }
}

async function registerAppropriateServiceWorker() {
  if ("serviceWorker" in navigator) {
    try {
      const currentPath = window.location.pathname;
      if (
        currentPath.startsWith("/login") ||
        currentPath.startsWith("/user/management")
      ) {
        await navigator.serviceWorker.register("/service-worker.js", {
          scope: "/",
        });
        console.log("Full service worker registered for PWA paths");
      } else {
        await navigator.serviceWorker.register("/minimal-service-worker.js", {
          scope: "/",
        });
        console.log("Minimal service worker registered for non-PWA paths");
      }
    } catch (error) {
      console.error("Error registering service worker:", error);
    }
  }
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

Sentry.init({
  dsn: "https://ee1c257642f1f44674aa9f741d39972b@o4506967701061632.ingest.us.sentry.io/4506967704928256",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

unregisterServiceWorker().then(() => {
  registerAppropriateServiceWorker().then(() => {
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </PostHogProvider>
      </React.StrictMode>
    );
  });
});
