import Faq from "@/components/front/Faq";
import Features from "@/components/front/Features";
import Footer from "@/components/front/Footer";
import Hero from "@/components/front/Hero";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { initializeServiceWorker } from "../../utils/pushNotification"; 
import TestStore from "./TestStore"; 

const Landing: React.FC = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];

  useEffect(() => {
    initializeServiceWorker(window.location.pathname);
  }, []);

  const isSubdomain = (hostname: string, subdomain: string) => {
    const result =
      subdomain !== "redbirdtab" &&
      subdomain !== "www" &&
      subdomain !== "dev" &&
      subdomain !== "sandbox" &&
      hostname.includes("redbirdtab.com");
    return result;
  };

  if (isSubdomain(hostname, subdomain)) return <TestStore />;

  return (
    <HelmetProvider>
      <Helmet>
        <title>Redbird Corporation</title>
      </Helmet>
      <div className="relative overflow-hidden bg-white dark:bg-gray-900 text-gray-800 dark:text-slate-200">
        <Hero />
        <Features id="features-section" className="relative z-10" />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default Landing;
