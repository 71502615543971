import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

const styles: Record<string, CSSProperties> = {
  container: {
    marginBottom: "32px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#1f2937",
  },
  subtitle: {
    fontSize: "1.3rem",
    color: "#4b5563",
  },
};

const CustomerLoyalty: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>under construction</h1>
    </div>
  );
};

export default CustomerLoyalty;
