import React, { useState, CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface OrderItem {
  id: number;
  itemName: string;
  category: string;
  price: string;
  stock: number;
  status: string;
  addedDate: string;
  photo: string;
}

const styles: Record<string, CSSProperties> = {
  container: {
    marginBottom: "32px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#1f2937",
  },
  subtitle: {
    fontSize: "1.3rem",
    color: "#4b5563",
  },
};

const ExpenseTitle: React.FC<{
  onMenuItemClick: (componentName: string) => void;
}> = ({ onMenuItemClick }) => {
  const { t } = useTranslation();
  const [orderItems] = useState<OrderItem[]>([]);

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],

      ...orderItems.map((item) => [
        item.id,
        item.itemName,
        item.stock,
        item.price,
        item.category,
        item.addedDate,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "order.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="flex justify-between flex-wrap">
      <div style={styles.container}>
        <h2 style={styles.title}>{t("back.management.expense.title")}</h2>
      </div>
      <div>
        <button
          onClick={downloadCSV}
          className="mr-2 px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          {t("back.management.menu.product.export")}
        </button>
        <button
          onClick={() => onMenuItemClick("addExpense")}
          className="px-4 py-2 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {t("back.management.expense.addExpense")}
        </button>
      </div>
    </div>
  );
};

export default ExpenseTitle;
