import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

interface DateFilterButtonProps {
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  labelText: string;
}

const DateFilterButton: React.FC<DateFilterButtonProps> = ({
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
  labelText,
}) => {
  const { t } = useTranslation();
  const [showFilterDatePopup, setShowFilterDatePopup] = useState(false);
  const filterDatePopupRef = useRef<HTMLDivElement>(null);

  const toggleFilterDatePopup = () => {
    setShowFilterDatePopup(!showFilterDatePopup);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      filterDatePopupRef.current &&
      !filterDatePopupRef.current.contains(event.target as Node)
    ) {
      setShowFilterDatePopup(false);
    }
  };

  const handleRemoveDateFilters = () => {
    onStartDateChange(null);
    onEndDateChange(null);
    setShowFilterDatePopup(false);
  };

  React.useEffect(() => {
    if (showFilterDatePopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterDatePopup]);

  return (
    <>
      <button
        className="mr-2 px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 lg:px-4 lg:py-2 lg:text-base sm:px-2 sm:py-1 sm:text-sm"
        onClick={toggleFilterDatePopup}
      >
        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
        {startDate && endDate
          ? `${format(startDate, "MM/dd/yyyy")} - ${format(
              endDate,
              "MM/dd/yyyy"
            )}`
          : startDate
          ? `After ${format(startDate, "MM/dd/yyyy")}`
          : endDate
          ? `Before ${format(endDate, "MM/dd/yyyy")}`
          : labelText}
      </button>
      {showFilterDatePopup && (
        <div
          ref={filterDatePopupRef}
          className="absolute mt-2 z-10 w-80 bg-white rounded-md shadow-lg"
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">
              {t("back.management.component.filterDates")}
            </h3>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("back.management.component.startDate")}
              </label>
              <ReactDatePicker
                selected={startDate}
                onChange={onStartDateChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholderText={t("back.management.component.selectStartDate")}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("back.management.component.endDate")}
              </label>
              <ReactDatePicker
                selected={endDate}
                onChange={onEndDateChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                placeholderText={t("back.management.component.selectEndDate")}
              />
            </div>
            <div className="mt-4 flex justify-end">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={handleRemoveDateFilters}
              >
                {t("back.management.component.removeDateFilters")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateFilterButton;
