import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface AddCategoryProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

interface Category {
  id: number;
  name: string;
  parent_category: number | null;
}

const AddCategory = ({
  onMenuItemClick,
  setSuccessMessage,
}: AddCategoryProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [selectedParentCategory, setSelectedParentCategory] = useState<
    number | null
  >(null);

  useEffect(() => {
    const apiUrl =
      process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
    const fetchAllCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          let allFetchedCategories: Category[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedCategories = [...allFetchedCategories, ...results];
            nextUrl = next;
          }

          setCategories(allFetchedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchAllCategories();
  }, [axiosInstance, loading, user?.selectedBranch]);

  const handleAddCategory = async () => {
    const selectedBranch = user?.selectedBranch?.id;
    const categoryData = {
      name,
      description,
      status,
      branch: selectedBranch,
      parent_category: selectedParentCategory || null,
    };

    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        const response = await axiosInstance.post(apiUrl, categoryData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.status === 201) {
          const successMessage = t("back.management.menu.category.successadd");
          setSuccessMessage(successMessage);
          setIsSuccess(true);
          setBannerMessage(successMessage);
          setShowBanner(true);
          onMenuItemClick("category", successMessage);
        }
      }
    } catch (error) {
      console.error("Error adding category:", error);
      setIsSuccess(false);
      setBannerMessage(t("back.management.menu.category.failadd"));
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  // Placeholder for promotions and categories options
  const statusOptions = [
    <option key="active" value="Active">
      {t("back.management.menu.category.status1")}
    </option>,
    <option key="inactivate" value="Inactive">
      {t("back.management.menu.category.status2")}
    </option>,
    <option key="noProduct" value="No Product">
      {t("back.management.menu.category.status3")}
    </option>,
    <option key="draft" value="Draft">
      {t("back.management.menu.category.status4")}
    </option>,
  ];

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.category.addnewcategory")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-full px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="categoryName"
              >
                {t("back.management.menu.category.categoryname")}
              </label>
              <input
                type="text"
                id="itemName"
                placeholder="Enter category name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.category.description")}
            </label>
            <textarea
              id="description"
              placeholder="Enter category description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            ></textarea>
          </div>
        </div>

        {/* Parent Category Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.assignparentcategory")}
          </h2>
          <select
            value={selectedParentCategory || ""}
            onChange={(e) =>
              setSelectedParentCategory(
                e.target.value ? parseInt(e.target.value) : null
              )
            }
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.category.selectparentcategory")}
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Status Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.status")}
          </h2>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.category.selectstatus")}
            </option>
            {statusOptions}
          </select>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("category")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.category.cancelbutton")}
          </button>
          <button
            onClick={handleAddCategory}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.category.confirmbutton")}
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default AddCategory;
