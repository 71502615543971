import React, { useState } from "react";

const CookieCheck: React.FC = () => {
  const [thirdPartyCookiesEnabled] = useState<boolean | null>(false);

  return (
    <>
      {thirdPartyCookiesEnabled === true && (
        <div className="bg-yellow-200 text-yellow-800 p-2 rounded">
          Third-party cookies are disabled. Please enable them to proceed with
          the payment.
        </div>
      )}
    </>
  );
};

export default CookieCheck;
