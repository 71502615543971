import React from "react";

interface SummaryData {
  total_subtotal_sales: number;
  total_tips: number;
  total_tax: number;
  total_paid_orders: number;
  total_unpaid_orders: number;
  total_service_fee_charge: number;
}

interface Props {
  summaryData: SummaryData;
  loading: boolean;
}

const SelectedDateSummary: React.FC<Props> = ({ summaryData, loading }) => {
  return (
    <div className="mt-4 bg-white p-4 rounded-lg shadow mb-4">
      <h2 className="text-xl font-bold mb-4">Selected Date Summary</h2>
      {loading ? (
        <div className="flex justify-center items-center h-32">
          <div className="spinner" /> {/* Replace with your spinner */}
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4">
          <div>
            <p className="text-lg font-semibold mt-2">Subtotal Sales:</p>
            <p className="text-lg font-bold">
              ${summaryData.total_subtotal_sales.toFixed(2)}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">Tips:</p>
            <p className="text-lg font-bold">
              ${summaryData.total_tips.toFixed(2)}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">Tax:</p>
            <p className="text-lg font-bold">
              ${summaryData.total_tax.toFixed(2)}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">Paid Orders:</p>
            <p className="text-lg font-bold">{summaryData.total_paid_orders}</p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">Unsuccessful Orders:</p>
            <p className="text-lg font-bold">
              {summaryData.total_unpaid_orders}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">Service Fee Charge:</p>
            <p className="text-lg font-bold">
              ${summaryData.total_service_fee_charge.toFixed(2)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedDateSummary;
