import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "@/locale/i18n";

interface Props {
  title: string;
  text: string;
  redirect?: string;
  isSuccess?: boolean;
  className?: string;
}

const WarningBanner: React.FC<Props> = ({
  title = i18n.t("shared.warning"),
  text = "",
  redirect,
  isSuccess = false,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${className} mb-2 rounded-sm border mt-4 ${
        isSuccess
          ? "bg-green-50 border-green-300"
          : "bg-yellow-50 border-yellow-300"
      }`}
    >
      <div
        className={`rounded-sm ${
          isSuccess ? "bg-green-50" : "bg-yellow-50"
        } p-4`}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            {isSuccess ? (
              <svg
                className="h-5 w-5 text-green-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 011.414 0l-8 8-4-4a1 1 0 111.414-1.414l2.293 2.293 7.293-7.293z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              // Existing warning SVG icon
              <svg
                className="h-5 w-5 text-yellow-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                {/* ... existing path ... */}
              </svg>
            )}
          </div>

          <div className="ml-3">
            <h3
              className={`text-sm leading-5 font-medium ${
                isSuccess ? "text-green-800" : "text-yellow-800"
              }`}
            >
              {title}
            </h3>
            <div
              className={`mt-2 text-sm leading-5 ${
                isSuccess ? "text-green-700" : "text-yellow-700"
              }`}
            >
              <p>
                {text}{" "}
                {redirect && (
                  <Link
                    className={`mt-2 underline ${
                      isSuccess ? "text-green-800" : "text-theme-800"
                    }`}
                    to={redirect}
                  >
                    {t("shared.goTo")} {redirect}
                  </Link>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningBanner;
