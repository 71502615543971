import LoginRegister from "@/assets/img/loginRegister.png";
import LogoLight from "@/assets/img/logo-light.png";
import Spinner from "@/components/front/Loader/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import axiosInstanceNoAuth from "@/utils/axiosConfigNoAuth";
import { subscribeToPushNotifications } from "@/utils/pushNotification";
import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { initializeServiceWorker } from "../../../utils/pushNotification";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login, user } = useUser();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    initializeServiceWorker("/login");
  }, []);

  useEffect(() => {
    if (user) {
      navigate("/user/management");
    }
  }, [user, navigate]);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (!navigator.onLine) {
      setShowBanner(true);
      setAnimateOut(false);
      setTimeout(() => setAnimateOut(true), 3000);
      setIsLoading(false);
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_LOGIN ?? "";
      const response = await axiosInstanceNoAuth.post(apiUrl, {
        username,
        password,
      });

      if (response.data) {
        const userData = {
          id: response.data.id,
          username: response.data.username,
          email: response.data.email,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          groups: response.data.groups,
          access_token: response.data.access_token,
          avatar: response.data.avatar,
          branches: response.data.branches,
          owner: response.data.owner,
        };

        console.log("User data", userData);
        login(userData);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("user_data", JSON.stringify(userData));
        await subscribeToPushNotifications(userData.id as string);
        navigate("/user/management");
      }
      setIsLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response && axiosError.response.status === 500) {
          setShowBanner(true);
        } else {
          console.error("Login error", axiosError);
        }
      } else {
        console.error("Login error", error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  return (
    <div className="min-h-screen flex overflow-hidden">
      <Link to="/" className="absolute top-0 left-0 p-4">
        <img src={LogoLight} alt="Home" className="h-12 w-30" />
      </Link>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Spinner />
        </div>
      )}
      <div className="flex-1 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-20">
          <div>
            {showBanner && (
              <WarningBanner
                title={t("account.login.invalidCredentials")}
                text={t("account.login.invalidCredentialsMessage")}
                className={`${
                  animateOut ? "animate-slideOutRight" : "animate-slideDown"
                }`}
              />
            )}
            <h2 className="mt-6 text-center text-3xl font-bold md:text-5xl text-gray-900 mb-8">
              {t("account.login.welcome")}
            </h2>
            <div className="text-center text-2xl md:text-3xl font-extrabold">
              <span className="text-black">
                {t("account.login.login_to")}&nbsp;
              </span>
              <span className="text-red-600">
                {t("account.login.redtitle")}
              </span>
              <span className="text-black">
                {t("account.login.service_name")}
              </span>
            </div>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <input type="hidden" name="remember" value="true" />
            <div>
              <label
                htmlFor="username"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.login.username_label")}{" "}
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.login.username_placeholder")}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700 block mb-2"
              >
                {t("account.login.password_label")}{" "}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("account.login.password_placeholder")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {t("account.login.remember_me")}
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/login/reset-password"
                  className="font-medium text-red-600 hover:text-red-500"
                >
                  {t("account.login.forgot_password")}
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {t("account.login.login_button")}
              </button>
            </div>
          </form>
          <div className="text-sm text-center font-medium">
            {t("account.login.no_account")}{" "}
            <Link to="/contact" className="font-medium text-red-600 ml-2">
              {t("account.login.register")}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-1/2 relative hidden lg:block">
        <div className="absolute top-0 right-0 bottom-0 w-3/5 bg-red-600"></div>
        <div className="absolute inset-0 flex justify-center items-center">
          <img
            src={LoginRegister}
            className="object-contain"
            style={{ maxHeight: "80%" }}
            alt="Login Visual"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
