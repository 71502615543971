import React, { useEffect, useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/CustomAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

interface ContextualSaveBarProps {
  isDirty: boolean;
  onSave: () => Promise<void>;
  onDiscard: () => void;
  isSaving: boolean;
}

const ContextualSaveBar: React.FC<ContextualSaveBarProps> = ({
  isDirty,
  onSave,
  onDiscard,
  isSaving,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(isDirty);
  }, [isDirty]);

  const handleSave = async () => {
    await onSave();
  };

  return (
    <Alert
      className={`fixed left-0 right-0 z-50 bg-gray-500 rounded-b-lg shadow-md transition-all duration-300 ease-in-out
                  ${isVisible ? "top-0" : "-top-full"}
                  mt-16 md:mt-0`}
    >
      <AlertDescription className="flex items-center justify-between px-4 py-2">
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-white mr-4 text-xl md:text-2xl"
          />
          <span className="font-medium text-white text-sm md:text-lg lg:text-xl">
            You have unsaved changes
          </span>
        </div>
        <div className="flex ml-4">
          <button
            onClick={onDiscard}
            className="mr-2 px-3 py-1 bg-gray-200 text-gray-700 text-sm rounded-md hover:bg-gray-300 transition-colors duration-200"
            disabled={isSaving}
          >
            Discard
          </button>
          <button
            onClick={handleSave}
            className="px-3 py-1 bg-red-500 text-white text-sm rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center"
            disabled={isSaving}
          >
            {isSaving ? (
              <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
            ) : null}
            Save
          </button>
        </div>
      </AlertDescription>
    </Alert>
  );
};

export default ContextualSaveBar;
