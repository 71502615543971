import React, { ReactNode } from "react";

interface AlertProps {
  children: ReactNode;
  className?: string;
}

export const Alert: React.FC<AlertProps> = ({ children, className = "" }) => {
  return <div className={`p-4 rounded-md ${className}`}>{children}</div>;
};

interface AlertDescriptionProps {
  children: ReactNode;
  className?: string;
}

export const AlertDescription: React.FC<AlertDescriptionProps> = ({
  children,
  className = "",
}) => {
  return <div className={`text-sm ${className}`}>{children}</div>;
};
