import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "@/components/front/Header";
import Footer from "@/components/front/Footer";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <HelmetProvider>
        <Helmet>
          <title>{"Privacy & Policy"} | Redbird Corporation</title>
        </Helmet>
      </HelmetProvider>
      <Header />
      <div className="container mx-auto p-4 mt-40 mb-40">
        <h1 className="text-4xl font-bold mb-4">
          Privacy Policy of Redbird Corporation
        </h1>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p>
            Welcome to Redbird Corporation. We are committed to protecting your
            privacy and ensuring that your personal information is handled in a
            safe and responsible manner. This policy outlines how we collect,
            use, and protect your information when you use our online services.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            2. Information We Collect
          </h2>
          <p>We collect the following types of information:</p>
          <ul className="list-disc list-inside ml-4">
            <li>
              <strong>Personal Information:</strong> Name, email address, phone
              number, and shipping address.
            </li>
            <li>
              <strong>Payment Information:</strong> Credit card details and
              billing address.
            </li>
            <li>
              <strong>Technical Information:</strong> IP address, browser type,
              and operating system.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            3. How We Use Your Information
          </h2>
          <p>We use your information to:</p>
          <ul className="list-disc list-inside ml-4">
            <li>Process and fulfill your orders.</li>
            <li>Provide customer support.</li>
            <li>Improve our services and website.</li>
            <li>Communicate with you about your orders and our services.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            4. Sharing Your Information
          </h2>
          <p>
            We do not sell or rent your personal information to third parties.
            We may share your information with:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>
              Service providers who assist us in operating our website and
              conducting our business.
            </li>
            <li>
              Legal authorities if required by law or to protect our rights.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
          <p>
            We implement a variety of security measures to ensure the safety of
            your personal information. Your data is stored in secure networks
            and is only accessible by a limited number of authorized personnel.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">6. Cookies</h2>
          <p>
            We use cookies to enhance your browsing experience. Cookies are
            small files that a site or its service provider transfers to your
            computer's hard drive through your web browser (if you allow) that
            enables the site's or service provider's systems to recognize your
            browser and capture and remember certain information.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">7. Your Rights</h2>
          <p>You have the right to:</p>
          <ul className="list-disc list-inside ml-4">
            <li>Access the personal information we hold about you.</li>
            <li>Request correction of any inaccurate information.</li>
            <li>Request deletion of your personal information.</li>
            <li>Opt out of receiving marketing communications.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">
            8. Changes to This Policy
          </h2>
          <p>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new policy on our website. You are
            advised to review this policy periodically for any changes.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this privacy policy,
            please contact us at:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>Email: redbirdcorptx@gmail.com</li>
          </ul>
        </section>

        <p>By using our website, you consent to our privacy policy.</p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
