import React, { useEffect, useState } from "react";
import Title from "@/views/back/management/components/dashboard/Title";
import Card from "@/views/back/management/components/dashboard/Card";
import Branch from "@/views/back/management/components/dashboard/Branch";
import { useUser } from "@/contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faBox,
  faPrint,
  faClipboardList,
  faChartBar,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

interface DashboardProps {
  onMenuItemClick: (componentName: string) => void;
  isSidebarOpen: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({
  onMenuItemClick,
  isSidebarOpen,
}) => {
  const { user } = useUser();
  const [showBranchComponent, setShowBranchComponent] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleUserChange = () => {
      const selectedBranch = user?.selectedBranch;
      setShowBranchComponent(!selectedBranch);
    };

    handleUserChange();

    window.addEventListener("storage", handleUserChange);

    return () => {
      window.removeEventListener("storage", handleUserChange);
    };
  }, [user]);

  return (
    <div className="p-8 bg-gray-100">
      <Title onMenuItemClick={onMenuItemClick} isSidebarOpen={isSidebarOpen} />
      <div>
        <Card isSidebarOpen={isSidebarOpen} />
        {showBranchComponent && <Branch onMenuItemClick={onMenuItemClick} />}
      </div>
      {/* Mobile view section for shortcuts */}
      <div className="block lg:hidden mt-12 gap-4">
        <h1 className="text-2xl font-bold mb-4">Quick Access</h1>
      </div>
      <div className="block lg:hidden mt-3 grid grid-cols-2 gap-4">
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("category")}
        >
          <FontAwesomeIcon icon={faTags} size="2x" />
          <p className="mt-2 text-center">Category</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("product")}
        >
          <FontAwesomeIcon icon={faBox} size="2x" />
          <p className="mt-2 text-center">Product</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("printer")}
        >
          <FontAwesomeIcon icon={faPrint} size="2x" />
          <p className="mt-2 text-center">Printers</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("orders")}
        >
          <FontAwesomeIcon icon={faClipboardList} size="2x" />
          <p className="mt-2 text-center">Order</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("report")}
        >
          <FontAwesomeIcon icon={faChartBar} size="2x" />
          <p className="mt-2 text-center">Report</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("setting")}
        >
          <FontAwesomeIcon icon={faGear} size="2x" />
          <p className="mt-2 text-center">Settings</p>
        </div>
      </div>
      {/* Tablet and larger view section for shortcuts */}
      <div className="hidden lg:block mt-12 gap-4">
        <h1 className="text-2xl font-bold mb-4">Quick Access</h1>
      </div>
      <div className="hidden lg:grid mt-3 grid-cols-3 gap-4">
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("category")}
        >
          <FontAwesomeIcon icon={faTags} size="2x" />
          <p className="mt-2 text-center">Category</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("product")}
        >
          <FontAwesomeIcon icon={faBox} size="2x" />
          <p className="mt-2 text-center">Product</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("printer")}
        >
          <FontAwesomeIcon icon={faPrint} size="2x" />
          <p className="mt-2 text-center">Printers</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("orders")}
        >
          <FontAwesomeIcon icon={faClipboardList} size="2x" />
          <p className="mt-2 text-center">Order</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("report")}
        >
          <FontAwesomeIcon icon={faChartBar} size="2x" />
          <p className="mt-2 text-center">Report</p>
        </div>
        <div
          className="bg-white p-4 rounded shadow cursor-pointer hover:bg-gray-200"
          onClick={() => onMenuItemClick("setting")}
        >
          <FontAwesomeIcon icon={faGear} size="2x" />
          <p className="mt-2 text-center">Settings</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
