import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditPromotionProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  promotionId: number | null;
}
interface Promotion {
  id: number;
  name: string;
  description: string;
  image: string;
  status: string;
  category: number | null;
}

const EditPromotion = ({
  onMenuItemClick,
  setSuccessMessage,
  promotionId,
}: EditPromotionProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [categories, setCategories] = useState<Promotion[]>([]);
  const [promotion, setPromotion] = useState<Promotion>({
    id: 0,
    name: "",
    description: "",
    image: "",
    status: "",
    category: 0,
  });

  useEffect(() => {
    const fetchPromotion = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${promotionId}/`);
          if (response.data) {
            setPromotion(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching promotion:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const response = await axiosInstance.get(
            `${apiUrl}?branch=${user.selectedBranch.id}`
          );
          setCategories(response.data.results);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    if (promotionId) {
      fetchPromotion();
      fetchCategories();
    }
  }, [promotionId, loading, axiosInstance, user?.selectedBranch]);

  const HandleUpdatePromotion = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PROMOTION_INFORMATION ?? "";
        await axiosInstance.put(`${apiUrl}${promotionId}/`, promotion);
        setSuccessMessage(t("Promotion updated successfully"));
        onMenuItemClick("promotion", t("Promotion updated successfully"));
      }
    } catch (error) {
      console.error("Error updating promotion:", error);
      setBannerMessage(t("Error updating promotion"));
      setShowBanner(true);
      setIsSuccess(false);
      setAnimateOut(false);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const statusOptions = [
    <option key="active" value="Active">
      {t("back.management.menu.category.status1")}
    </option>,
    <option key="inactivate" value="Inactive">
      {t("back.management.menu.category.status2")}
    </option>,
    <option key="draft" value="Draft">
      {t("back.management.menu.category.status4")}
    </option>,
  ];

  return (
    <PageLoader isFetching={isFetching}>
      <div className="w-full">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        {promotionId ? (
          <>
            <h1 className="text-xl font-bold">
              {t("back.management.menu.promotion.editPromotion")}
            </h1>

            {/* General Information Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.promotion.generalinfo")}
              </h2>
              <div className="flex flex-wrap -mx-2">
                <div className="w-full md:w-full mt-3 px-2 mb-4 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="categoryName"
                  >
                    {t("back.management.menu.category.categoryname")}
                  </label>
                  <input
                    type="text"
                    id="itemName"
                    placeholder="Enter category name"
                    value={promotion.name}
                    onChange={(e) =>
                      setPromotion({ ...promotion, name: e.target.value })
                    }
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="description"
                >
                  {t("back.management.menu.category.description")}
                </label>
                <textarea
                  id="description"
                  placeholder="Enter promotion description"
                  value={promotion.description}
                  onChange={(e) =>
                    setPromotion({ ...promotion, description: e.target.value })
                  }
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
                ></textarea>
              </div>
            </div>

            {/* Category Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.product.category")}
              </h2>
              <select
                value={promotion.category || ""}
                onChange={(e) =>
                  setPromotion({
                    ...promotion,
                    category: e.target.value ? parseInt(e.target.value) : null,
                  })
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.product.selectcategory")}
                </option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Status Section */}
            <div className="my-4 bg-white p-4 shadow rounded-lg">
              <h2 className="text-lg font-semibold">
                {t("back.management.menu.category.status")}
              </h2>
              <select
                value={promotion.status}
                onChange={(e) =>
                  setPromotion({ ...promotion, status: e.target.value })
                }
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.category.selectstatus")}
                </option>
                {statusOptions}
              </select>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("promotion")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.promotion.cancelbutton")}
              </button>
              <button
                onClick={HandleUpdatePromotion}
                className="px-4 py-2 bg-red-500 text-white rounded-md"
              >
                {t("back.management.menu.promotion.updatebutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.menu.category.nocategoryselected")}</p>
        )}
      </div>
    </PageLoader>
  );
};

export default EditPromotion;
