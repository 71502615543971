import React, { CSSProperties, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import handheldsFeature1Image from "@/assets/img/handheldsFeature1.png";
import handheldsFeature2Image from "@/assets/img/handheldsFeature2.png";

const HandheldsFeature2 = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const wrapperStyle = {
    maxWidth: "80%",
    margin: "0 auto",
  };

  const imageLeftStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-between",
    margin: "20px 0",
  };

  const imageRightStyle: CSSProperties = {
    ...imageLeftStyle,
    flexDirection: isMobile ? "column" : "row-reverse",
  };

  const contentStyle: CSSProperties = {
    flexBasis: isMobile ? "100%" : "50%",
    paddingRight: isMobile ? "0" : "20px",
    textAlign: isMobile ? "center" : "left",
  };

  const imageStyle = {
    flexBasis: isMobile ? "100%" : "50%",
    display: "flex",
    justifyContent: "center",
  };

  const imageTagStyle = {
    maxWidth: isMobile ? "80%" : "100%",
    height: "auto",
  };

  const titleStyle = {
    fontWeight: "bold",
    fontSize: isMobile ? "2em" : "3em",
  };

  const textStyle = {
    fontSize: isMobile ? "1em" : "1.2em",
  };

  return (
    <div style={wrapperStyle}>
      <div style={imageLeftStyle}>
        <div style={imageStyle}>
          <img
            src={handheldsFeature1Image}
            alt="handhelds Feature Left"
            style={imageTagStyle}
          />
        </div>
        <div style={contentStyle}>
          <h1 style={titleStyle}>
            {t("front.features.handhelds.feature2title")}
          </h1>
          <p style={textStyle}>
            {t("front.features.handhelds.feature2description")}
          </p>
        </div>
      </div>

      <div style={imageRightStyle}>
        <div style={imageStyle}>
          <img
            src={handheldsFeature2Image}
            alt="handhelds Feature Right"
            style={imageTagStyle}
          />
        </div>
        <div style={contentStyle}>
          <h1 style={titleStyle}>
            {t("front.features.handhelds.feature2title2")}
          </h1>
          <p style={textStyle}>
            {t("front.features.handhelds.feature2description2")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HandheldsFeature2;
